.clockCell > td {
  padding-top: 0;
  padding-right: 0;
}

.clockCell > svg {
  margin-top: calc(var(--space-smallest) * -1);
  margin-right: var(--space-smaller);
}

.clockCell > span {
  min-width: var(--space-larger);
}

.hoursCell > span {
  display: none;
}

.withIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.withIcon > svg {
  margin-top: calc(var(--space-smallest) * -1);
  margin-right: var(--space-smaller);
}

.labourTable > table > tbody > tr > td {
  word-break: break-word;
}

.title {
  display: flex;
  padding: var(--space-base);
  border-top-color: var(--color-surface--background);
  text-transform: capitalize;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.hoursCell,
.clockCell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.mobileTitle {
  display: none;
}

/** Responsiveness for small screens */
@media screen and (max-width: 1024px) {
  .hoursCell > span {
    display: inline-block;
  }

  .hoursCell {
    margin-top: var(--space-base);
    align-items: center;
    justify-content: space-between;
  }

  .clockCell {
    display: flex;
    font-weight: bold;
    flex-direction: row;
    justify-content: flex-end;
  }

  .mobileTitle {
    display: flex;
  }

  .alignCenter {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .alignRight {
    display: flex;
    text-align: right;
    flex-direction: row;
    justify-content: space-between;
  }

  .numericBold {
    font-weight: bold;
  }

  .labourTable > table > tfoot > tr > td:nth-child(1),
  .labourTable > table > tfoot > tr > td:nth-child(2),
  .labourTable > table > tfoot > tr > td:nth-child(3) {
    display: none;
  }

  .labourTable > table > tbody > tr > td > .hoursCell > .clockCell > td {
    padding-right: 0;
  }
}
