.downgradePromoAcceptedModalContainer {
  padding: var(--space-large) var(--space-large) var(--space-larger);
}

.downgradePromoAcceptedImgContainer {
  display: flex;
  justify-content: center;
}

.downgradePromoAcceptedImg {
  max-width: 25%;
}
