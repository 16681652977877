:global([data-react-class="campaigns/index.CampaignsRoute"]) {
  display: contents;
}

.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
}

.segmentsPageContainer {
  flex: 1;
}
