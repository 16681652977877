.itemContainerLarge:first-child {
  padding-top: 0;
}

.itemContainerLarge:last-child {
  padding-bottom: 0;
}

.moreIconContainer {
  flex-shrink: 0;
}

.titleText {
  flex-wrap: wrap;
  padding-right: var(--space-small);
  flex: 1;
}

.durationPriceText {
  flex-wrap: nowrap;
}

.descriptionContainer {
  flex: 2;
  margin-right: var(--space-base);
}

.endContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1.1 0;
  flex-wrap: nowrap;
  gap: var(--space-base);
  align-items: center;
}

.editButton {
  color: var(--color-interactive);
  cursor: pointer;
}

.editButton:hover {
  color: var(--color-interactive--hover);
}
