.acceptDispute button {
  margin: 0 1rem 0 0;
}

.stepsList {
  margin-left: 1em;
  list-style: decimal outside;
}

.learnMore {
  padding-bottom: var(--space-small);
}

.reviewClaimDetailsStep {
  display: flex;
  flex-direction: row;
}

.reviewClaimDetailsContainer {
  color: var(--color-interactive);
}

.reviewClaimDetailsContainer:hover {
  color: var(--color-interactive--hover);
  cursor: pointer;
}

.reviewClaimDetailsContainer:focus {
  box-shadow: var(--shadow-focus);
  border-color: var(--color-focus);
  outline: none;
  color: var(--color-interactive--hover);
  background-color: var(--color-surface--hover);
}

.reviewClaimDetailsContainer:active {
  box-shadow: none;
  border: none;
  outline: none;
  color: var(--color-interactive--hover);
  background-color: var(--color-surface--hover);
}
