.articleListCta {
  display: flex;
  flex-direction: column;
  padding: var(--space-small);
  padding-bottom: var(--space-large);
  border-radius: var(--radius-base);
  color: var(--color-text);
  transition: background-color 0.3s ease, color 0.2s;
}

.articleListCta:hover,
.articleListCta:focus-visible {
  color: var(--color-heading);
  background-color: var(--color-surface--hover);
}

.articleListCta:focus-visible {
  box-shadow: var(--shadow-focus);
  outline: transparent;
}

.articleSnippet {
  margin-top: var(--space-smaller);
  line-height: 1.5;
}
