.label {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: var(--color-lightBlue);
}

.lineitemcost {
  background-color: var(--color-lightBlue--dark);
}

.labour {
  background-color: var(--color-lightBlue);
}

.expenses {
  background-color: var(--color-lightBlue--light);
}

.profit {
  background-color: var(--color-green);
}
