.appLogoIcon {
  width: 24px;
  height: 24px;
}

.appAlertRowContent {
  padding: var(--space-base) var(--space-small) 0 var(--space-small);
  color: var(--color-heading);
}

.appAlertRowContent:hover {
  background-color: var(--color-surface--hover);
}

.itemBottomDivider {
  padding-bottom: var(--space-base);
  border-bottom: var(--border-base) dotted var(--color-border);
}

.appAlertRowContainer {
  display: flex;
  padding: 0 var(--space-small) 0 var(--space-small);
}

.appLogoContainer {
  order: 1;
  flex-basis: 15%;
  padding-right: var(--space-small);
}

.appAlertCenterContent {
  display: "flex";
  flex-direction: column;
  order: 2;
  flex-basis: 77%;
}

.appAlertTitleContainer {
  padding-bottom: var(--space-small);
  font-size: 16px;
  font-weight: 700;
  order: 1;
}

.appAlertLastUpdatedAt {
  color: rgb(101, 120, 132);
  font-size: 14px;
  font-weight: 400;
  order: 2;
}

.alertIconContainer {
  order: 3;
  margin-left: auto;
}

.alertIconContainer > svg {
  flex-basis: 7%;
  width: 13.35px !important;
  height: 13.35px !important;
}
