.breadcrumb {
  display: flex;
  padding: var(--space-large) 0 0 var(--space-large);
  align-items: center;
  column-gap: var(--space-small);
}

.errorBanner {
  padding: var(--space-larger);
}
