.container {
  --sparkle-color: var(--color-base-purple--600);
  --sparkle-background-color: var(--color-surface);

  /* The duration taken for the big sparkle to rotate one round */
  --rotation-duration: 750ms;
  /* Duration to wait before starting the unload animation.
     A duration ensures the rotation of the big sparkle finishes before the
     rest of the unload animation starts.
  */
  --unload-animation-delay: calc(var(--rotation-duration) / 3);
  /* Duration to wait before starting the load animation. This duration
     ensures the user has time to "notice" the animation.
  */
  --load-animation-delay: 1s;
  --small-sparkle-transform-duration: 250ms;
  --loading-translate-duration: 250ms;
}

:root[data-theme="dark"] .container {
  --sparkle-color: var(--color-base-purple--400);
  --sparkle-background-color: var(--color-surface);
}

.sparkle {
  fill: var(--sparkle-color);
}

.sparkleInner {
  fill: var(--sparkle-background-color);
}

.smallSparkleLoading,
.smallSparkleUnloading {
  animation-duration: var(--small-sparkle-transform-duration);
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: 12px 12px;
}

.smallSparkleLoading {
  animation-delay: var(--load-animation-delay);
  animation-name: hide-small-sparkle;
}

.smallSparkleUnloading {
  scale: 0;
  translate: 5px 0;
  animation-delay: var(--unload-animation-delay);
  animation-name: show-small-sparkle;
}

.bigSparkleTranslateLoading,
.bigSparkleTranslateUnloading {
  animation-duration: var(--loading-translate-duration);
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.bigSparkleTranslateLoading {
  animation-delay: var(--load-animation-delay);
  animation-name: move-sparkle;
}

.bigSparkleTranslateUnloading {
  transform: translate(-6px, 0);
  animation-delay: var(--unload-animation-delay);
  animation-name: move-sparkle-back;
}

.bigSparkleRotationContainer {
  animation-delay: calc(var(--loading-translate-duration) + 1s);
  animation-duration: var(--rotation-duration);
  animation-iteration-count: infinite;
  animation-name: loading-animation;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transform-origin: 15px 12px;
}

.bigSparkleInnerLoading {
  animation-delay: var(--load-animation-delay);
  animation-duration: var(--loading-translate-duration);
  animation-iteration-count: 1;
  animation-name: hide-sparkle-inner;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.bigSparkleInnerUnloading {
  animation-delay: var(--unload-animation-delay);
  animation-duration: var(--loading-translate-duration);
  animation-iteration-count: 1;
  animation-name: show-sparkle-inner;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  fill-opacity: 0;
}

@keyframes hide-small-sparkle {
  0% {
    scale: 1;
  }

  20% {
    scale: 1.2;
  }

  100% {
    scale: 0;
  }
}

@keyframes show-small-sparkle {
  0% {
    scale: 0;
    translate: 5px 0;
  }

  90% {
    scale: 1.2;
  }

  100% {
    scale: 1;
    translate: 0 0;
  }
}

@keyframes hide-sparkle-inner {
  0% {
    fill-opacity: 1;
  }

  100% {
    fill-opacity: 0;
  }
}

@keyframes show-sparkle-inner {
  0% {
    fill-opacity: 0;
  }

  100% {
    fill-opacity: 1;
  }
}

@keyframes move-sparkle {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-6px, 0px);
  }
}

@keyframes move-sparkle-back {
  0% {
    transform: translate(-6px, 0);
  }

  100% {
    transform: translate(0px);
  }
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
