.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  gap: var(--space-base);
  padding: var(--space-base);
}

.headerContainer {
  display: flex;
  height: 36px;
  margin-top: var(--space-base);
  flex-direction: row;
  align-items: center;

  @media (--large-screens-and-up) {
    margin-top: var(--space-large);
    margin-bottom: calc(100px - var(--space-base));
  }
}

.progressBar {
  width: 200px;
  margin: auto;
}

.errorBanner {
  width: 100%;
}
