.headerStyle {
  margin: 0;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center;
  background-position: center center;
  background-size: auto 210%;
}

.headerWithoutBackground {
  margin: 0;
  padding-top: 2em;
  padding-bottom: 1em;
  text-align: center;
}

.savedHeaderStyle {
  text-align: center;
}

.smallParagraph {
  font-size: 0.75em;
}
