@media screen and (max-width: 40rem) {
  .shadow {
    position: absolute;
    right: var(--space-base);
    z-index: 4;
    width: var(--space-large);
    height: calc(var(--space-base) + var(--space-large));
    background: -webkit-linear-gradient(
      0deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    pointer-events: none;
  }
}

.helpDrawerWrapper {
  --public-content--padding: var(--space-base);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.helpDrawerArticleWrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: var(--space-small);
  border-top: var(--border-base) solid var(--color-border);
  overflow-y: auto;
}

.helpDrawerMoreResults {
  display: flex;
  padding: var(--space-base);
  align-items: center;
  justify-content: center;
}

.helpDrawerFooter {
  padding: var(--space-base);
  border-top: var(--border-base) solid var(--color-border);
}

.helpDrawerFooter > *:not(:last-child) {
  margin-bottom: var(--space-base);
}

.helpDrawerMenu {
  display: flex;
  flex-direction: column;
}
