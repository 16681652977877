.container {
  display: flex;
  width: 100%;
}

.container > div {
  border-radius: 0 var(--radius-base) var(--radius-base) 0;
}

.container span {
  right: var(--space-small);
}
