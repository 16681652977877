@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.itemsContainer {
  display: flex;
  max-width: 100vw;
  padding: 2.5rem;
  padding-bottom: 0;
  text-align: center;
  opacity: 0;
  transform: translateY(
    -50%
  ); /* aligns bottom of itemsContainer with center of page */
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.itemsContainer .slideInContent {
  display: flex;
  margin-bottom: var(--space-large);
  text-align: center;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.itemsContainer.visible {
  opacity: 1;
  animation: fade-in 0.3s ease;
}

.itemsContainer.fadeOut {
  opacity: 0;
  animation: fade-out 0.3s ease;
}

.itemsContainer .slideInContent :global(.slideInElement) {
  transform: translateY(100%);
}

.itemsContainer.visible .slideInContent :global(.slideInElement) {
  transform: translateY(0);
  animation: slide-in 0.3s ease;
}

.itemsContainer.fadeOut .slideInContent :global(.slideInElement),
.itemsContainer.fadeOut :global(.slideOutElement) {
  transform: translateY(100%);
  animation: slide-out 0.3s ease;
}

.itemsContainer .headerContent {
  max-width: 100%;
}

.itemsContainer .headerContent > :last-child {
  /* aligns top of headerContent with bottom of .itemsContainer */
  margin-bottom: -100%;
}
