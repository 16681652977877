.recurrenceTopMargin {
  margin-top: var(--space-large);
}

.checkboxContainer {
  margin-top: var(--space-large);
  margin-bottom: var(--space-small);
}

.unscheduledCheckContainer {
  margin-top: var(--space-base);
  grid-column: -1 / 1;
}

.flexBox {
  display: flex;
}

.flexChild {
  flex: 1;
}
