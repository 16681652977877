.checkbox p {
  margin: 0px;
}
.checkbox {
  display: flex;
  margin-bottom: var(--space-base);
}
.dropdown {
  display: flex;
  align-items: center;
  padding-bottom: var(--space-base);
}
