.cardContainer{
  width: 100%;
  height: 100%;

  > :first-child {
    height: 100%;

    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;

      > :last-child {
        flex: 4;
        align-content: flex-end;
      }
    }
  }
}
