.container {
  margin-bottom: var(--space-large);
  padding: var(--space-large);
  padding-top: 0;
}

.heading {
  padding-bottom: var(--space-large);
}

.copy {
  padding-bottom: var(--space-large);
}

.form {
  padding-top: var(--space-large);
}

@media screen and (max-width: 500px) {
  .form {
    padding-bottom: var(--space-large);
  }
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .container {
    padding: var(--space-extravagant);
    padding-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 450px;
    margin: 0 auto;
    padding: 0;
  }
}
