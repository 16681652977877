.container {
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex: 1 1 0px;
}

.container:focus-visible {
  outline: none;
}

.cell {
  display: flex;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border: 0;
  border-color: transparent;
  border-radius: var(--radius-circle);
  background: transparent;
  flex-basis: auto;
  align-items: center;
  justify-content: center;
}

.today .cell {
  position: relative;
  overflow: visible;
}

.today .cell span::after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: calc(50% - 2px);
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: var(--color-text);
}

.today .disabled span::after {
  background-color: var(--color-disabled);
}

.today .highlighted span::after {
  background-color: var(--color-text);
}

.today .selected span::after {
  background-color: var(--color-surface);
}

.rollover {
  background-color: var(--color-surface--background);
}

.rolloverEnd {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rolloverStart {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.cell:not(.disabled):not(.selected):hover {
  background-color: var(--color-surface--hover);
}

.container:focus > .cell:not(.selected) {
  background-color: var(--color-surface--hover);
}

.container:focus > .cell:not(.selected):not(.disabled),
.container.tabbable > .cell:not(.selected):not(.disabled) {
  color: var(--color-text);
}

.selected {
  border-color: var(--color-visit);
  color: var(--color-surface);
  background-color: var(--color-visit);
}

.selected:hover,
.container:focus .selected {
  background-color: var(--color-interactive--hover);
}

.range-none .highlighted {
  background-color: var(--color-green--lighter);
}

.range-none .highlighted:hover {
  border-color: var(--color-interactive--hover);
  background-color: var(--color-surface--hover);
}

.disabled {
  color: var(--color-disabled);
}

.range-none:not(.rollover, .rolloverStart, .rolloverEnd) {
  background: none;
}

.range-between {
  background-color: var(--color-green--lightest);
}

.range-start {
  background: linear-gradient(
    90deg,
    transparent 50%,
    var(--color-green--lightest) 50%
  );
}

.range-end {
  background: linear-gradient(
    90deg,
    var(--color-green--lightest) 50%,
    transparent 50%
  );
}

.accessibleLabel {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
