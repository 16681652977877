.card {
  margin-top: var(--space-small);
  margin-bottom: var(--space-base);
  padding: var(--space-base);
  border: 0.025rem solid var(--color-informative);
}

.row {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  margin-bottom: var(--space-small);
}

.image {
  width: 100%;
  max-width: 42rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-base);
}

.buttonsBlock {
  display: flex;
  justify-content: flex-end;
}

.dismissButton {
  margin-top: var(--space-base);
  margin-right: var(--space-base);
}

.learnMoreButton {
  margin-top: var(--space-base);
}

.divider {
  display: none;
}

@media screen and (min-width: 56rem) {
  .card {
    padding: 0;
    border: none;
  }

  .row {
    flex-direction: row;
    margin-bottom: var(--space-large);
  }

  .imageContainer {
    margin-bottom: 0;
    margin-right: var(--space-larger);
  }

  .buttonsBlock {
    margin-top: auto;
  }

  .divider {
    display: block;
  }
}
