.editorContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listStyle {
  list-style-type: disc;
  padding-inline-start: 40px;
}

@media (--medium-screens-and-up) {
  .editorContainer {
    max-width: 590px;
  }
}
