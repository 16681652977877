.banner {
  --ctaBanner-background: var(--color-surface--background);
  --ctaBanner-image-min-height: 240px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  position: relative;

  height: 100%;
  border-radius: var(--radius-base);

  overflow: hidden;
  background: var(--ctaBanner-background);

  .bannerImage {
    min-height: var(--ctaBanner-image-min-height);

    & > img:first-child {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .tornOverlayHorizontal {
      position: absolute;
      bottom: -1px;
      width: 100%;
      fill: var(--ctaBanner-background);
    }

    .tornOverlayVertical {
      display: none;
      position: absolute;
      right: -1px;
      height: 100%;
      fill: var(--ctaBanner-background);
    }
  }

  .bannerDismiss {
    position: absolute;
    top: 0;
    right: 0;

    margin: 0.25rem;
    padding: 0.25rem;
    line-height: 1;
  }

  .bannerSlot {
    position: relative;
    flex: 0 0 100%;

    &.padded {
      padding: var(--space-base);
    }

    &.small {
      flex: 0 0 35%;
    }

    &.large {
      flex: 0 0 45%;
    }

    &.fill {
      flex: 1 1 100%;
    }
  }
}

.banner.rowBreakpointAny {
  flex-direction: row;

  .bannerSlot {
    &.bannerImage,
    &.bannerImage.small,
    &.bannerImage.large,
    &.bannerImage.fill {
      flex: 0 0 40%;
      min-height: initial;
    }

    &.bannerImage .tornOverlayHorizontal {
      display: none;
    }

    &.bannerImage .tornOverlayVertical {
      display: block;
    }
  }
}

@media (--small-screens-and-up) {
  .rowBreakpointSmall {
    flex-direction: row;

    .bannerSlot {
      flex: 1 0 auto;
    }

    .bannerImage,
    .bannerImage.small,
    .bannerImage.large,
    .bannerImage.fill {
      flex: 0 0 40%;
      min-height: initial;
    }

    .bannerImage .tornOverlayHorizontal {
      display: none;
    }

    .bannerImage .tornOverlayVertical {
      display: block;
    }
  }
}

@media (--medium-screens-and-up) {
  .rowBreakpointMedium {
    flex-direction: row;

    .bannerSlot {
      flex: 1 0 auto;
    }

    .bannerImage,
    .bannerImage.small,
    .bannerImage.large,
    .bannerImage.fill {
      flex: 0 0 40%;
      min-height: initial;
    }

    .bannerImage .tornOverlayHorizontal {
      display: none;
    }

    .bannerImage .tornOverlayVertical {
      display: block;
    }
  }
}

@media (--large-screens-and-up) {
  .rowBreakpointLarge {
    flex-direction: row;

    .bannerSlot {
      flex: 1 0 auto;
    }

    .bannerImage,
    .bannerImage.small,
    .bannerImage.large,
    .bannerImage.fill {
      flex: 0 0 40%;
      min-height: initial;
    }

    .bannerImage .tornOverlayHorizontal {
      display: none;
    }

    .bannerImage .tornOverlayVertical {
      display: block;
    }
  }
}

@media (--extra-large-screens-and-up) {
  .rowBreakpointExtraLarge {
    flex-direction: row;

    .bannerSlot {
      flex: 1 0 auto;
    }

    .bannerImage,
    .bannerImage.small,
    .bannerImage.large,
    .bannerImage.fill {
      flex: 0 0 40%;
      min-height: initial;
    }

    .bannerImage .tornOverlayHorizontal {
      display: none;
    }

    .bannerImage .tornOverlayVertical {
      display: block;
    }
  }
}
