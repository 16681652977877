.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.cta_wrapper {
  text-align: center;
}

.message {
  min-height: 105px;
  margin-top: var(--space-large);
  padding: var(--space-base) var(--space-large);
}

.trendlineImage,
.trendlineImage > img {
  width: 100%;
}
