.container {
  margin-top: var(--space-small);
  padding: var(--space-small) var(--space-base);
  border-left: 2px solid var(--color-border);
}
.sendButton {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--space-small);
}
.row {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--space-smaller);
  gap: var(--space-small);
  align-items: flex-end;
}
.replyDate p {
  line-height: var(--typography--lineHeight-base);
}
.inputTextContainer {
  position: relative;
  padding: var(--space-smaller) var(--space-smaller);
  padding-bottom: 0px;
}
.inputTextContainer textarea {
  min-height: 1rem;
}
