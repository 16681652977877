.topMargin {
  margin-top: var(--space-large);
}

.timesContainer {
  margin-bottom: var(--space-base);
}

.timesContainer > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
