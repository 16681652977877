.onlinePaymentOption {
  display: flex;
  align-items: center;
}

.rightSwitch {
  display: flex;
  height: fit-content;
  margin-left: auto;
}

.helpText {
  margin-top: var(--space-smaller);;
}

.containerLabelAndIcon {
  display: flex;
  gap: var(--space-smaller);;
  align-items: center;
}

.containerLabelAndIcon > button {
  display: inline-flex;
  align-items: center;
}

.containerLabelAndIcon > button > svg {
  vertical-align: middle;
  cursor: pointer;
}

