.headingSection {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  padding: var(--space-base) 0;
}

.arrowImg {
  max-height: 4.5rem;
  transform: translate(0rem, 2rem) rotate(45deg);
}

.headingSection > h2 {
  color: var(--color-text--reverse);
}

.featureCopyOne > h3 {
  padding: var(--space-base) 0 var(--space-small) 0;
  color: var(--color-text--reverse);
}

.featureCopyTwo > h3 {
  padding: var(--space-base) 0 var(--space-small) 0;
  color: var(--color-text--reverse);
}

.featureCopyOne > p {
  padding-bottom: var(--space-larger);
  color: var(--color-text--reverse);
  font-weight: 300;
}

.featureCopyTwo > p {
  color: var(--color-text--reverse);
  font-weight: 300;
}

@media screen and (min-width: 20rem) {
  .headingSection {
    padding-bottom: 0;
  }

  .arrowImg {
    transform: translate(0rem, 0.9rem) rotate(45deg);
  }
}

@media screen and (min-width: 29rem) {
  .arrowImg {
    max-height: 4rem;
  }

  .headingSection {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 40rem) {
  .headingSection {
    padding: var(--space-base) 0;
  }

  .arrowImg {
    transform: translate(0rem, 1.7rem) rotate(45deg);
  }
}

@media screen and (min-width: 42rem) {
  .featuresHighlightsContainer {
    width: 90%;
  }

  .headingSection {
    padding: var(--space-large) 0;
  }
}

@media screen and (min-width: 49rem) {
  .arrowImg {
    max-width: 2.75rem;
    transform: translateY(4px) rotate(5deg);
  }

  .headingSection > h2 {
    padding-right: var(--space-small);
  }
}

@media screen and (min-width: 64rem) {
  .featuresHighlightsContainer {
    width: 70%;
  }
}
