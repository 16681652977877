.promoOfferContainer {
  display: flex;
  height: 114px;
  margin-bottom: var(--space-small);
  background-image: url("~@images/DowngradePromoModalPaintBackground.png");
  background-position: center center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.promoOfferAmount > p {
  color: var(--color-base-white);
  font-family: var(--typography--fontFamily-display);
  font-size: var(--typography--fontSize-jumbo);
  font-weight: 800;
  line-height: var(--typography--lineHeight-minuscule);
}

.promoOfferDuration > p {
  color: var(--color-base-white);
  font-family: var(--typography--fontFamily-display);
  font-size: var(--typography--fontSize-largest);
  font-weight: 800;
  line-height: var(--typography--lineHeight-tightest);
}

.promoOfferDescription {
  padding: var(--space-small) 0;
}

.promoOfferErrorBanner button {
  min-height: auto;
  padding: 0;
  border: 0;
  color: var(--color-interactive) !important;
  letter-spacing: 0;
  vertical-align: baseline;
}

.promoOfferErrorBanner button > span {
  font-weight: 500;
}

@media (max-width: 640px) {
  .promoOfferContainer {
    background-image: url("~@images/DowngradePromoModalPaintBackgroundMobile.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .promoOfferAmount > p {
    font-size: 24px;
  }

  .promoOfferDuration > p {
    font-size: var(--typography--fontSize-larger);
  }
}
