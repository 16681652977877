.filtersContainer {
  display: flex;
  gap: var(--space-small);
  width: 100%;
  margin: 0;
  align-items: flex-start;
}

.filtersContainer button {
  white-space: nowrap;
}

@media (max-width: 1270px) {
  .filtersContainer button {
    width: 100%;
  }

  .filtersContainer > div {
    flex-grow: 1;
  }
}

@media (max-width: 1025px) {
  .filtersContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: auto;
  }
}

@media (max-width: 768px) {
  .filtersContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
