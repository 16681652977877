.contactDetails {
  --message-weight: 400;
  display: flex;
  max-width: 75%;
  margin-bottom: var(--space-smaller);
  font-weight: var(--message-weight);
  align-items: flex-end;
}

.outbound.contactDetails {
  align-self: flex-end;
}

.contactDetails .emailAddress {
  display: block;
  margin-bottom: var(--space-small);
}

.clickable {
  cursor: pointer;
}

.iconButton {
  margin-left: var(--space-smaller);
}
