.container {
  display: flex;
  align-items: center;
}

.containerExpanded {
  flex-direction: row;
}

.containerCollapsed {
  flex-direction: column;
}

@media screen and (max-width: 640px) {
  .container {
    display: none;
  }
}

/*
  Base styles from vendor/assets/sg-1/components-web-app.scss:1529
*/

.secondaryLogo {
  width: 3.75rem;
  height: 2.9375rem;
  background: center no-repeat;
  background-size: 1.75rem;
}

.secondaryLogo:hover,
.secondaryLogo:focus {
  background-color: rgb(1, 37, 51);
}
