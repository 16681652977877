.container {
  position: absolute;
  right: -2px;
  height: 25px;
  min-width: 25%;
  padding-left: 3px;
  padding-right: 3px;
  border: none;
  border-width: var(--border-base);
  border-radius: var(--radius-larger);
  background-color: var(--color-surface);
  transform: scale(0.8);
  transform-origin: right;
}

.container[data-direction="to"] {
  top: -27px;
}

.container[data-direction="from"] {
  bottom: -27px;
}

.content {
  flex-direction: row;
  display: flex;
  padding: 3px;
  align-items: center;
  align-content: center;
}

.icon {
  margin-bottom: var(--space-smallest);
  margin-left: var(--space-smaller);
}
