.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 40rem) {
  .onboardingMobileCardContainer {
    display: none;
  }
}
