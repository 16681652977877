.container {
  height: 100%;
}

.pageWrap {
  overflow-y: auto;
}

.pageWrap > div {
  padding: var(--space-base);
}

.pageWrap h1 {
  margin-bottom: 0;
}

.todayCard {
  margin-bottom: var(--space-base);
  grid-column: 1 / span 3;
}

.fourColumns {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: var(--space-base);
  align-items: start;
}

.pageGrid {
  display: grid;
  height: 100%;
  grid-template-columns: minmax(0, 1fr) minmax(min-content, max-content);
  grid-template-rows: 1fr;
}

.liteToCoreTrialPopoverContainer {
  display: none;
}

.liteToCoreTrialInTrialCardContainer {
  display: block;
}

@media screen and (min-width: 40rem) {
  .liteToCoreTrialPopoverContainer {
    display: block;
  }

  .liteToCoreTrialInTrialCardContainer {
    display: none;
  }
}
