.mainContainer {
  display: flex;
  width: 100%;
  max-width: 485px;
  padding: 0 var(--space-large);
  flex-direction: column;

  @media (--small-screens-and-up) {
    padding: 0 0;
    flex-basis: 485px;
  }
}

.mainContainer h1 {
  text-align: center;
}

.errorBanner {
  width: 100%;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: var(--space-base);
    text-align: center;
  }
}

.button {
  margin-bottom: var(--space-small);
}
