.send {
  margin-right: var(--space-base);
  padding-top: var(--space-base);
}

.input {
  margin: var(--space-base);
}

.bottom {
  display: block;
  bottom: 0;
}

.container {
  flex-shrink: 0;
  margin: var(--space-smallest);
}
