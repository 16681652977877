.logoIcon {
  width: 24px;
  height: 24px;
  padding-right: 0.5rem;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.row {
  display: flex;
}

.featureRow {
  margin-bottom: 0.5rem;
}

.paddingLeft {
  padding-left: 2rem;
}

.iconMargin {
  padding-right: 0.5rem;
}

.textContainer p {
  line-height: 1.45em;
}
