.firstHeader {
  margin-top: var(--space-small);
}

.outerButtonContainer {
  display: flex;
  justify-content: space-between;
  margin: var(--space-base);
  padding: 0 var(--space-small);
}

.rightButtonContainer {
  display: flex;
}

.radioGroup {
  padding-bottom: var(--space-small);
}
