.customFieldChipAppLogo {
  width: var(--space-large);
  height: var(--space-large);
  margin-left: calc(-1 * var(--space-smaller));
  margin-right: var(--space-small);
  border-radius: var(--radius-small);
}

.customFieldChipLabel {
  margin-right: calc(-1 * var(--space-small));
}
