.featuredCard {
  min-width: 15.625rem;
  scroll-snap-align: none;
}

/* Force the Atlantis card to hide overflow so feature logos get clipped by border radius */
.featuredCard > div {
  border: var(--border-base) solid var(--color-border);
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .featuredCard {
    min-width: 88vw;
    scroll-snap-align: center;
  }

  .featuredCard:nth-child(1) {
    padding-left: var(--space-base);
    scroll-snap-align: start;
  }

  .featuredCard:nth-child(3) {
    padding-right: var(--space-base);
    scroll-snap-align: end;
  }
}

.highlightedCard > * > * {
  background-color: var(--color-informative--surface) !important;
}

.card p,
.featuredCard p {
  -moz-box-orient: vertical; /* Mozilla */
  -webkit-box-orient: vertical; /* WebKit */
  /* Disabling since we don't have the context as to why it's written this way */
  /* DO NOT COPY */
  /* stylelint-disable-next-line property-no-unknown */
  box-orient: vertical;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  gap: var(--space-base);
}

:root[data-theme="dark"] {
  .row img {
    border-radius: var(--radius-small);
    background-color: var(--color-white);
  }
}

.integrationCardContent {
  display: flex;
  flex-direction: column;
  gap: var(--space-smaller);

  overflow: hidden;
}

.integrationCardContent h3,
.integrationCardContent h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
