.tableWrap {
  border-radius: var(--radius-base);
  overflow: hidden;
  background-color: var(--color-surface);
}

.emptyMessage {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: var(--space-largest);
  right: 0;
  bottom: var(--space-largest);
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.tableAccessory {
  padding: var(--space-base) var(--space-base) var(--space-small)
    var(--space-base);
  border: var(--border-base) solid var(--color-border);
  border-bottom: 0;
}

.tableAccessory > .searchContainer {
  max-width: 288px;
}
