.table {
  --border-color: var(--color-border);
  --header-border-color: var(--color-border--section);
  --horizontal-inset: var(--space-base);
  position: relative;
  width: 100%;
  border-collapse: collapse;
  border-radius: var(--radius-base);
  overflow: hidden;
  background-color: var(--color-surface);
}

.table::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: var(--border-base) solid var(--border-color);
  border-radius: var(--radius-base);
  pointer-events: none;
}

.row:not(:last-child) {
  border-bottom: var(--border-base) solid var(--border-color);
}

.header .row {
  border-bottom: var(--border-base) solid var(--border-color);
  color: var(--color-heading);
  font-family: var(--typography--fontFamily-display);
  font-weight: bold;
  text-transform: uppercase;
}

.footer .row {
  border-top: var(--border-base) solid var(--border-color);
  color: var(--color-heading);
}

.row.interactive:hover {
  background-color: var(--color-surface--hover);
  cursor: pointer;
}

.row.interactive:focus-visible {
  outline: none;
  background-color: var(--color-surface--hover);
}

.table > tbody > .row > div,
.table > tfoot > .row > div {
  display: table-cell;
  text-align: right;
}

.table > tbody > .row > div > td,
.table > tfoot > .row > div > td {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.table > tbody > .row > div > span,
.table > tfoot > .row > div > h5 {
  display: none;
}

.table > thead > .row > td:first-child,
.table > tbody > .row > td:first-child,
.table > tfoot > .row > td:first-child {
  padding-left: var(--horizontal-inset);
}

.table > thead > .row > td:last-child,
.table > tbody > .row > td:last-child,
.table > tfoot > .row > td:last-child,
.table > thead > .row > div:last-child > td,
.table > tbody > .row > div:last-child > td,
.table > tfoot > .row > div:last-child > td {
  padding-right: var(--horizontal-inset);
}

/** Responsiveness for small screens */
@media screen and (max-width: 1024px) {
  .table {
    display: flex;
    flex-direction: column;
  }

  .header {
    border-bottom: var(--border-base) solid var(--border-color);
  }

  .table > thead > tr > th > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .row {
    padding-top: var(--space-base);
    padding-bottom: var(--space-base);
    border-bottom: var(--border-base) solid var(--border-color);
  }

  .row > td {
    padding: var(--space-smaller) var(--space-base);
    text-align: left;
  }

  .row > td:first-child,
  .table > tbody > .row > div {
    padding-top: var(--space-base);
  }

  .table > tbody > .row > div,
  .table > tbody > .row > div > span,
  .table > tfoot > .row > div > h5 {
    display: flex;
    width: 100%;
  }

  .table > tbody > .row > div,
  .table > tfoot > .row > div {
    display: flex;
    width: 100%;
    padding: var(--space-smaller) 0 0 var(--space-base);
    flex-direction: row;
  }

  .table > tbody > .row,
  .table > thead > .row > div,
  .table > tfoot > .row {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  /** this row doesn't have a classname (i.e. "row") so we need to use the element "tr" as selector **/
  .table > thead,
  .table > thead > tr,
  .table > thead > tr > th {
    display: flex;
    width: 100%;
  }

  .row > td:first-child,
  .row > td:last-child {
    padding: var(--space-smaller) var(--space-base);
    text-align: left;
  }

  .table > tfoot > .row > div:last-child {
    padding-bottom: var(--space-smallest);
  }

  .table > thead > .row:nth-child(2) {
    display: none;
  }
}
