.header {
  padding: var(--space-base);
  background-color: var(--color-surface--background);
}

.header h3 {
  display: inline;
  margin-right: var(--space-base);
  font-family: var(--typography--fontFamily-display);
  font-size: var(--typography--fontSize-large);
  font-weight: 800;
  line-height: var(--typography--lineHeight-large);
  text-transform: uppercase;
}

.targettedCard {
  box-shadow: var(--shadow-focus);
  border-radius: var(--radius-base);
}
