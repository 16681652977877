.overrideDates > p {
  display: inline;
}

.showMoreDates {
  display: inline;
}

.showMoreDates > p {
  display: inline;
  color: var(--color-interactive);
}
