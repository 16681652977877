.actionButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.singleActionButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.twoRightActionButtonsContainer {
  display: flex;
  justify-content: flex-end;
}

.twoRightActionButtonsContainer button {
  margin-left: 0.5rem;
}

.discountOfferContainer {
  display: flex;
  justify-content: center;
}
