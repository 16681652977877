.chat {
  color: var(--color-interactive);
}

.chat:hover {
  color: var(--color-interactive--hover);
  cursor: pointer;
}

.columnMaxWidth {
  max-width: 560px;
}

.topBarSpacing > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.splashContainer {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.splashImg {
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  height: 100%;
  max-width: 100%;
  filter: blur(2px);
}

/* override default from .card-headerTitle */
.splashText {
  color: var(--color-base-white);
  font-size: var(--typography--fontSize-jumbo);
  text-align: center;
  white-space: normal;
}

@media screen and (max-width: 1352px) {
  .splashText {
    font-size: var(--typography--fontSize-largest) !important;
  }
}

/* override default from .centerContainer */
.centerContainer {
  z-index: 0;
  max-height: 356px;
}
