.wrapper {
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
}

.content {
  padding: var(--space-base);
}

.inline {
  display: flex;
  padding-bottom: var(--space-base);
}

.inline h2 {
  margin-right: var(--space-base);
}
