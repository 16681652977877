.smallListItemWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 var(--space-small);
}

.smallListItemLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.updatedAtWrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.preventWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.justifyContentEnd {
  display: flex;
  text-align: right;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.marginY {
  margin-left: var(--space-small);
  margin-right: var(--space-small);
}

@media (--medium-screens-and-up) {
  .updatedAtWrapper {
    align-items: center;
  }
}
