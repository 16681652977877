.header {
  padding: var(--space-large) var(--space-large) var(--space-largest)
    var(--space-large);
  background-color: var(--color-surface--background--subtle);
}

.innerHeader {
  max-width: var(--integration-page-width);
  margin: 0 auto;
}

.twoThirdsGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-larger);
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: var(--space-base);
}

.logoImage {
  max-width: 192px;
  max-height: 192px;
}

.disclaimerContent {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-small);
}

@media (max-width: 639px) {
  .logoImage {
    max-width: 96px;
    max-height: 96px;
  }

  .header {
    padding-top: var(--space-large);
    padding-bottom: var(--space-large);
  }

  .twoThirdsGrid {
    gap: var(--space-large);
  }
}

@media (min-width: 840px) {
  .twoThirdsGrid {
    grid-template-columns: 2fr 1fr;
    gap: var(--space-extravagant);
  }

  .twoColumnGrid {
    grid-template-columns: minmax(64px, auto) 1fr;
  }
}
