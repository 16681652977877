.lineItemsTable {
  border-color: var(--color-border);
}

.title {
  display: flex;
  padding: var(--space-base);
  border-top-color: var(--color-surface--background);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.numeric {
  font-feature-settings: "tnum";
}

.mobileTitle {
  display: none;
}

.alignRight {
  text-align: right;
}

.numericBold {
  font-weight: bold;
}

/** Responsiveness for small screens */
@media screen and (max-width: 1024px) {
  .lineItemsTable > table > tbody > tr > div > td > span {
    font-weight: bold;
  }

  .lineItemsTable > table > tfoot > tr > td > button {
    display: none;
  }

  .lineItemsTable > table > tfoot > tr > td:not(.lineItemsTableFooterCell) {
    padding: 0;
  }

  .lineItemsTable > table > tfoot > tr > td.lineItemsTableFooterCell {
    display: flex;
  }

  .mobileTitle {
    display: flex;
    padding-left: var(--space-smaller);
  }

  .alignRight {
    display: flex;
    text-align: right;
    flex-direction: row;
    justify-content: space-between;
  }

  .alignRight > span:nth-child(2),
  .alignCenter > span:nth-child(2) {
    font-weight: bold;
  }
}
