.sectionContainer {
  display: flex;
  padding: var(--space-base);
  border: var(--border-base) solid var(--color-border);
  flex-direction: column;
  flex: 1 1 100%;
}

.iconTitleContainer {
  display: flex;
}

.appLogoIconContainer {
  min-width: 24px;
  margin-right: 8px;
}

.appTitleContainer {
  align-self: center;
}

.appLogoIcon {
  width: 24px;
  height: 24px;
}

.customField > div {
  padding: var(--space-small) 0;
  border-top: var(--border-base) solid var(--color-border);
}

.customField > div:first-child {
  border-top: none;
}

.customField > div:last-child {
  padding-bottom: 0;
}

.readOnlyEditingBlurb {
  padding-top: var(--space-small);
}
