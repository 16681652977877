.inboundChatBubble,
.outboundChatBubble,
.errorStateChatBubble {
  max-width: 75%;
  margin-top: var(--space-large);
  padding: var(--space-small) var(--space-base);
  border-radius: var(--space-smaller);
  color: var(--color-heading);
  font-family: var(--typography--fontFamily-normal);
  font-size: var(--space-base);
  line-height: var(--space-large);
  white-space: pre-wrap;
  background-color: var(--color-inactive--surface);
  overflow-wrap: break-word;
  align-self: flex-start;
}

.outboundChatBubble {
  align-self: flex-end;
  background-color: var(--color-success--surface);
}

.errorStateChatBubble {
  align-self: flex-end;
  background-color: var(--color-critical--surface);
}

.outboundChatBubble,
.inboundChatBubble,
.outboundTimeStamp,
.inboundTimeStamp {
  margin-left: var(--space-base);
  margin-right: var(--space-base);
}

.inboundTimeStamp,
.outboundTimeStamp,
.errorStateTimeStamp {
  display: inline-flex;
  padding: var(--space-smaller) 0;
  color: var(--color-text--secondary);
  align-self: flex-start;
}

.outboundTimeStamp {
  color: var(--color-text--secondary);
  align-self: flex-end;
}

.errorStateTimeStamp {
  color: var(--color-critical--onSurface);
  align-self: flex-end;
}

.timestampHeaderContainer {
  --message-weight: 400;
  display: flex;
  flex-direction: column;
  font-weight: var(--message-weight);
}

.timestampHeaderContainer h6 {
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  color: var(--color-text--secondary);
  text-align: center;
}
