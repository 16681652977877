.smsPreview,
.smsPreviewNoArrow {
  position: relative;
  padding: var(--space-base);
  border-radius: var(--space-base);
  background-color: var(--color-surface--background);
}

.smsPreview {
  margin-bottom: var(--space-base);
}

.smsPreview:before {
  content: "";
  position: absolute;
  bottom: -0.75rem;
  left: 0;
  border: 0.875rem solid transparent;
  border-left-color: var(--color-surface--background);
}

.emailPreview {
  padding: var(--space-base);
  border-radius: var(--radius-base);
  background-color: var(--color-surface--background);
}

.messageTemplateSubject {
  margin-bottom: var(--space-base);
  p {
    font-weight: bold;
  }
}

.messageTemplateFooterText {
  margin-top: var(--space-base);
}
