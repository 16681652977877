.icon-container {
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 0px var(--space-small);
  margin-bottom: 16px;
}

.description-text {
  grid-column: 2;
}
