.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-large) var(--space-base) 0;
  background-color: transparent;
}

.header h2 {
  line-height: 1.5;
  word-break: break-word;
}

.fullWidth {
  width: 100%;
}

.header .fullWidth {
  padding-left: 0 !important;
}
