/*
  faking a border with a background on the parent element, gaps & padding
  to prevent double borders on overlapping boxes
*/
.container {
  display: grid;
  width: 100%;
  padding: var(--border-base);
  border-radius: var(--radius-base);
  background: var(--color-border);
  grid-template-areas:
    ". . . . . . ."
    ". . . . . . ."
    ". . . . . . ."
    ". . . . . . ."
    ". . . lastday lastday lastday lastday";
  column-gap: var(--border-base);
  row-gap: var(--border-base);
}

.checkboxWrapper {
  position: relative;
}

.checkboxWrapper:nth-child(n + 1):nth-last-child(-n + 1) {
  grid-area: lastday;
}

/* kept present but hidden for accessibility */
.container input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  opacity: 0;
}

.dayCheckbox {
  display: block;
  padding: 1rem;
  text-align: center;
  user-select: none;
  background: var(--color-surface);
  transition: 300ms;
}

.dayCheckbox::after {
  content: "";
  display: block;
  position: absolute;
  top: calc(-1 * var(--border-base));
  right: calc(-1 * var(--border-base));
  bottom: calc(-1 * var(--border-base));
  left: calc(-1 * var(--border-base));
  z-index: 2;
  border-radius: var(--radius-base);
}

/* Checked stlying */
.container input[type="checkbox"]:checked + .dayCheckbox::after {
  border: var(--border-base) solid var(--color-interactive--hover);
}

.container input[type="checkbox"]:checked + .dayCheckbox {
  color: var(--color-text--reverse);
  background: var(--color-interactive);
}

.container input[type="checkbox"]:checked:focus + .dayCheckbox {
  background: var(--color-interactive--hover);
}

/* Disabling since we don't have the context as to why it's written this way */
/* DO NOT COPY */
/* stylelint-disable no-descending-specificity */
/* Focus & hover styling */
.container input[type="checkbox"]:focus + .dayCheckbox,
.dayCheckbox:hover {
  background: var(--color-surface--hover);
}
/* stylelint-enable no-descending-specificity */

.container input[type="checkbox"]:focus + .dayCheckbox::after {
  box-shadow: var(--shadow-focus);
}

.container input[type="checkbox"]:checked + .dayCheckbox:hover {
  background: var(--color-interactive--hover);
}

/* Disabled styling */
.container input[type="checkbox"]:disabled:checked + .dayCheckbox {
  color: var(--color-disabled--secondary);
  background: var(--color-disabled);
}

.container input[type="checkbox"]:checked:disabled + .dayCheckbox::after {
  border: none;
}

/* Disabling since we don't have the context as to why it's written this way */
/* DO NOT COPY */
/* stylelint-disable no-descending-specificity */
.container input[type="checkbox"]:disabled + .dayCheckbox {
  color: var(--color-disabled);
  background: var(--color-surface--background);
  cursor: default;
}

.container input[type="checkbox"]:enabled + .dayCheckbox {
  cursor: pointer;
}
/* stylelint-enable no-descending-specificity */
