.flexAndWrap {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .flexAndWrap {
    flex-wrap: wrap;
  }
}

.paddingRightSmall {
  padding-right: 1rem;
}

.marginBottomSmall {
  margin-bottom: 1rem;
}

.paddingSmall {
  padding: 1rem !important;
}

.paragraph {
  margin: 0 0 1rem;
  color: rgb(66, 78, 86);
  font-size: 0.875rem;
  line-height: 1.25em;
}
