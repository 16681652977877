.ratesRow {
  display: flex;
  flex-wrap: wrap;
}

.rate {
  display: flex;
  margin-bottom: var(--space-smallest);
  margin-right: var(--space-base);
}

.rate img {
  margin-right: var(--space-smallest);
}

.rateLabel {
  margin-left: 0.5rem;
}

.rateLabel:last-child {
  margin-left: 0;
}
