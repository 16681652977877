.flex {
  display: flex;
}

.affixedInputWrapper {
  flex: 1;
}

.affixedInputWrapper:focus-within :global(.input) {
  box-shadow: var(--shadow-focus);
}

.spaceBetween {
  justify-content: space-between;
}

.alignItemsCenter {
  align-items: center;
}
