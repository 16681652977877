.panelContainer {
  display: grid;
  width: 100%;
  padding: var(--space-base) var(--space-large);
  background-image: url("~@images/OnboardingChecklistBackground.png");
  align-content: center;
  justify-content: center;
  background-size: cover;
}

.rightContainer,
.rightContainerSmallScreen {
  padding-top: var(--space-large);
}

.rightContainerSmallScreen {
  padding-left: 0;
}

.leftContainer {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.description {
  white-space: pre-line;
}

.imageContainer {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  height: 350px;
}

.imageContainer > img {
  object-fit: scale-down;
}

.additionalImageContainer {
  flex-direction: row;
  flex-wrap: wrap;
}

.additionalImageContainer,
.appBadge,
.displayBadgeVertical {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.displayBadgeVertical {
  width: 300px;
  flex-direction: column;
}

.googlePlayBadgeVertical {
  width: 180px;
}
.appStoreBadgeVertical {
  width: 140px;
}

.appStoreBadge {
  display: inline-block;
  width: 120px;
  height: 60px;
  padding-left: var(--space-small);
  padding-right: var(--space-small);
}
.googlePlayBadge {
  display: inline-block;
  width: 150px;
  height: 60px;
}

.growTrialDescription {
  padding-top: var(--space-base);
}

.productExpertButton {
  display: flex;
  justify-content: center;
}

.productExpertButton > button {
  display: flex;
  background-color: transparent;
  justify-content: center;
}

.additionalImageContainer > button:focus {
  outline: none;
  color: transparent;
  background-color: transparent;
}

@media (--medium-screens-and-up) {
  .rightContainer {
    display: flex;
    padding-left: var(--space-largest);
    justify-content: center;
    align-items: center;
  }

  .jobCostingVideoContainer {
    padding: var(--space-extravagant);
  }

  .productExpertButton {
    justify-content: flex-start;
  }

  .productExpertButton > a {
    justify-content: flex-start;
  }
}

@media (--large-screens-and-up) {
  .startGrowTrialLeftContainer {
    display: flex;
    height: 350px;
    max-width: 400px;
    padding-left: var(--space-largest);
    flex-direction: column;
    justify-content: center;
  }
}
