.factWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 70%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 10vh;
  padding-left: 40px;
}

.maskingTapeWrapper {
  display: inline-block;
  position: relative;
  padding: var(--space-large);
}

.backgroundSvg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.beforeSvg {
  position: absolute;
  top: 0;
  left: 0;
  max-width: fit-content;
  transform: translate3d(-13%, -75%, 0);
}

.content {
  padding: 0 var(--space-base);
  text-align: center;
}

.maskingTapeWrapper h1,
.maskingTapeWrapper h2,
.maskingTapeWrapper h3 {
  color: var(--color-white);
}
