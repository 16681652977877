.container {
  display: flex;
  flex-direction: column-reverse;
  margin: var(--space-large);
  margin-bottom: 0;

  @media (--large-screens-and-up) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-extravagant);
    margin: var(--space-base) var(--space-extravagant);
  }
}

.contentSection {
  > * {
    margin-bottom: var(--space-base);
  }

  > h2 {
    margin-bottom: var(--space-large);
  }

  @media (--large-screens-and-up) {
    max-width: 304px;
  }
}

.content p {
  margin-bottom: var(--space-base);
}

.imageSection {
  display: flex;
  margin-bottom: var(--space-large);
  justify-content: center;
  align-items: center;

  img {
    max-height: 270px;
  }
}
