.loadingOverlayContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
  background: rgba(255, 255, 255, 0.9) !important;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-backdrop-filter: blur(15px) !important;
  backdrop-filter: blur(15px) !important;
}

.loadingOverlayContainer:global(.loadingOverlayfadeOut) {
  opacity: 0;
  animation: fade-out 0.75s linear;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
