.preventWrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.jobNumberLink {
  display: inline;
}

.jobNumberLink a {
  padding: var(--space-smallest);
  color: var(--color-interactive);
  text-decoration: underline;
}

.justifyContentEnd {
  display: flex;
  text-align: right;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
