.sidebarTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--space-base);
}

.sidebarTitle h2 {
  color: var(--color-base-purple--800);
  font-family: var(--typography--fontFamily-display);
  font-weight: 800;
}

.assistantButtonWrapper {
  display: inline-block;
  width: 40px;
}

:root[data-theme="dark"] .sidebarTitle h2 {
  color: var(--color-base-purple--300);
}
