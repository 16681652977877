.container {
  display: grid;
  grid-column-gap: var(--space-larger);
  grid-row-gap: var(--space-larger);
  grid-template-rows: auto;
  grid-template-areas:
    "bankDetails"
    "notifications";
}

.containerWithInsights {
  display: grid;
  grid-column-gap: var(--space-larger);
  grid-row-gap: var(--space-larger);
  grid-template-rows: auto;
  grid-template-areas:
    "financialInsights" /* financialInsights grid area will be included if FinancialInsightsSection is rendered  */
    "bankDetails"
    "notifications";
}

.bankDetails {
  grid-area: bankDetails;
}

.notifications {
  grid-area: notifications;
}

.disableJobberPaymentsContainer .disableJobberPaymentsDescription {
  margin-bottom: var(--space-small);
}

/* Media query for larger screens (desktop) */
@media (min-width: 1024px) {
  .containerWithInsights {
    grid-template-areas:
      "financialInsights financialInsights"
      "notifications bankDetails";
  }

  .container {
    grid-template-areas: "notifications bankDetails";
  }
}

@media (max-width: 40rem) {
  .pageWrapper h1 {
    display: none;
  }
}
