.spinnerLineItem,
.buttonLineItem > * {
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  border-top: var(--border-base) solid var(--color-border);
}

.centeredBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
