.errorState {
  justify-content: center;
  text-align: center;
}

.loadingState {
  height: 108px;
}

.sparkLine {
  width: 100%;
}

.sparkLineBox {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
