@media screen and (min-width: 640px) and (max-width: 1024px) {
  .promotionImage {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .promotionImage {
    min-width: 1200px;
  }
}
