.container {
  height: 100%;
}

.glimmerCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.glimmerCol {
  display: flex;
  flex-direction: column;
}

.glimmerColSmall {
  flex-basis: 50%;
}

.glimmerColLarge {
  width: 100%;
}

.glimmerField {
  display: flex;
  margin-top: var(--space-small);
  margin-bottom: var(--space-base);
  margin-left: 0;
  margin-right: var(--space-base);
}

.glimmerTray {
  display: flex;
  margin-top: var(--space-small);
  margin-left: 0;
}

.glimmerFieldShort {
  width: 75%;
}
