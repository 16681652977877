.spinnerCenter {
  margin: auto;
}

.inputField {
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
}

.actionBar {
  display: flex;
  padding-top: 0;
  flex: 1 1 100%;
  justify-content: flex-end;
}

/**
 * 1. Use CSS `order` to adjust the buttons position on the UI
 */

.leftAction {
  flex: 1 1 auto;
  order: 1; /* 1 */
}

.rightAction {
  display: flex;
  flex: 0 0 auto;
  order: 2; /* 1 */
}

.rightAction > * {
  margin-left: var(--space-small);
}

.rightAction *:first-child {
  order: 2; /* 1 */
}

.rightAction *:nth-child(2) {
  order: 1; /* 1 */
}

.formHeader {
  padding-top: var(--space-base);
}

.flexRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.flexBasis {
  flex-basis: 100%;
}

.gridContainer {
  display: grid;
  grid-gap: var(--space-base);
  grid-template-columns: repeat(auto-fit, minmax(267px, 1fr));
}
