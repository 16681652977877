.valueTextHeading {
  grid-column: 1;
  margin: 0;
  align-self: center;
}

.netLossAmount {
  color: var(--color-critical--onSurface);
}

.profitMarginBreakdownContainer {
  display: grid;
  gap: var(--space-smaller);
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  text-align: left;
  align-items: flex-end;
}

.profitBreakdownTitle {
  margin: 0;
  color: var(--color-text--secondary);
}

.separator {
  margin-left: var(--space-small);
  margin-right: var(--space-small);
  justify-self: center;
}

.separator h4 {
  color: var(--color-text--secondary);
}

.blurredText {
  user-select: none;
  filter: blur(5px);
}

.profitMarginBreakdownContainer > h4 > span {
  display: none;
}

.divider {
  display: none;
}

.titleWithLabel {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: var(--space-smaller);
}

/* DO NOT COPY THIS! This is a custom breakpoint for Profit Bar only */
@media screen and (max-width: 1180px) {
  .separator {
    display: none;
  }

  .profitMarginBreakdownContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--space-smaller);
  }

  .profitMarginBreakdownContainer:nth-of-type(2) {
    margin-top: 0;
    margin-bottom: var(--space-base);
  }

  .profitMarginBreakdownContainer > h4 > span {
    display: inline-block;
  }

  .profitMarginBreakdownContainer:nth-of-type(2) > h4 > span,
  .profitMarginBreakdownContainer:last-of-type > h4 > span {
    display: none;
  }

  .profitMarginBreakdownContainer:last-of-type {
    margin-top: var(--space-base);
  }

  .profitMarginBreakdownContainer:nth-of-type(3) {
    margin-top: 0;
  }

  .profitMarginBreakdownContainer > h4 {
    font-size: var(--typography--fontSize-base);
  }

  .divider {
    display: block;
    margin-top: var(--space-base);
  }
}
