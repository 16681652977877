.buttonContainer {
  display: flex;
  margin-top: 1rem;
  justify-content: flex-end;
}

.buttonContainer button {
  border-radius: 2px;
}

.buttonContainer button:first-child {
  margin-right: 0.75rem;
}
