.marketingSuiteAddonContainer {
  display: flex;
  position: relative;
  width: 100%;
  margin: 0 0 var(--space-base);
  padding: var(--space-large) ;
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
  flex-direction: column;
  gap: var(--space-larger);
}

.avatar {
  display: flex;
  justify-content: center;
  margin-bottom: var(--space-small);
}

.testimonial {
  padding: var(--space-base);
  border-radius: var(--radius-base);
  background-color: var(--color-surface--background--subtle);
}

.switch {
  position: absolute;
  top: var(--space-large);
  right: var(--space-large);
}

.businessData {
  display: flex;
  padding: var(--space-base);
  border-radius: var(--radius-base);
  background-color: var(--color-success--surface);
  flex-direction: row;
  gap: var(--space-base);
  align-items: center;
}

.glimmerContainer {
  margin-bottom: var(--space-base);
}

.glimmerCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.glimmerCol {
  display: flex;
  flex-direction: column;
}

.glimmerRow {
  display: flex;
  flex-direction: row;
}

.glimmerColLarge {
  width: 100%;
}

.glimmerFieldShort {
  width: 75%;
}
