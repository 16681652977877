.emailBody {
  text-align: left;
}

.htmlEmailBody {
  font-size: var(--typography--fontSize-large);
  line-height: 133%;

  > ol,
  > ul {
    list-style-type: revert;
    list-style-position: inside;
  }
}
