.radioGroupContainer {
  width: 100%;
}

.radioOption input[type="radio"] {
  position: relative;
  top: var(--space-smallest);
  width: var(--space-base);
  height: var(--space-base);
  margin-left: 0px;
  margin-right: var(--space-small);
  border: var(--border-thick) solid var(--color-interactive);
  border-radius: 50%;
  background-color: var(--color-surface);
  appearance: none;
}

.radioOption label {
  padding-right: var(--space-base);
  font-size: var(--typography--fontSize-large);
  cursor: pointer;
}

.radioOption input[type="radio"]:checked {
  border: var(--border-thicker) solid var(--color-green--dark);
  background-color: var(--color-interactive);
}

.radioOption input[type="radio"]:disabled + .radio-label:before,
.radioOption input[type="radio"]:disabled + .radio-label {
  border-color: var(--color-border);
}

.radioOption input[type="radio"]:disabled + label,
.radioOption input[type="radio"]:disabled:checked + .radio-label {
  color: var(--color-disabled);
  cursor: not-allowed;
}

.radioOption input[type="radio"]:disabled:checked + .radio-label:before,
.radioOption input[type="radio"]:disabled:checked + .radio-label {
  border-color: var(--color-disabled);
  background-color: var(--color-disabled--secondary);
}

.columnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 2;
}
