.textAction a,
.textAction button {
  min-height: unset;
  padding: 0;
  border-radius: var(--radius-small);
  vertical-align: top;

  &:hover,
  &:focus,
  &:disabled {
    background-color: unset;
  }

  &:disabled {
    border: unset;
  }
}

.textAction.disabled {
  cursor: not-allowed;
}
