.buttonContainer a span{
  font-size: 12px;
}

.trendWrapper {
  display: flex;
  align-items: center;
  gap: var(--space-small);

  > * {
    flex: 0 1 auto;
  }
}
