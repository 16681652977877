.upgradePromptWrapper {
  display: block;
  padding: 1.5rem 0;
}

.upgradePromptDescription {
  order: 0;
  margin-top: var(--space-base);
  text-align: center;
}

.upgradePromptDescription strong {
  white-space: nowrap;
}

.upgradePromptButton {
  order: 1;
  margin-top: var(--space-base);
}

@media only screen and (min-width: 40rem), print {
  .upgradePromptWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 11.5rem;
  }

  .upgradePromptTitle,
  .upgradePromptButton {
    width: 33.33333%;
  }

  .upgradePromptDescription {
    order: 1;
    margin: 0;
    text-align: left;
  }

  .upgradePromptButton {
    order: 0;
  }
}

@media only screen and (min-width: 60rem), print {
  .upgradePromptWrapper {
    max-height: 9.75rem;
  }
}
