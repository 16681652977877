.paymentMethodsContainer {
  display: flex;
  gap: var(--space-small);
  padding: var(--space-smaller);
  flex-wrap: nowrap;
  overflow-x: auto;
}

.paymentMethod {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 120px;
  min-width: 92px;
  box-sizing: border-box;
  padding: 12px;
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
  background-color: var(--color-surface);
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  transition: background-color 0.3s;
  gap: 4px;
  flex-grow: 1;
  flex-shrink: 1;
}

@media (min-width: 600px) {
  .paymentMethod {
    flex-basis: calc(25% - var(--space-small));
  }
}

.paymentMethod:hover {
  box-shadow: var(--shadow-base);
  border: var(--border-base) solid var(--color-interactive--subtle--hover);
  background-color: var(--color-surface--hover);
}

.paymentMethod:focus {
  box-shadow: var(--shadow-focus);
  border: var(--border-base) solid var(--color-interactive--subtle--hover);
  outline: none;
  background-color: var(--color-surface--hover);
}

.paymentMethod.selected {
  box-shadow: none;
  border: none;
  color: var(--color-on-primary);
  background-color: var(--color-interactive--subtle--hover);
}

.checkIcon {
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
  height: 18px;
  border-radius: var(--radius-circle);
  background-color: var(--color-surface);;
  align-items: center;
  justify-content: center;
}

.checkIcon svg {
  width: 12px !important;
  height: 12px !important;
}


.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
