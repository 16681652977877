.dateSelectorCardBody {
  display: flex;
  width: 370px;
  box-shadow: var(--shadow-base);
  padding: var(--space-base);
  border-radius: var(--radius-base);
  row-gap: var(--space-base);
  flex-direction: column;
}

.applyButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
