.container {
  height: 100%;
  flex: 1;
}

.deleteIconContainer {
  margin-left: auto;
}

.headerRowContainer {
  display: flex;
  align-items: center;
}

.rowContainer {
  display: flex;
  column-gap: var(--space-small);
}

.rowElement {
  width: 50%;
}
