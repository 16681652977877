.automaticPaymentsExpansionCardContainer {
  padding-top: var(--space-base);
}

.cardDescription {
  padding: var(--space-base) 0;
}

.buttonContainer {
  display: flex;
  gap: var(--space-base);
  padding-top: var(--space-small);
}

@media (--medium-screens-and-up) {
  .automaticPaymentsExpansionCardContainer {
    padding: 0;
  }
}
