.nameRowItem {
  width: 100%;
}

.secureCheckoutContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.secureCheckoutIconContainer {
  padding-right: var(--space-small);
}

@media screen and (max-width: 450px) {
  .secureCheckoutContainer {
    display: none;
  }
}
