.button {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--space-large);
}

.chipsContainer {
  --public-chip-base-bg-color: var(--color-text);
  --public-chip-base-hover-bg-color: var(--color-text);
  --public-chip-base-content-color: var(--color-text--reverse);
}

.promotionButtonGroup {
  display: flex;
  flex-direction: row;
  padding-top: var(--space-large);
}

.promotionButton {
  min-width: 200px;
  margin: 0 var(--space-small);
}

.otherText {
  padding-bottom: var(--space-base);
}

.goalCard {
  display: flex;
  flex-direction: "row";
  width: 100%;
  height: calc(50px + var(--space-base));

  margin-bottom: var(--space-base);
  padding: var(--space-base);
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);

  cursor: pointer;
  transition: all var(--timing-quick) linear;
  align-items: center;
  justify-content: space-between;
  outline-color: var(--color-focus);
}

.goalCard:hover,
.goalCard:focus {
  border-color: var(--color-interactive);
}

.goalCard:focus-visible {
  outline-style: solid;
}

.goalCardSelected {
  border-color: var(--color-interactive);
  outline-color: var(--color-interactive);
}

.goalCardSelected:hover,
.goalCardSelected:focus {
  background-color: inherit;
}

.icon {
  display: flex;
  align-items: center;
}

.themeButton:hover + .radioContainer label::before {
  border-color: var(--color-interactive);
}

.themeButton {
  cursor: pointer;
}

.themeButtonImage {
  padding-bottom: var(--space-small);
}

.radioContainer {
  display: flex;
  justify-content: center;
  margin-top: -5px; /* snugs the radio button up to the image to reduce the gap */
}

@media screen and (max-width: 500px) {
  .button {
    position: absolute;
    bottom: 0;
    width: calc(100% - (var(--space-large) * 2));
    padding-bottom: var(--space-large);
  }

  .promotionButtonGroup {
    display: flex;
    flex-direction: column;
    min-width: calc(100vw - (var(--space-large) * 2));
  }

  .promotionButton {
    padding-bottom: var(--space-base);
  }
}

@media screen and (min-width: 1024px) {
  .promotionButton {
    margin-left: 0;
    margin-right: var(--space-base);
  }
}
