.customFieldGroup {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 14px;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius, 4px);
}

.customFieldHeader {
  padding-top: 16px;
  border-top: 1px solid var(--color-border);
}

.customFieldGroup:last-child {
  margin-bottom: 0;
}

.archivedCustomFieldSectionButton button {
  display: flex;
  width: 100%;
  justify-content: left;
  padding-left: var(--space-base);
}

.disclosureHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.appInfo {
  display: flex;
  align-items: center;
}

.appLogo {
  width: var(--space-large);
  height: var(--space-large);
  margin-right: var(--spacing-small, 10px);
  border-radius: var(--radius-small);
}

.appName {
  font-size: var(--typography--fontSize-medium, 16px);
  font-weight: var(--typography--fontWeight-bold, bold);
}

.customFieldsList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fieldRow {
  display: flex;
  width: 100%;
  align-items: center;
  margin: var(--spacing-small, 8px) 0;
}

.fieldLabel {
  flex-shrink: 0;
  width: 150px;
  margin-right: var(--spacing-small, 10px);
  line-height: var(--line-height-base, 1.2);
  word-wrap: break-word;
}

.fieldValue {
  flex-grow: 1;
  width: 100%;
  max-width: 350px;
}

.linkField {
  display: inline-block;
  color: var(--color-interactive);
  font-weight: var(--typography--fontWeight-bold, bold);
  text-decoration: underline;
  cursor: pointer;
}

.linkContainer {
  display: flex;
  align-items: center;
}

.plainLink {
  margin-right: var(--spacing-small, 10px);
  text-decoration: underline;
  cursor: pointer;
}
