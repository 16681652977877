.accordionSection {
  display: flex;
  height: 100%;
  padding: var(--space-base);
  align-items: center;
  @media (--large-screens-and-up) {
    flex-basis: 65%;
  }
}

.accordionContainer {
  max-width: 488px;
  margin: 0 auto;
}

.headerContent {
  margin-bottom: var(--space-large);
}

.progressBar {
  margin-bottom: var(--space-base);

  @media (--medium-screens-and-up) {
    margin-bottom: var(--space-large);
  }
}
