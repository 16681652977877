.box {
  padding: var(--space-base);
  border-radius: var(--radius-base);
  background-color: var(--color-surface--background);
}

.dismissButton {
  cursor: pointer;
}

.dismissButton:focus {
  box-shadow: var(--shadow-focus);
  outline: none;
}

.actionButtons {
  display: flex;
  gap: var(--space-smaller);
}
