.chartWithTitle {
  display: flex;
  flex-direction: column;
  gap: var(--space-base);
}

.plotContainer {
  height: 250px;
  margin-bottom: var(--space-base);
}

.plotContainer svg {
  overflow: visible;
}
