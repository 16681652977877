.listItem {
  display: flex;
  list-style-type: none;
  align-items: top;
  gap: var(--space-base);
}

.iconItem {
  display: flex;
  align-items: center;
}

.listItem > :first-child {
  flex-shrink: 0;
}

.listItem:not(:last-child) {
  padding-bottom: calc(var(--space-small) * 1.5);
}
