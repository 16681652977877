.fieldsWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
}

.fieldWrapper {
  flex-direction: column;
  flex: 1;
}

.description {
  margin-top: 6px;
}

.fieldsWrapper > :first-child {
  z-index: 1;
  margin-right: -1px;
  --public-field--top-right-radius: 0;
  --public-field--bottom-right-radius: 0;
}

.fieldsWrapper > :last-child {
  --public-field--top-left-radius: 0;
  --public-field--bottom-left-radius: 0;
}
