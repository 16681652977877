.bankACHSecurityText {
  display: flex;
  margin: var(--space-large) 0 0;
  padding-bottom: var(--space-small);
}

.bankACHSecurityText > p {
  margin: var(--space-small) 0;
}

.bankIconLargeSpace {
  flex: 0 1 30%;
}

.bankIconSmallSpace {
  flex: 0 1 21%;
}
