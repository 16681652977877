.dynamicDataList {
  position: relative;
  padding: var(--space-base);
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
}

.dynamicDataList > dl {
  display: grid;
  margin: 0;
  grid-template-columns: auto 1fr;
  gap: var(--space-smaller) var(--space-small);
}

.dynamicDataList > dl > dt {
  font-weight: bold;
}

.dynamicDataList > dl > dd {
  margin-left: 0;
}

.dynamicDataListLoadingSpinner {
  position: absolute;
  right: var(--space-small);
  bottom: var(--space-small);
}
