.kpiSection {
  display: flex;
  gap: var(--space-small);
  margin: var(--space-smallest);
  margin-bottom: var(--space-largest);
  padding: var(--space-smaller);
  padding-left: 0;
  overflow-x: auto;
}

.kpiSection > :first-child {
  padding-left: 0;
}

.kpiContainer {
  display: flex;
  gap: var(--space-small);
  flex: 0 1 calc((100% / 12) * 7 - (var(--space-small) / 2));

  @media (--extra-large-screens-and-up) {
    flex-basis: calc((100% / 12) * 8 - (var(--space-small) / 2));
  }
}

.kpiCardIcon {
  position: absolute;
  top: var(--space-base);
  right: var(--space-base);
}

.kpiCardBody {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--space-base);
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 200px;
  padding: var(--space-base);

  .trendWrapper {
    display: flex;
    align-items: center;
    gap: var(--space-small);

    > * {
      flex: 0 1 auto;
    }
  }
}

.loadingState {
  height: var(--space-larger);
}

.errorState {
  margin-top: calc(var(--space-larger) - var(--typography--fontSize-small));
}

.featuredWrapper {
  flex: 0 1 calc((100% / 12) * 5 - (var(--space-small) / 2));
  min-width: 375px;

  &:empty {
    flex-basis: 0;
    min-width: 0;
  }

  @media (--extra-large-screens-and-up) {
    flex-basis: calc((100% / 12) * 4 - (var(--space-small) / 2));
  }
}

.staticFeature {
  flex: 0 0 375px;
}

.popoverWrapper button[aria-label="Close dialog"] {
  display: none;
}
