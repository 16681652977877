@media (hover: hover) {
  .infoIcon:hover {
    cursor: pointer;
  }

  .infoIcon:hover svg path {
    fill: var(--color-interactive--subtle--hover) !important;
  }
}

.popoverContainer {
  width: 348px;
}

@media only screen and (max-width: 348px) {
  .popoverContainer {
    width: 288px;
  }

  .popoverContainer h4 {
    font-size: var(--typography--fontSize-base);
  }

  .popoverContainer p {
    font-size: var(--typography--fontSize-small);
  }
}
