.labelShadowBox {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow-base);
  border-radius: var(--radius-large);
}

.appListingLabel {
  position: absolute;
  margin-top: -12px;
}

.offerLabel {
  display: inline-flex;
  padding: calc(var(--space-smaller) * 1.5) calc(var(--space-small) * 1.25);
  border-radius: var(--radius-large);
  background-color: var(--color-brand--highlight);
}
