.loadingBarWrapper::before {
  height: 24px;
}

.loadingBarContainer {
  position: fixed;
  right: 40px;
  bottom: 40px;
  left: 40px;
  height: 8px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--color-surface--background);
}

:global(.loadingOverlayfadeOut) .loadingBarContainer {
  opacity: 0;
  animation: fade-out 300ms ease;
}

@media (--medium-screens-and-up) {
  .loadingBarContainer {
    position: absolute;
    top: calc(50% + 72px);
    width: 30%;
    max-width: 310px;
  }

  /* start slide out when overlay fade out begins */
  :global(.loadingOverlayfadeOut) .loadingBarContainer {
    animation: slide-out 300ms ease, fade-out 300ms ease;
  }
}

.loadingBar {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: var(--color-surface--reverse);
}

/* finish loading before 300ms before overlay fade out begins (600ms from end of animation)  */
.loadingBarTwoStepAnimation .loadingBar {
  animation: loading-animation 6250ms linear;
}

/* finish loading before 300ms before overlay fade out begins (600ms from end of animation)  */
.loadingBarThreeStepAnimation .loadingBar {
  animation: loading-animation 9750ms linear;
}

@keyframes loading-animation {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes slide-out {
  0% {
    top: calc(50% + 72px);
  }

  100% {
    top: calc(50% + 72px + var(--space-large));
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
