.menuContainer {
  display: inline-block;

  /* **RuleButton has the same styling** */
  button {
    min-height: auto;
    padding: 0;
    vertical-align: baseline;
  }
}

.defaultButtonTextColor {
  button {
    color: rgba(1, 41, 57, 1);
  }
}

.errorButtonTextColor {
  button {
    color: var(--color-destructive);
  }
}
