.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: var(--space-small);
  overflow: hidden;
  text-wrap: nowrap;
}

.content {
  margin: var(--space-smaller) 0px var(--space-smaller) 0px;
  color: var(--color-text--secondary);
  font-size: var(--typography--fontSize-small);
  font-variant: normal;
  font-style: normal;
}
