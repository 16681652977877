.setup {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: var(--space-large);
  gap: var(--space-large);
  align-items: center;
}

@media (--large-screens-and-up) {
  .setup {
    flex-direction: row;
  }
}

.setup h1 {
  margin: 0;
}

.stepDirections {
  display: flex;
  flex-direction: column;
  gap: var(--space-base);
  max-width: 30rem;
}

.stepDirections > div:first-of-type p:first-of-type {
  font-size: var(--typography--fontSize-large);
}

.customMessage {
  display: flex;
  padding: var(--space-base);
  border-radius: var(--space-smallest);
  background: var(--color-informative--surface);
  flex-direction: column;
  gap: var(--space-smaller);
}

.infoBar {
  display: flex;
  padding: var(--space-small) var(--space-base);
  border-bottom: var(--space-minuscule) solid var(--color-border);
  border-left: var(--space-minuscule) solid var(--color-border);
  border-right: var(--space-minuscule) solid var(--color-border);
  justify-content: space-between;
  align-items: center;
}

.buttons {
  display: flex;
}

@media (--medium-screens-and-up) {
  .buttons {
    gap: var(--space-small);
  }
}

.img {
  width: 285px;
  height: 285px;
  aspect-ratio: 1/1;
}
