.container {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-base);
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: var(--space-base);
  border: var(--border-base) solid var(--color-border);

  border-radius: var(--radius-base);
  text-align: left;
  background: var(--color-surface);
  justify-content: space-between;
  flex-direction: row;
  column-gap: var(--space-base);
  transition: background-color var(--timing-base);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  row-gap: var(--space-small);
}

.inputLabelContainer input {
  position: absolute;
  opacity: 0;
}

.container:hover,
.content:hover,
.contentContainer:hover,
.inputLabelContainer:hover {
  background-color: var(--color-surface--hover);
}

.title {
  display: flex;
  align-items: center;
  column-gap: var(--space-smaller);
}
