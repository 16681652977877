.childWrapper {
  padding: var(--space-base);
  text-align: center;
}

.extraPadding {
  padding: var(--space-large);
}

.childWrapper h4 {
  margin-bottom: var(--space-base);
}

.bannerWrapper {
  padding: 0 var(--space-base) var(--space-base) var(--space-base);
}
