.expenseModalButtonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
}

.inputTime {
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: center;
}

.rightButtons {
  display: flex;
  gap: var(--space-small);
  flex: 0 0 auto;
  order: 2;
}
