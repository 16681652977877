.button {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  padding-left: var(--space-base);
  padding-right: var(--space-base);
  cursor: pointer;
}
.button:hover,
a.button:hover,
.button:focus,
a.button:focus,
.button:active,
a.button:active {
  box-shadow: var(--shadow-focus);
  border-color: var(--color-focus);
  outline: none;
  background-color: var(--color-surface--background);
}

.sideNav {
  padding-left: var(--space-smallest);
  padding-right: var(--space-smallest);
  color: var(--color-surface);
  background-color: var(--color-heading);
}

.button.sideNav:hover,
a.button.sideNav:hover,
.button.sideNav:focus,
a.button.sideNav:focus,
.button.sideNav:active,
a.button.sideNav:active {
  box-shadow: var(--shadow-focus);
  outline: none;
  color: var(--color-surface);
  background-color: var(--color-heading);
}
