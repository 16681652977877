.feedback {
  display: flex;
  align-items: center;
  /* Reduce the effective flex gap from the parent. */
  margin-top: calc(-1 * var(--space-small));
  padding: 0;
  column-gap: var(--space-minuscule);
  --color-surface--hover: var(--color-base-taupe--200);
}

:root[data-theme="dark"] .feedback {
  --color-surface--hover: var(--color-base-blue--700);
}

.modalRow {
  display: flex;
  align-items: center;
}
