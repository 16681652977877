.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.iconContainer {
  padding: var(--space-base);
}

.link {
  padding-left: var(--space-smaller);
  color: var(--color-interactive);
}
