.outer_wrapper {
  height: 100%;
}
.outer_wrapper > div {
  height: 100%;
  padding: var(--space-base);
  border: none;
  background-color: var(--color-surface--background);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header span {
  overflow-wrap: anywhere;
}

.message {
  margin: var(--space-base) 0;
}

.dismissButton {
  cursor: pointer;
}

.dismissButton:focus {
  outline: none;
}

.dismissButton:hover {
  font-weight: bold;
}
