.jobCost {
  margin-bottom: var(--space-larger);
}

.loading {
  display: flex;
  width: 100%;
  padding: var(--space-base);
  border: 0;
  justify-content: center;
  align-items: center;
}

.errorText {
  margin-left: var(--space-small);
}

.divider {
  height: var(--space-base);
  background-color: var(--color-surface--background);
}

.jobCostContent {
  margin-bottom: var(--space-larger);
  padding: 0;
  border: 0;
  border-radius: var(--radius-base);
  overflow: hidden;
  background-color: var(--color-surface--background);
}

.whiteContent {
  width: 100%;
  margin-bottom: var(--space-larger);
  padding: 0;
  border: 0;
}

.whiteContent > div::before {
  display: none;
}

/** Override card style - border */
.whiteContent > div {
  border: 0;
}

.jobCostContent > div {
  border: none;
}

.greyContent .jobCostTables {
  padding: 0 var(--space-base) var(--space-base);
  border-bottom-right-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
  background-color: var(--color-surface--background);
}
