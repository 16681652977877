.wrapper {
  display: flex;
  gap: var(--space-small);
  flex-wrap: wrap;
}

.fullWidth {
  width: 100%;
}

.wrapper button {
  padding: var(--space-small);
  text-align: left;
  background-color: var(--button--color-surface--hover);
}

.fullWidth > div {
  width: 100%;
}

.wrapper .notLoading button {
  background-image: var(--copilot-transition-gradient);
  background-size: 300% 100%;
}

.wrapper button:hover {
  border-color: var(--color-border--interactive) !important;
  background-position: right;
  transition: all var(--copilot-hover-transition-duration) ease;
}

.wrapper .loading button:hover {
  transition-duration: 0s;
}
