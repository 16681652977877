.appCustomFieldItemContainer {
  display: flex;
  flex-direction: row;
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
}

.appCustomFieldItemDragAnchor {
  align-self: flex-start;
  padding-left: var(--space-base);
  padding-right: var(--space-base);
}
