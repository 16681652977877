.image {
  width: 56px;
}

.expensesTable > table > tbody > tr > td {
  word-break: break-word;
}

.title {
  display: flex;
  padding: var(--space-base);
  border-top-color: var(--color-surface--background);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobileTitle {
  display: none;
}

.alignRight {
  text-align: right;
}

.numericBold {
  font-weight: bold;
}

/** Responsiveness for small screens */
@media screen and (max-width: 1024px) {
  .expensesTable > table > tbody > tr > td > div.descriptionGroup {
    display: flex;
    justify-content: space-between;
  }

  .expensesTable > table > tbody > tr > td > div > div.descriptionBlock {
    margin-right: var(--space-small);
  }

  .expensesTable > table > tfoot > tr > td:not(:last-child) {
    display: none;
  }

  .expensesTable > table > tbody > tr > td > div.dateAmountGroup {
    display: flex;
    padding-top: var(--space-small);
    justify-content: space-between;
  }

  .mobileTitle {
    display: flex;
  }

  .alignRight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
