.criteriaSelection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--space-small);
  padding-top: var(--space-small);
}

.validationWrapper {
  position: absolute;
  top: -500px;
  max-width: 1px;
  max-height: 1px;
}
