.loadingSpinner {
  display: flex;
  padding: var(--space-base);
  justify-content: center;
}

.paymentDetailsContainer {
  margin-bottom: var(--space-base);
}

.glimmerCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.glimmerCol {
  display: flex;
  flex-direction: column;
}

.glimmerColSmall {
  flex-basis: 50%;
}

.glimmerColLarge {
  width: 100%;
}

.glimmerField {
  display: flex;
  margin: var(--space-small);
  margin-left: 0;
  margin-right: var(--space-base);
}

.glimmerFieldShort {
  width: 75%;
}

.threeDSecureChallengeContainer {
  width: 100%;
  height: 400px;
}
