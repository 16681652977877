.container {
  position: relative;
}

.errorBanner {
  position: absolute;
  bottom: 100%;
  width: 100%;
  padding: var(--space-small);
}

.container textarea {
  height: auto;
}

.rewriteBar {
  display: flex;
  align-items: center;
  gap: var(--space-small);
  flex-basis: 100%;
}
