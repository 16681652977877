.recurlyHostedField {
  display: flex;
  z-index: var(--elevation-default);
  height: var(--space-largest);
}

.cardNumberInput {
  display: flex;
  z-index: var(--elevation-base);
  border: none;
  flex: 1 20 auto;
}

.monthExpirationInput {
  border-right-color: var(--field--border-color);
  border-right-style: solid;
  border-right-width: var(--border-base);
}

.cardNumberContainer {
  display: flex;
  width: 100%;
  border-color: var(--field--border-color);
  border-style: solid;
  border-width: var(--border-base);
  border-top-right-radius: var(--radius-base);
  border-top-left-radius: var(--radius-base);
  justify-content: space-between;
}

.cardBrandsContainer {
  display: flex;
  padding-left: var(--space-small);
  padding-right: var(--space-small);
  align-items: center;
  justify-content: flex-end;
}

.expirationContainer {
  width: 50%;
}

.expirationFields {
  display: flex;
  border-right: none;
  border-color: var(--field--border-color);
  border-style: solid;
  border-width: var(--border-base);
  border-bottom-left-radius: var(--radius-base);
}

.ccDetailsContainer {
  display: flex;
}

.cvcDetailsContainer {
  width: 50%;
}

.cvcFields {
  display: flex;
  flex: auto;
  border-color: var(--field--border-color);
  border-style: solid;
  border-width: var(--border-base);
  border-bottom-right-radius: var(--radius-base);
  align-items: center;
  justify-content: space-between;
}

.cvcInput {
  width: 100%;
  border: none;
}

.cvcImage {
  height: 70%;
  padding-left: var(--space-small);
  padding-right: var(--space-small);
}

.glimmer {
  min-height: var(--space-largest * 2);
  margin-bottom: var(--space-base);
}

.glimmerRow {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 750px) {
  .cardBrandsContainer {
    display: none;
  }
}
