.container {
  display: flex;
  width: 100%;
  padding: var(--space-smaller) 0;
}

.cardNumberContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: 0 var(--space-small);
}

.cardNumberInnerContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-right: var(--space-small);
}

.cardNumberInnerContainer > p {
  padding-bottom: var(--space-smallest);
}

.expiryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}

.expiryContainer.expiringSoon p {
  color: var(--color-warning--onSurface);
}

.expiryContainer.isExpired p {
  color: var(--color-critical);
}

.defaultCardBadge {
  padding: 0 var(--space-smallest);
}

.textContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardNumberTitle {
  margin-right: var(--space-small);
}

.cardNumber {
  text-wrap: nowrap;
}

@media only screen and (--medium-screens-and-up) {
  .cardNumberContainer {
    flex-direction: row;
  }
}
