.popoverContainer > div[role="dialog"] {
  z-index: 3;
  margin: 0.5rem 1rem;
}

.overlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  -webkit-box-orient: vertical;
  padding: 0.5rem 0;
  padding: 0 1rem;
  overflow-y: auto;
  background-color: var(--color-overlay);
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-name: animation-fadeIn;
  animation-name: animation-fadeIn;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.bottomBar {
  display: flex;
  align-items: center;
}

.stepLabel {
  flex-grow: 1;
}
