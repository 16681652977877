.banner > div {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.container {
  display: flex;
  flex-grow: 1;
  margin-right: 0 !important;
}

.showAllButton {
  position: relative;
  margin-right: var(--space-base);
  flex-shrink: 0;
}

.message {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: var(--space-small);
}

.message > p {
  margin-top: var(--space-smaller);
}

.countdownAndAction {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: space-between;
  gap: var(--space-base);
}

@media (min-width: 640px) {
  .message {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .message > p {
    max-width: 100%;
    margin-top: 0;
    flex: 1 0 auto;
  }

  .countdownAndAction {
    justify-content: flex-start;
  }

  .countdownAndAction,
  .countdownAndAction p {
    flex: 0 0 auto;
  }
}
