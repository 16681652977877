.header > span {
  display: inline-block;
  font-family: var(--typography--fontFamily-normal);
  font-size: var(--typography--fontSize-larger);
  font-weight: 500;
}

.header > strong {
  padding-bottom: 5px;
}

.valueMetric {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 30.4px;
  line-height: 30px;
  align-items: baseline;
}

.valueMetric .valueMetricMessage {
  font-family: var(--typography--fontFamily-normal);
  font-size: 25.6px;
  font-weight: 500;
}

.valueMetric .arrowIconWrapper {
  display: flex;
  margin-right: -5px;
  align-self: center;
}

.valueMetric .arrowIconWrapper > svg {
  width: 30px !important;
  height: 30px !important;
  fill: var(--color-heading) !important;
}
