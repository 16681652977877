:global([data-react-class="campaigns/index.CampaignsRoute"]) {
  display: contents;
}

.quoteFollowUpBannerContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (--medium-screens-and-up) {
  .quoteFollowUpBannerContainer {
    max-width: 590px;
  }
}
