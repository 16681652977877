.contentPageContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.editContainer,
.previewContainer {
  flex: 1 0 100%;
}
.previewContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: var(--color-surface--background);
}

@media (--large-screens-and-up) {
  .contentPageContainer {
    flex-wrap: nowrap;
    height: 100%;
    /** Prevent the container from growing below the BottomBar */
    overflow-y: hidden;
  }

  .editContainer {
    flex: 1 1 57%;
    height: 100%;
    overflow-y: auto;
  }

  .previewContainer {
    flex: 1 0 43%;
    height: 100%;
    overflow-y: auto;
  }
}
