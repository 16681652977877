.playerWrapper {
  position: relative;
  padding-top: 67%; /* Player ratio: magic value optimized for the NewHomeWelcomeCard */
}

.player {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  height: 100%;
}
