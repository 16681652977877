.container {
  display: flex;
  box-shadow: var(--shadow-high);
  padding: var(--space-small);
  border-radius: var(--radius-base);
  background-color: var(--color-surface);
  flex-direction: row;
  flex: 1;

  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-smaller);
  align-items: baseline;
  flex: 1;
  white-space: nowrap;
}

.textContainer p:last-child {
  display: flex;
  width: 100%;
  margin-bottom: 0;
}

.label {
  margin-top: var(--space-smallest);
  margin-right: var(--space-small);
}

.boldContent {
  font-weight: bold;
}
