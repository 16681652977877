.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--space-small);
  padding-bottom: 0;
}

.content {
  flex: 2;
  padding: var(--space-large) var(--space-base);
}

.heading {
  padding-bottom: var(--space-small);
  padding-right: var(--space-base);
}

.image {
  object-fit: contain;
  flex: 1;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.subHeading {
  max-width: 707px;
  padding-bottom: var(--space-base);
}

.donePanelContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: rgba(242, 240, 234, 1);
  background-size: cover;
}

@media (min-width: 769px) {
  .donePanelContainer {
    flex-direction: row;
  }

  .image {
    height: inherit;
    flex: none;
  }
}
