.paymentDisclaimer {
  padding-bottom: var(--space-small);
}

.addressRowItemLarge {
  width: 70%;
}

.addressRowItemSmall {
  width: 30%;
}

.loadingSpinner {
  display: flex;
  padding: var(--space-base);
  justify-content: center;
}
