.landingPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerContainer {
  max-width: 954px;
  padding: var(--space-largest) var(--space-base) 120px var(--space-base);
  text-align: center;
}

.headerButtonContainer {
  margin-bottom: var(--space-largest);
}

.tapeHeaderImg {
  width: 203.8px;
  margin: 0 auto;
}

.highlightsContainer {
  display: flex;
  flex-direction: column;
  max-width: 954px;
  padding: 0 var(--space-base);
  gap: var(--space-largest);

  @media (--large-screens-and-up) {
    gap: 120px;
  }
}

.comingSoonContainer {
  width: 100%;
  margin-top: var(--space-largest);
  padding: var(--space-larger) var(--space-base);
  text-align: center;
  background-color: var(--color-surface--background);

  @media (--large-screens-and-up) {
    margin-top: 120px;
  }
}

.content {
  max-width: 589px;
  margin: 0 auto;
}

.content h2 {
  margin: var(--space-small) 0 var(--space-base) 0;
}

.footerContainer {
  width: 100%;
  padding: calc(var(--space-largest) * 2) var(--space-large);
  text-align: center;
  background: var(--color-surface--reverse);
  background-image: url("~@images/backgroundGrain.png");
}

.footerBody {
  color: var(--color-text--reverse--secondary);
  font-size: var(--typography--fontSize-larger);
  font-weight: 800;
  line-height: 24px;
}

.footerTitle {
  max-width: 460px;
  margin: 0 auto var(--space-base) auto;
  color: var(--color-text--reverse);
  font-size: var(--typography--fontSize-jumbo);
  font-weight: 900;
  line-height: 39.96px;
}

.footerPrice {
  margin-left: var(--space-small);
}

.footerBasePrice {
  margin-right: var(--space-small);
  color: var(--color-text--reverse--secondary);
  text-decoration: line-through;
}
