.contentContainer {
  margin-top: 30px;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.optionChipsContainer {
  flex-shrink: 0;
}

.optionsContainer {
  margin-top: 10px;
}

.infoIcon {
  margin-bottom: var(--space-smallest);
  margin-left: calc(var(--space-smaller) * -1);
}

.descriptionForNoAutoPayAccounts {
  padding: var(--space-base) 0 var(--space-small) 0;
}
