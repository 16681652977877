.bigValue {
  margin: 0;
  font-family: var(--typography--fontFamily-display);
  font-size: var(--typography--fontSize-larger);
  font-weight: 800;
}

a.bigValue {
  color: var(--color-heading);
}

a.bigValue:hover,
a.bigValue:focus {
  color: var(--color-interactive);
}
