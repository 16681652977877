.container {
  display: flex;
  height: 43px;
  padding-top: var(--space-small);
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.header {
  flex: 1;
}

.iconContainer {
  margin-right: var(--space-base);
}
