.unreadBadge {
  display: inline-block;
  position: absolute;
  top: 0.1rem;
  right: 0.3rem;
  height: 0.85rem;
  border-radius: 15%;
  color: var(--color-white);
  font-family: var(--typography--fontFamily-normal);
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  background-color: var(--color-critical);
  outline-style: solid;
  outline-width: thin;
}
