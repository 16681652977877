.infoTipWithIcon {
  display: inline-flex;
  align-items: center;
  gap: var(--space-smaller);
  cursor: help;
}

.infoModalContentWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-large);
  justify-content: center;
}

.infoModalContentWrapper > * {
  flex: 0 1 auto;
}

.infoModelContent {
  display: flex;
  flex-direction: column;
  gap: var(--space-base);
}

.infoModelImage {
  padding: 0 var(--space-base);
}

.infoModelImage > img {
  box-shadow: var(--shadow-low);
  border-radius: 14%;
}

@media (--medium-screens-and-up) {
  .infoModalContentWrapper {
    flex-wrap: nowrap;
  }
  .infoModalContentWrapper > * {
    flex-basis: 50%;
  }
}
