.marketplaceLayout {
  width: 100%;
  max-width: calc(var(--base-unit) * 80);
  box-sizing: border-box;
  padding: var(--space-larger) var(--space-base);
}

.headerPadding {
  height: var(--space-larger);
  margin: 0 !important;
}

.layoutContent {
  padding-top: var(--space-small);
}

.layoutContent p {
  color: var(--color-heading);
  font-size: 18px;
}
