.viewClaimDetailsContainer {
  color: var(--color-interactive);
}

.viewClaimDetailsContainer:hover {
  color: var(--color-interactive--hover);
  cursor: pointer;
}

.viewClaimDetailsContainer:focus {
  display: inline-block;
  box-shadow: var(--shadow-focus);
  border-color: var(--color-focus);
  outline: none;
  background-color: var(--color-surface--hover);
}

.viewClaimDetailsContainer:active {
  box-shadow: none;
  border: none;
  outline: none;
  background-color: var(--color-surface--hover);
}
