.message,
.content,
.contentMessage,
.actions {
  display: grid;
}

.message {
  grid-template-columns: auto;
  gap: var(--space-small);
}

@media only screen and (min-width: 40rem) {
  .message {
    grid-template-columns: auto max-content;
  }
}

.content {
  grid-template-columns: max-content auto;
  gap: var(--space-small);
}

.contentMessage {
  padding-top: var(--space-smallest);
  grid-row-gap: var(--space-base);
}

.actions {
  grid-template-columns: max-content max-content;
  align-self: start;
}

.actions > :last-child {
  margin-left: var(--space-small);
}
