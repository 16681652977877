.overlayImage {
  margin: auto;
  padding: 0 var(--space-base);
  padding-top: var(--space-large);
}

.overlayHeader {
  padding: var(--space-base);
  padding-bottom: var(--space-small);
  text-align: center;
  align-self: center;
}

.sticky {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(var(--elevation-menu) + var(--elevation-modal));
  box-shadow: 0 0 0.25rem 0.0625rem var(--color-border);
  border: 1px solid var(--color-border);
  border-radius: var(--radius-larger);
  overflow: hidden;
  background-color: var(--color-surface);
  flex-direction: column;
}

.persistentStateContainer {
  display: flex;
  justify-content: space-between;
  padding: var(--space-large) var(--space-base);
}

.persistentStateHeader {
  padding: 0;
  text-align: center;
  align-self: center;
}
