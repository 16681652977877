.cardsContainerBase,
.fullWidth {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-large);
}

@media only screen and (--large-screens-and-up) {
  .cardsContainerBase {
    display: grid;
    grid-column-gap: var(--space-large);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .fourCardContainerBase {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
