.financialInsights {
  grid-area: financialInsights; /* to play nicely with the grid layout from the parent JobberPaymentsSettings component */
}

.financialInsightsCard {
  display: flex;
  height: 100%;
}

.divider {
  margin-top: var(--space-larger);
}
