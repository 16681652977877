.logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;

  width: var(--space-extravagant);
  max-height: var(--space-extravagant);

  padding: var(--space-small);
}

.logo.fullwidth {
  width: auto;
  height: calc(var(--space-base) * 10);
  max-height: calc(var(--space-base) * 10);

  padding: calc(var(--space-smaller) * 5) calc(var(--space-small) * 5);
}

.logo img {
  object-fit: contain;
  box-sizing: border-box;
}

.logo.fullwidth img {
  width: 100%;
  max-width: 18rem;
}
