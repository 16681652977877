.cardOverride { 
  width: 100%;
  padding-bottom: var(--space-small);
}
  
.cardOverride > div {
  width: 100%;
  border: 1px solid var(--color-base-purple--600);
}

.widget {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ReviewsEngagement.module.css */
@media (max-width: 768px) {
  .cardOverride > div > div > div {
    display: flex;
    flex-direction: column;
  }
}
