.gridContainer {
  display: grid;
  grid-gap: var(--space-base) var(--space-larger);
  grid-template-columns: 1fr;
}

@media only screen and (min-width: 40rem) {
  .gridContainer {
    grid-template-columns: 2fr 1fr;
  }
}
