.imageContainer {
  display: grid;
  border: var(--border-base) solid var(--color-border--interactive);
  border-radius: var(--radius-base);
  overflow: hidden;
  background: var(--color-surface);
  background-image: var(--copilot-transition-gradient);
  background-size: 300% 100%;
  transition: all var(--timing-base) ease-out;
  grid-template-columns: 72px 1fr;
}

.imageContainer:hover {
  color: unset;
  background-position: right;
  transition: all var(--copilot-hover-transition-duration) ease;
}

.image {
  height: 72px;
  object-fit: cover;
}

.imageTitle {
  padding: var(--space-small);
}

.imageTitle > p:first-child {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
