.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-base);
}

.logo {
  width: fit-content;
  height: fit-content;
}

.logo img {
  width: calc(var(--space-extravagant) + var(--space-base));
  height: calc(var(--space-extravagant) + var(--space-base));
  margin: var(--space-base);
}

.dottedLine {
  width: calc(var(--space-extravagant) + var(--space-base));
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-small);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: var(--space-small);
  justify-content: flex-end;
}

.developerMessageWrapper {
  box-sizing: content-box;
  padding: 0 var(--public-content--padding);
}

.errorMessage,
.developerMessage,
.requiredPlanMessage {
  color: var(--color-text);
  font-size: var(--typography--fontSize-base);
  font-weight: 400;
  line-height: var(--typography--lineHeight-base);
}

.errorMessage:not(:last-child) {
  padding-bottom: calc(var(--space-small) * 1.5);
}

.scopeCategory {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.scopeCategory .scopeCategoryContent {
  flex-grow: 2;
 }

.scopeCategory .scopeCategoryTitle {
  margin: 0;
  font-weight: 700;
  line-height: var(--typography--lineHeight-base);
}

.scopeCategory .scopeCategoryIcon {
  margin: auto var(--space-small) 0 0;
}

.scopeCategory .scopeCategoryIcon svg {
  margin-top: -4px;
}

.scopeCategory .scopeCategoryDescription {
  padding-left: var(--space-larger);
}

.scopeCategory .scopeCategoryDescription p:not(:last-child) {
  margin-bottom: var(--space-small);
}
