.container {
  border-radius: var(--radius-base);
  overflow: hidden;
  background-color: var(--color-surface--background);
}

.textContainer {
  padding: var(--space-base);
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-small);
}

.buttonRow {
  margin-top: var(--space-base);
}
