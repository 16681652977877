.onlinePaymentOption {
  display: flex;
  align-items: center;
}

.rightSwitch {
  display: flex;
  height: fit-content;
  margin-left: auto;
}

.helpText {
  font-style: italic;
}
