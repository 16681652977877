.row {
  display: flex;
  padding: var(--space-base);
  border-bottom: 1px solid var(--color-border);
  flex-wrap: wrap;
  align-items: center;
}

.row:last-child {
  border-bottom: none;
}

.name {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: var(--space-small);
}

.time {
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: var(--space-small);
}

.timerLink {
  display: flex;
  align-items: center;
  color: var(--color-heading);
  justify-content: flex-end;
}

.timerLink:hover {
  color: var(--color-green);
}

@media only screen and (min-width: 640px) {
  .row {
    flex-wrap: nowrap;
  }

  .name {
    width: var(--space-larger);
    margin-bottom: 0;
    margin-right: var(--space-base);
  }

  .time {
    width: 70px;
    margin-bottom: 0;
    margin-right: var(--space-base);
  }

  .timerLink {
    justify-content: start;
  }
}

.scheduleWrap {
  position: relative;
  flex: 1 1 auto;
  border-radius: var(--radius-base);
  overflow: hidden;
}

.scheduleScroll {
  overflow-x: auto;
  transition: all var(--timing-base);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.prevSchedule,
.nextSchedule {
  display: none;
  position: absolute;
  top: 0;
  z-index: var(--elevation-menu);
  width: var(--space-large);
  height: 100%;
  padding-top: var(--space-minuscule);
  background-color: var(--color-greyBlue);
  cursor: pointer;
  opacity: 0.8;
  transition: all var(--timing-base);
}

.prevSchedule {
  left: 0;
}

.nextSchedule {
  left: 100%;
  margin-left: -24px;
}

.prevSchedule:hover,
.nextSchedule:hover {
  background-color: var(--color-greyBlue--dark);
}

.prevSchedule:focus,
.nextSchedule:focus {
  background-color: var(--color-greyBlue--dark);
  outline-color: var(--color-focus);
}

.canScroll {
  display: block;
}

.scheduleScroll::-webkit-scrollbar {
  display: none;
}

.schedule {
  display: flex;
}

.appointment {
  width: 120px;
  margin-right: var(--space-smallest);
  cursor: pointer;
  transition: all var(--timing-base);
  flex: 0 0 auto;
}

.appointment:focus {
  outline-color: var(--color-focus);
}

.first {
  border-bottom-left-radius: var(--radius-base);
  border-top-left-radius: var(--radius-base);
}

.last {
  margin-right: 0;
  border-top-right-radius: var(--radius-base);
  border-bottom-right-radius: var(--radius-base);
}

.incompleteAppointment {
  background-color: var(--color-surface--background);
}

.incompleteAppointment:hover,
.incompleteAppointment:focus {
  background-color: var(--color-surface--background--hover);
}

.activeAppointment {
  background-color: var(--color-informative--surface);
}

.activeAppointment:hover,
.activeAppointment:focus {
  background-color: var(--color-lightBlue--lighter);
}

.completeAppointment {
  background-color: var(--color-success--surface);
}

.completeAppointment:hover,
.completeAppointment:focus {
  background-color: var(--color-green--lighter);
}

.appointmentDetails {
  padding: var(--space-smaller) var(--space-small);
}

.appointmentTime {
  font-size: 11px;
  font-size: calc(var(--typography--fontSize-small) * 0.785);
}

.appointmentTitle {
  overflow: hidden;
  font-size: 14px;
  font-size: var(--typography--fontSize-small);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.completeAppointment .appointmentTitle {
  text-decoration: line-through;
}
