.tornPage {
  position: absolute;
  top: -72px;
  z-index: -1;
  width: 100%;
  height: 486px;
  background-color: transparent;
  background-image: url("~@images/integrations/torn-effect-mask.png"),
    url("~@images/integrations/paper-background.png");
  background-position: bottom center, bottom center;
  background-repeat: no-repeat;
  background-size: 100% 154px, 100% 486px;
}

:root[data-theme="dark"] {
  .tornPage {
    background-color: var(--color-surface);
    background-blend-mode: color-burn;
  }
}

.tornPage.tornPagePublic {
  top: 0;
}

.pageWrap > div {
  margin: 0 auto;
}

.searchBoxSection {
  margin-top: var(--space-largest);
}

.appSection {
  margin: var(--space-extravagant) 0 0 0;
  padding: 0;
}

.ourPicksSection {
  grid-auto-flow: row;
  overflow-x: inherit;
}

.appGrid {
  display: grid;

  /* Set a minimum width that limits "All Apps" to a maximum of three columns. */
  --max-columns: 3;
  --total-column-gap: calc((var(--max-columns) - 1) * var(--space-base));
  --min-item-width: 15.625rem;
  --min-item-width-for-x-columns: calc(
    (100% - var(--total-column-gap)) / var(--max-columns)
  );

  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--min-item-width), var(--min-item-width-for-x-columns)), 1fr)
  );

  column-gap: var(--space-base);
  row-gap: var(--space-base);
  padding-top: var(--space-base);
}

@media screen and (max-width: 480px) {
  .ourPicksSection {
    margin-left: calc(var(--space-base) * -1);
    margin-right: calc(var(--space-base) * -1);
    padding-bottom: var(--space-small);
    overflow-x: auto;
    grid-auto-flow: column;
    scroll-snap-type: x mandatory;
  }

  .appGrid {
    --min-item-width: 88vw;
  }
}

.requestAppContainer {
  display: flex;
  width: auto;
  height: 96px;
  padding: 0px;
  background: var(--color-surface);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.appsSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0px 48px 0px;
}

.appsSectionContainer p {
  color: var(--color-critical);
}

.noResultsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0px 0px 0px;
}
