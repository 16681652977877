.cardPadding {
  padding: var(--space-larger) var(--space-large);
}

.cardContainer {
  text-align: center;
}

.cardContainer > div {
  border-color: var(--color-surface) !important;
  border-radius: 0% !important;
}

.cardHeadingSection {
  display: flex;
  flex-direction: column;
}

.cardHeading {
  padding-bottom: var(--space-base);
  text-transform: uppercase;
}

.cardHeading > h2 {
  font-weight: 800;
}

.cardDescription {
  padding-bottom: var(--space-large);
}

.monthlyPrice > p {
  font-weight: 700;
  text-decoration: line-through;
}

.annualPrice > h1 {
  font-size: 2.25rem;
}

.boldFont {
  font-weight: 600;
}

.costDescription {
  padding-top: var(--space-small);
  color: var(--color-text);
}

.featuresHeading > p {
  padding-top: var(--space-base);
  font-weight: 600;
}

.featureItem {
  padding: var(--space-small);
  border-bottom: 1px solid var(--color-border);
}

.featureItem:last-child {
  border-bottom: none;
}

.featuresDesktopView {
  display: none;
}

@media only screen and (min-width: 64rem) {
  .cardContainer {
    width: 100%;
    max-width: 60rem;
  }

  .cardHeadingSection {
    align-items: flex-start;
  }

  .cardHeading {
    padding-left: 0;
  }

  .cardDescription {
    width: 100%;
    max-width: 20rem;
    padding-right: var(--space-base);
    text-align: start;
  }

  .topCardSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .cardCostSection {
    padding-bottom: var(--space-base);
    text-align: right;
    align-self: flex-end;
  }

  .featuresHeading {
    display: flex;
    text-align: start;
    flex-direction: row;
    justify-content: space-around;
  }

  .featuresHeading > p {
    padding-top: 0;
  }

  .line {
    margin-bottom: var(--space-small);
    margin-left: var(--space-small);
    border-bottom: 1px solid var(--color-border);
    flex-grow: 1;
  }

  .featuresMobileView {
    display: none;
  }

  .featuresDesktopView > ul {
    display: flex;
    padding: 0 var(--space-base);
    list-style-type: disc;
    flex-direction: column;
    flex-basis: 60%;
  }

  .featuresDesktopView {
    display: flex;
    text-align: left;
  }
}
