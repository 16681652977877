.receptionistToggleFullWidth {
  width: 100%;
  padding: var(--space-base);
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  background-color: var(--color-surface--background--subtle);
  align-items: center;
  flex-direction: row;
}
.receptionistToggleLabel {
  display: inline-flex;
  margin-left: var(--space-small);
}
