.dialog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  z-index: 2000;
  background: var(--color-surface);
  animation: scaleIntoView 0.2s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  animation-delay: 0.2s;
}

@keyframes scaleIntoView {
  0% {
    visibility: visible;
    transform: scale(0.01);
  }
  100% {
    visibility: visible;
    transform: scale(1);
  }
}
