.accordionItemDetails {
  display: flex;
  justify-content: space-between;
}

.accordionItemTitle {
  display: flex;
}

.accordionItemTextRegular {
  /* Should be extracted to Atlantis if being used regularly */
  font-weight: 600;
}

.accordionItemTitleIcon {
  display: flex;
  flex: 0 0 auto;
  padding-left: var(--space-base);
  padding-right: 0;
}

.accordionItemDisplay {
  margin-right: var(--space-base);
}

.accordionItemTitleText {
  display: flex;
  flex: 1 1 auto;
  align-self: center;
}

.accordionSummary {
  list-style: none;
}

.accordionSummary::-webkit-details-marker {
  display: none;
}

.accordionItem {
  display: flex;
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  padding-right: var(--space-base);
  justify-content: space-between;

  cursor: pointer;
}

.accordionItem:focus,
.accordionItem:hover,
.accordionItemSelected {
  display: flex;
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  padding-right: var(--space-base);
  justify-content: space-between;
  font-weight: 600;
  background-color: var(--color-surface--active);
  cursor: pointer;
}

.removeBackgroundColour,
.removeBackgroundColour:hover,
.removeBackgroundColour:focus {
  background-color: transparent;
}

.accordionDetails[open] > .accordionItemDetails {
  -webkit-animation: openAnimation var(--timing-base);
  animation: openAnimation var(--timing-base);
}

@keyframes openAnimation {
  0% {
    margin-top: calc(var(--space-small) * -1);
    opacity: 0;
  }

  100% {
    margin-top: 0px;
    opacity: 1;
  }
}
.accordionContainerButton {
  width: 100%;
}

.secondaryTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.secondaryTitle {
  padding-right: var(--space-base);
}
