.jobCostDiscoveryCard {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border: 1px solid var(--color-border);
  border-radius: var(--radius-base);
  background-color: var(--color-surface--background);
}

.jobCostDiscoveryContainer {
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-content: center;
  padding: var(--space-base);
}

.jobCostDiscoveryText {
  margin: var(--space-small) 0;
}

.jobCostDiscoveryButtonContainer {
  display: flex;
  flex-flow: row;
}

.jobCostDiscoveryLearnMoreButton {
  margin-right: var(--space-small);
}

.jobCostDiscoveryTextHeader {
  display: flex;
}

.jobCostDiscoveryHighlight {
  display: none;
  margin-bottom: var(--space-small) 0;
}

.jobCostDiscoveryHighlight > strong {
  padding-bottom: var(--space-smallest);
}

.jobCostDiscoveryNonHighlight {
  display: inline-flex;
}

.dismissButtonContainer {
  display: block;
}

.dismissButton {
  margin-left: auto;
  cursor: pointer;
}

@media (min-width: 50rem) {
  .jobCostDiscoveryText {
    margin: var(--space-smaller) 0;
  }

  .dismissButtonContainer {
    display: flex;
    flex: 1 0;
  }

  .jobCostDiscoveryContainer {
    margin: 0;
  }

  .jobCostDiscoveryHighlight {
    display: inline-flex;
  }

  .jobCostDiscoveryNonHighlight {
    display: none;
  }
}
