.cellCheckBox {
  height: 3.5rem;
  padding: 0 var(--space-base);
}

.cellRow td {
  border-bottom: 1px solid var(--color-border);
}

.cellRow.active,
.cellRow:hover,
.selected {
  background-color: var(--color-surface--background);
}

.cellRow.active .cellCheckBox {
  border-left: 2px solid var(--color-interactive);
}
