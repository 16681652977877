.section {
  padding: var(--space-base);
  border-bottom: var(--border-base) solid var(--color-border);
}

.section:last-child {
  border-bottom: none;
}

.summaries {
  display: grid;
  grid-column-gap: var(--space-base);
  max-width: 100%;
  overflow: hidden;
}

.oneColumn {
  grid-template-columns: 1fr;
}
.twoColumns {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.fourColumns {
  grid-template-columns: repeat(4, max-content);
  grid-column-gap: var(--space-large);
}

.summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--space-base);
  flex: 0 0 100%;
}

.summary h3 {
  width: 64px;
  margin-right: var(--space-small);
  padding: var(--space-small) var(--space-base);
  border-radius: var(--radius-base);
  text-align: center;
}

.summary h5 {
  text-transform: uppercase;
}

.scheduled h3 {
  background-color: var(--color-surface--active);
}

.toGo h3 {
  background-color: var(--color-surface--background);
}

.active h3 {
  background-color: var(--color-informative--surface);
}

.complete h3 {
  background-color: var(--color-success--surface);
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
