.statsWrapper {
  position: absolute;
  top: 50%;
  right: 0;
  left: 40px;
  z-index: 3;
  width: 80%;
  max-width: 45vw;
  margin: 0 auto;
  transform: translateY(-50%);
}

.statsWrapper * {
  text-align: center;
}

.highlightHeading h1,
.whiteHeading h1 {
  position: relative;
  z-index: 2;
  margin: var(--space-base);
}

.highlightHeading h1 {
  color: var(--color-brand--highlight);
  font-size: 64px;
}

.whiteHeading h1 {
  color: var(--color-white);
  font-size: 55px;
}

.smallMaskingTape {
  display: inline-block;
  position: relative;
  margin-bottom: var(--space-small);
}

.smallMaskingTape img {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
}

.maskingTapeContainer {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  width: calc(100% + 30px);
  height: 100%;
  overflow: hidden;
  transform: translateX(-50%);
}

/* This was the breakpoint where font-size of 55px would break into 2 lines */
@media screen and (min-width: 1255px) and (max-width: 1409px) {
  .highlightHeading h1 {
    margin: var(--space-small);
    font-size: 58px;
  }

  .whiteHeading h1 {
    margin: var(--space-small);
    font-size: 50px;
  }
}

/* This was the breakpoint where font-size of 50px would break into 2 lines */
@media screen and (min-width: 1024px) and (max-width: 1255px) {
  .highlightHeading h1 {
    margin: var(--space-smaller);
    font-size: 47px;
  }

  .whiteHeading h1 {
    margin: var(--space-smaller);
    font-size: 40px;
  }
}
