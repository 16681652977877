.buttonRow {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  justify-content: space-between;
}

.intentContainer,
.operationContainer {
  flex-shrink: 0;
  align-self: "center";
}

.intentSelect,
.operationSelect {
  display: flex;
  width: 200px;
  flex-grow: 0.8fr;
}

/* Jobber Online has pretty harsh styles so we'll make lists more standard */
.contentPreview ul {
  list-style: circle;
  list-style-position: inside;
  margin-inline-start: 1em;
}

.contentPreviewText {
  padding: var(--space-base);
  border: 1px solid var(--color-border);
  background: var(--color-surface--background);
}
