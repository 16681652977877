.chatNotification {
  cursor: pointer;
}

.unread:before {
  content: "";
  display: block;
  position: absolute;
  top: var(--space-small);
  right: var(--space-small);
  width: var(--space-small);
  height: var(--space-small);
  box-sizing: content-box;
  border: var(--border-base) solid var(--color-text--reverse);
  border-radius: 50%;
  background-color: var(--color-critical);
}
