.globalBannerModal {
  --message-weight: 400;

  font-weight: var(--message-weight);
}

.bold {
  --message-weight: 800;
  transform: uppercase;
}
