.wrapper,
.wrapper > div {
  height: 100%;
}

.wrapper > div {
  display: flex;
  flex-direction: column;
}

.wrapper:not(:only-child) > div {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.metrics {
  padding: var(--space-small) 0;
}

.metric {
  padding: var(--space-small) var(--space-base);
  border-bottom: var(--border-base) solid var(--color-border);
}

.metric:last-child {
  border-bottom: none;
}

.metric h1,
.metric h2 {
  line-height: 1;
}

.metric h1 {
  margin-bottom: -2px;
}

.metric h5 {
  padding-bottom: var(--space-small);
  text-transform: uppercase;
}

.threeColumnRow {
  display: grid;
  min-height: 2.25rem;
  grid-template-columns: repeat(2, minmax(0, min-content)) 1fr;
  align-items: flex-end;
  column-gap: var(--space-small);
}

.buttonColumn {
  justify-self: flex-end;
}

.headerWithCTA {
  display: flex;
  padding: var(--space-base);
  background-color: var(--color-surface--background);
  justify-content: space-between;
}

.ctaButtonWrapper {
  flex-shrink: 0;
  margin-left: var(--space-smaller);
}
