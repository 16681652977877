.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dateWrapper {
  width: fit-content;
}

.listContainer {
  padding-left: 10px;
}

.listStyle {
  list-style: disc inside;
}
