.container {
  display: flex;
  flex-direction: column;
}

.scheduleAndCalendarContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--space-larger);
}

.recommendationsScheduleAndCalendarContainer {
  margin-bottom: var(--space-larger);
}

.scheduleContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-larger);
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 1;
  padding: 0 16px 0 16px;
}

.narrow {
  flex-basis: 33.33%;
}

.wide {
  flex-basis: 70%;
}
