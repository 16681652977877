.exportMenu button {
  min-width: 130px;
}

@media screen and (max-width: 1024px) {
  .exportMenu,
  .exportMenu > div,
  .exportMenu button {
    width: 100%;
  }
}
