.switchContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.cardOnFileSwitchTitle {
  margin-bottom: 0;
}
.contentContainer {
  margin-top: 30px;
}
.optionsContainer {
  margin-top: 10px;
}
