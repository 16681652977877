.container {
  display: flex;
  width: 100%;
  padding: var(--space-smaller) 0;
}

.cardNumberContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: 0 var(--space-small);
}

.expiryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expiryContainer.expiringSoon p {
  color: var(--color-warning--onSurface);
}

.expiryContainer.isExpired p {
  color: var(--color-critical);
}

.defaultCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardNumberInnerContainer {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: var(--space-smaller);
}

.cardNumberTitle {
  margin-right: var(--space-small);
}

.cardNumber {
  text-wrap: nowrap;
}
