.spacer {
  height: 15rem;
}

.container {
  position: absolute;
  left: 0;
  z-index: var(--elevation-menu);
}

.stickyContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: var(--space-smallest);
  background-color: var(--color-surface--background);
}

@media only screen and (min-width: 40rem) {
  .stickyContainer {
    width: calc(100% - 11.75rem);
  }
}

.summaryContainer {
  display: grid;
  padding: var(--space-large);
  border-radius: var(--space-smallest);
  background-color: var(--color-surface--background);
  gap: 0;
}

.disclosure {
  max-height: 90vh;
  overflow-y: scroll;
}

.headerContainer {
  display: block;
  width: 100%;
}

.headerTop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-smaller);
}

.collapsedBody {
  display: block;
  padding: var(--space-small) 0;
}

.footer {
  display: block;
  padding: var(--space-small) 0;
}

.tierNameContainer {
  margin-top: var(--space-large);
}

.termsOfService {
  margin-top: var(--space-small);
}

.savingsMessage {
  padding: var(--space-small);
}
