.tableContainer {
  display: flex;
  min-height: 0;
  flex-direction: column;
  flex: 1;
}

/*
 DataTable wrapper to remove left border
*/
.tableContainer > :first-child {
  display: flex;
  height: 100%;
  border-left: none;
  flex-direction: column;
  flex: 1;
}

/*
  Wrap the DataTable content to allow scrolling of the content
*/
.tableContainer > :first-child > :first-child {
  flex: 1;
}

/*
 DataTable pagination wrapper to keep it visible and allow scrolling of the content
*/
.tableContainer > :first-child > :last-child {
  flex: 0;
  min-height: auto;
}

.clientSegmentDrawer {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.drawerHeader,
.segmentsCriteriaContainer {
  display: flex;
  justify-content: space-between;
  padding: var(--space-base);
  align-items: center;
}
