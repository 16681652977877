.donut {
  --size: var(--space-large);
  --half-size: calc(var(--size) / 2);
  --stroke-width: var(--space-smaller);
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  /* CSS normally supports `pi` in calc, but our webpack blows up when we use it */
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/calc-constant */
  --pi: 3.1415926535897932;
  --circumference: calc(var(--radius) * var(--pi) * 2);
  --dash: calc((var(--progress, 0) * var(--circumference)));
  --progress-color: var(--color-green);

  width: var(--space-large);
  height: var(--space-large);
}

.donut circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  stroke-linecap: round;
  fill: transparent;
}

.track {
  stroke: var(--color-greyBlue--lighter);
}

.progress {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  stroke: var(--progress-color);
  transition: stroke-dasharray var(--timing-base) ease-in-out;
}

.quotes {
  --progress-color: var(--color-quote);
}

.jobs {
  --progress-color: var(--color-job);
}

.invoices {
  --progress-color: var(--color-invoice);
}
