.paperRip {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 3;
  width: 25%;
  height: calc(100% + 25px);
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: var(--color-surface);
}

.imageOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(77, 105, 116, 1) 0%, rgba(1, 41, 57, 1) 100%);
  mix-blend-mode: overlay;
  pointer-events: none;
  opacity: 0.9;
}

.mediaBackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 40% center;
}

.mediaBackgroundImageWithOverlay {
  filter: grayscale(100%) contrast(0.6) brightness(1.1);
}

.businessBackground {
  object-position: center top !important;
}

.themeBackgroundImage {
  top: 50%;
  width: 90%;
  height: 70%;
  max-height: 800px;
  transform: translateY(-50%);
  object-fit: cover;
  object-position: right top;
}
