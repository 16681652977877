.insightCard {
  --color-icon: var(--color-base-purple--600);
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: var(--space-base);
  padding: var(--space-base);
  border: 1px solid var(--color-base-purple--600);
  border-radius: var(--radius-base);
  overflow: hidden;
  cursor: pointer;
  transition: 500ms;
  flex-direction: column;
  justify-content: space-between;
}

.insideKPISection > .insightCard {
  height: 100%;
  max-width: 500px;
  margin: 0;
}

/* Reviews Engagement */
.insideReviewsEngagement > .insightCard {
  max-height: 50px;
  box-shadow: none;
  margin: 0;
  padding: var(--space-small);
  border: 0;
  gap: 0;
}

.insideReviewsEngagement > .insightCard > .copyContainer > h4 > span > svg {
  fill: var(--color-base-purple--600) !important;
}
/* Reviews Engagement */

:root[data-theme="dark"] .insightCard {
  --color-icon: var(--color-base-purple--400);
  border-color: var(--color-base-purple--400);
}

.insightCard > * {
  position: relative;
  z-index: var(--elevation-base);
}

/*
    This pseudo-element is used to create the gradient transition effect.
    You can't transition gradients directly, so instead, we give the
    pseudo-element a background with the gradient, and then transition the opacity.
 */
.insightCard:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--elevation-default);
  width: 100%;
  height: 100%;

  background: radial-gradient(
      80.79% 73.4% at 100% 0%,
      rgba(154, 213, 223, 0.56) 0%,
      rgba(154, 213, 223, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 59.47%,
      rgba(255, 255, 255, 0.7) 90.7%
    ),
    radial-gradient(
      22.83% 22.7% at 64.21% 60.75%,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.6) 100%
    ),
    radial-gradient(
      68.58% 34.91% at 21.59% 53.9%,
      rgba(235, 247, 249, 0.03) 0%,
      rgba(183, 225, 235, 0.3) 100%
    ),
    radial-gradient(
      68.37% 49.27% at 20.5% 66.95%,
      rgba(216, 211, 235, 0.9) 0%,
      rgba(153, 140, 204, 0.9) 100%
    );
  opacity: 0;
  transition: 500ms;
}

.insightCard:hover:after {
  opacity: 1;
}

:root[data-theme="dark"] .insightCard:after {
  background: radial-gradient(
      80.79% 73.4% at 100% 0%,
      var(--color-base-teal--700) 0%,
      rgba(12, 88, 106, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(39, 49, 53, 0) 59.47%,
      rgba(39, 49, 53, 0.7) 90.7%
    ),
    radial-gradient(
      22.83% 22.7% at 64.21% 60.75%,
      rgba(39, 49, 53, 0.7) 0%,
      rgba(39, 49, 53, 0.5) 100%
    ),
    radial-gradient(
      68.58% 34.91% at 21.59% 53.9%,
      rgba(14, 97, 113, 0.03) 0%,
      rgba(14, 97, 113, 0.37) 100%
    ),
    radial-gradient(
      68.37% 49.27% at 20.5% 66.95%,
      var(--color-base-purple--900) 0%,
      var(--color-base-purple--700) 100%
    );
}

.insideKPISection {
  height: 100%;
}

#insightCardLabel {
  display: flex;
  align-items: center;
  gap: var(--space-smaller);
  margin-top: var(--space-smaller);
}

#insightCardLabel > p {
  color: var(--color-base-purple--600);
}

/* Reviews Engagement */
.insideReviewsEngagement #insightCardLabel > p {
  display: none;
  height: 0;
}
/* Reviews Engagement */

:root[data-theme="dark"] #insightCardLabel > p {
  color: var(--color-base-purple--400);
}

#insightCardLabel svg {
  /*
  Icon has an inline `vertical-align`,
  so this !important is necessary to
  override it.
  */
  vertical-align: sub !important;
}

.copyContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-smaller);
}

/* Reviews Engagement */
.sparklesIcon {
  display: none;
}

.insideReviewsEngagement .copyContainer {
  gap: 0;
  grid-gap: 0;
}

.insideReviewsEngagement > .insightCard > .copyContainer {
  align-items: center;
}

.insideReviewsEngagement > .insightCard > .copyContainer > h4 {
  color: var(--color-base-purple--600);
  font-size: var(--typography--fontSize-base);
}

.insideReviewsEngagement .copyContainer .sparklesIcon {
  display: inline;
  margin-right: var(--space-smaller);
}
/* Reviews Engagement */

.copyContainer button {
  display: inline;
  color: var(--color-heading);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.valueHighlight {
  white-space: nowrap;
}

.valueHighlight .greenArrow {
  color: var(--color-success);
}

.valueHighlight .redArrow {
  color: var(--color-critical);
}
