.container {
  display: flex;
  flex: 1 1 100%;
}

.label {
  order: 1;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  padding-right: var(--space-base);
}

:global(.client-property-custom-fields) .label {
  flex: 0 0 calc(100% / 3);
  max-width: 400px;
}

.label.inAppGroup {
  flex: 0 0
    calc(
      ((100% + (2 * var(--space-base))) * 0.4166667) - var(--space-base) - 1px
    );
}

:global(.client-property-custom-fields) .label.inAppGroup {
  flex: 0 0 calc(((100% + (2 * var(--space-base))) / 3) - var(--space-base));
  max-width: calc(400px - var(--space-base));
}

.label > p {
  color: var(--color-heading);
}

.labelEdit {
  align-self: center;
}

.value {
  width: 100%;
  padding-left: var(--space-base);
  word-break: break-word;
  order: 2;
  flex: 1 1 0%;
  align-self: flex-start;
  overflow-wrap: break-word;
}

:global(.client-property-custom-fields) .value {
  padding-left: 0;
}

.value > p {
  color: var(--color-text);
}

.valueEdit {
  display: flex;
}

.unit {
  padding-left: var(--space-smaller);
  align-self: center;
  white-space: nowrap;
}

.areaSymbol {
  padding: 0 var(--space-smaller);
  align-self: center;
}

.linkLabel {
  align-self: flex-start;
}

.linkValue {
  display: block;
}

.linkName {
  padding-bottom: var(--space-small);
}

@media only screen and (max-width: 1024px), print {
  .container {
    flex-direction: column;
  }

  .value {
    flex: 100%;
    padding-left: 0;
  }

  .label {
    flex: 100%;
    max-width: 100%;
  }

  .labelEdit {
    align-self: flex-start;
  }
}
