.header {
  width: 9rem;
  height: 3.5rem;
  padding: var(--space-base);
  border-bottom: 1px solid var(--color-border);
  font-weight: 700;
  text-align: left;
}

.headerCheckbox {
  width: var(--space-base);
  height: 3.5rem;
  padding: 0 var(--space-base);
  border-bottom: 1px solid var(--color-border);
  text-align: left;
}

.headerRow {
  border-bottom: 1px solid var(--color-border);
}

.cell {
  width: 9rem;
  height: 3.5rem;
  padding: var(--space-base);
  border-bottom: 1px solid var(--color-border);
}
