.wrapper {
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
}

.content {
  display: block;
  max-width: 500px;
  margin: auto;
  padding: var(--space-base);
}

.content h2 {
  margin-bottom: var(--space-base);
}

.content button {
  margin-top: var(--space-base);
}

.notice {
  margin-top: var(--space-smallest);
  color: var(--color-text--secondary);
  font-size: var(--typography--fontSize-small);
}
