.summary {
  padding: var(--space-smaller) 0 var(--space-smallest) 0;
  border: none;
  list-style: none;
  cursor: pointer;
  outline-color: none;
  transition: background var(--timing-quick), box-shadow var(--timing-base);
}

.summary::-webkit-details-marker {
  display: none;
}

.summary * {
  pointer-events: none;
}

.summaryContainer {
  display: flex;
}

.summaryWrap {
  align-items: center;
  display: flex;
  width: 100%;
  margin-right: var(--space-smaller);
}

.summaryWrap h4 {
  flex: 1 0 0;
}

.content {
  display: block;
  padding: var(--space-small) 0;
}

.details[open] > .content {
  -webkit-animation: openAnimation var(--timing-base);
  animation: openAnimation var(--timing-base);
}

@keyframes openAnimation {
  0% {
    margin-top: calc(var(--space-small) * -1);
    opacity: 0;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}
