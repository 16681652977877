.stepContainer {
  display: flex;
  flex-direction: column;
  padding: var(--space-large);
  gap: var(--space-large);
  align-items: center;
}

@media (--large-screens-and-up) {
  .stepContainer {
    flex-direction: row;
  }
}

.stepContainer h1 {
  margin-bottom: 0;
  @media (--small-screens-and-below) {
    line-height: var(--space-large);
  }
}

.stepContainer p {
  margin: var(--space-base) 0;
  font-size: var(--typography--fontSize-large);
}

.subtitle p {
  margin-top: var(--space-small);
  color: var(--color-text--secondary);
}

.row {
  display: flex;
  flex-direction: row;
  gap: var(--space-small);
}

.imgContainer {
  aspect-ratio: 1/1;

  img {
    width: 285px;
    height: 285px;
  }
}

.column {
  max-width: 30rem;
  margin: auto 0;
}

.subtext p {
  color: var(--color-text--secondary);
  font-size: var(--typography--fontSize-small);
}
