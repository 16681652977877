.jobCostToggleButton {
  display: flex;
  width: 100%;
  padding: var(--space-base);
  border-top-right-radius: var(--radius-base);
  border-top-left-radius: var(--radius-base);
  background-color: var(--color-surface--background);
  justify-content: flex-start;
}

.jobCostHeader {
  display: flex;
  flex: 9;
  padding: 0 var(--space-base) var(--space-larger) var(--space-larger);
  background-color: var(--color-surface--background);
}

.blurredText {
  user-select: none;
  filter: blur(5px);
}

.profitMarginContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.profitMarginText {
  margin: 0;
}

.profitAndGraph {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-surface--background);
}

.netLossAmount {
  color: var(--color-critical--onSurface);
}

/* DO NOT COPY THIS! This is a custom breakpoint for Profit Bar only */
@media screen and (max-width: 1180px) {
  .jobCostHeader,
  .jobHeaderContainer {
    flex-direction: column;
  }

  .jobCostHeader {
    padding: 0 var(--space-large) var(--space-base);
  }

  .profitMarginContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: var(--space-base);
    align-items: center;
  }

  .profitMarginContainer > p {
    margin: 0;
    font-family: var(--typography--fontFamily-normal);
    font-size: var(--typography--fontSize-base);
    font-weight: 600;
  }

  .profitMarginText {
    margin: 0;
    font-size: var(--typography--fontSize-large);
  }
}
