.featureListWrapper {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 3;
  max-width: 450px;
  margin: 0 auto;
  padding-left: 40px; /* half the rip width to visually center it */
  transform: translateY(-50%);
}

.featureList {
  margin-top: 40px;
}

.item {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.checkmarkWrapper {
  padding-right: 10px;
}

.itemHeaderSpacer {
  padding-top: 5px;
}

.itemSubtext {
  height: 36px;
  color: var(--color-white);
}
