.slideContainer {
  width: 100%;
  height: 100%;
}

.slide {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 500ms;
}

.present {
  height: auto;
  opacity: 1;
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
}

.image {
  height: 12rem;
}

.dots {
  display: flex;
  justify-content: center;
}

.dots div {
  width: 0.5rem;
  height: 0.5rem;
  margin: 0.15rem;
  border-radius: 50%;
}

.greyDot {
  background-color: rgb(225, 225, 225);
}

.greenDot {
  background-color: rgb(125, 176, 14);
}

.buttons {
  display: flex;
  margin-top: 2.75rem;
  margin-bottom: 0.5rem;
}

.singleButton {
  justify-content: right;
}

.twoButtons {
  justify-content: space-between;
}

@media screen and (min-width: 56rem) {
  .image {
    height: 19.5rem;
  }
}
