.drawerHeaderNoLeftPad {
  display: flex;
  justify-content: space-between;
  padding: var(--space-base) var(--space-base) var(--space-base) 0px;
  align-items: center;
}

.clientSegmentCriteriaContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
}
