.breadcrumbContainer {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: var(--space-small);
  border-bottom: 1px solid var(--color-border);
  align-items: center;
  justify-content: center;
  row-gap: var(--space-small);
  flex: 0;
  background-color: var(--color-surface);
}

.navigationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.buttonPlaceholder {
  position: absolute;
  right: var(--space-base);
}

.breadcrumbMobileContainer {
  flex: 0;
  width: 100%;
  flex-direction: column;
  padding-top: var(--space-small);
}

.parentItem {
  display: flex;
  flex-direction: row;
  padding-top: var(--space-smaller);
  padding-bottom: var(--space-smaller);
}

.breadCrumb {
  display: flex;
  padding-left: var(--space-base);
  padding-right: var(--space-base);
}

.inactiveBreadCrumb {
  color: var(--color-text--secondary);
}

.headingBox {
  flex-direction: row;
  padding-right: var(--space-base);
}

.mobileContentButton {
  align-self: center;
}

.mobileContent {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.mobileContentText {
  flex: 1;
}

.backButton {
  padding-right: var(--space-base);
}
