.buttonContainer {
  display: flex;
  justify-content: flex-start;
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
}

.inputButton {
  display: none;
}
