.featureCardContainer {
  width: 19rem;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  padding-top: var(--space-large);
  border-radius: var(--radius-base);
}

.featureCardCopy {
  min-height: 134px;
  padding: 0 var(--space-large) var(--space-larger) var(--space-large);
}

.featureCardHeading {
  padding-bottom: var(--space-small);
}

.featureImage {
  padding-left: var(--space-base);
}
