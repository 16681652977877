.infoRow {
  display: flex;
  flex-direction: row;
  gap: var(--space-small);
  align-items: center;
}

.cardPaymentsTitleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cardBrandRow {
  display: flex;
  flex-direction: row;
  gap: var(--space-smaller);
  align-items: center;
}
