.jobCostDiscoveryDemo {
  display: flex;
  justify-content: center;
}

.jobCostDiscoveryDemoTitle > h5 {
  color: var(--color-text--secondary);
}

.jobCostDiscoveryDemoBlur {
  filter: blur(5px);
}

.jobCostDiscoveryDemoContent {
  display: flex;
  justify-content: space-between;
}

.jobCostDiscoveryDemoContentLeft {
  display: none;
}

.jobCostDiscoveryDemoContentRight {
  display: flex;
  width: 100%;
  padding-top: var(--space-small);
  flex-direction: column;
}

.jobCostDiscoveryDemoContentMobile {
  display: flex;
  justify-content: space-between;
}

.jobCostDiscoveryDemoContentMobileSpacer {
  padding-bottom: var(--space-base);
}

.jobCostDiscoveryDemoContentLast {
  display: flex;
  margin-top: var(--space-base);
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  border-top: 4px solid var(--color-border);
  justify-content: space-between;
}

@media (min-width: 50rem) {
  .jobCostDiscoveryDemo {
    display: flex;
    padding: var(--space-base) 0px;
  }

  .jobCostDiscoveryDemoContent {
    display: block;
  }

  .jobCostDiscoveryDemoContentMobile {
    display: none;
  }

  .jobCostDiscoveryDemoContentMobileSpacer {
    padding: unset;
  }

  .jobCostDiscoveryDemoContentLeft {
    display: block;
    flex-grow: 4;
  }

  .jobCostDiscoveryDemoContentRight {
    display: flex;
    width: auto;
    padding: unset;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 4;
  }

  .jobCostDiscoveryDemoContentLast {
    display: block;
    margin: unset;
    padding: unset;
    border-top: none;
  }
}
