.pageWrapper {
  width: 100%;
  height: 100%;
}

.linkContainer {
  padding-left: var(--space-large);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.disputeDetailsContainer {
  max-width: 420px;
  min-width: 380px;
}

.respondToDisputeContainer {
  width: 100%;
  min-width: 380px;
  padding-right: 30px;
  word-break: break-word;
  word-wrap: break-word;
}

@media only screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .respondToDisputeContainer {
    max-width: 380px;
    padding-right: 0;
  }

  .disputeDetailsContainer {
    max-width: 380px;
    margin-top: 30px;
  }
}
