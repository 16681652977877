.ratingChip {
  display: flex;
  padding: var(--space-smallest) var(--space-small);
  gap: var(--space-smallest);
  border-radius: 23px;
  flex-direction: row;
}
.ratingChip p {
  margin-top: 0 !important;
  font-weight: bold;
}
.negative {
  background-color: var(--color-critical--surface);
}
.negative p {
  color: var(--color-critical--onSurface);
}
.positive {
  color: var(--color-text);
  background-color: var(--color-surface--background);
}
