.quoteWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
  width: 80%;
  max-width: 650px;
  padding: var(--space-largest);
  padding-right: 0;
}

.quote {
  display: flex;
  position: relative;
  padding: var(--space-large);
  padding-left: 0;
  padding-right: var(--space-largest);
  align-items: flex-start;
}

.backgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.quotationWrapper {
  flex: 1;
  display: flex;
  align-items: baseline;
  transform: translateY(100%);
}

.quotationWrapper span {
  position: relative;
  color: var(--color-brand--highlight);
  font-size: 150px;
  line-height: 0;
  transform: translateX(-50%);
}

.quote h2 {
  color: var(--color-white);
  font-size: 24px;
  line-height: 133%;
}

.details {
  display: flex;
  margin-top: var(--space-extravagant);
  padding-right: var(--space-largest);
  text-align: right;
  flex-direction: column;
}

.details h3 {
  color: var(--color-white);
}

.detailsRow {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--space-small);
}

.detailsRow * {
  margin-bottom: 0;
  color: var(--color-white);
}
