.container {
  position: relative;
  width: 100%;
}

.inputTextContainer input {
  padding-left: calc(var(--space-larger) + var(--space-smaller));
  padding-right: var(--space-larger);
}

.inputTextContainer label {
  padding-left: calc(var(--space-larger) + var(--space-smaller));
  padding-right: var(--space-larger);
}

.searchIconContainer {
  position: absolute;
  top: 50%;
  left: var(--space-small);

  /* Form field label is 1 and hides this icon if we don't increase the elevation */
  z-index: 2;
  pointer-events: none;
  transform: translateY(-50%);
}

.actionIconContainer {
  position: absolute;
  top: 50%;
  right: var(--space-small);

  /* Form field label is 1 and hides this icon if we don't increase the elevation */
  z-index: 2;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transform: translateY(-50%);
  appearance: none;
}
