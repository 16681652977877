.toLabelContainer {
  /* Left padding to the wall will be space-base from the above container */
  padding-right: var(--space-base);
}

.recipientChipContainer {
  display: flex;
  min-height: 81px;
  padding: var(--space-base);
  border-bottom: var(--border-base) solid var(--color-border);
  align-items: center;
}
