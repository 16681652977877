.stepContainer {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  max-width: 842px;
  min-width: 80%;
  margin: 0 auto;
}

.spinner {
  padding-bottom: var(--space-larger);
}
