.containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
}

.button {
  width: 100%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 40rem) {
  .button,
  .left {
    width: initial;
    margin-bottom: 0;
  }

  .containerHeader {
    flex-direction: row;
    margin-bottom: 1rem;
  }
}
