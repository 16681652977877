.setupWizardContainer {
  display: flex;
  position: relative;
  width: calc(100vw - (100vw - 100%));
  min-height: 100dvh;
  overflow: hidden;
  background-color: var(--color-surface);
  flex-direction: column;
}

.setupWizardContainer .static {
  padding: var(--space-large);
}

.setupWizardControls {
  display: flex;
  align-items: center;
  height: 48px;
}

.logo {
  max-width: 145px;
  margin-bottom: var(--space-larger);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .setupWizardContainer .static {
    padding: var(--space-extravagant);
    padding-bottom: var(--space-large);
  }
}

@media screen and (min-width: 1024px) {
  .setupWizardContainer {
    padding-top: 10vh;
  }

  .setupWizardContainer .static {
    max-width: 50%;
    margin-left: calc((50vw - 450px) / 2);
    padding: var(--space-large) 0;
  }

  .setupWizardControls {
    max-width: 450px;
  }
}

.largeBlueprintImage {
  width: 300px;
  height: auto;
  margin-top: 13%;
  margin-bottom: 10px;
}
