.list {
  margin: 0;
  padding-bottom: var(--space-base);
  list-style: none;
}

.listItem {
  flex: 1 1 auto;
  min-width: 0;
  align-self: center;
  color: var(--color-heading);
}

.active {
  background-color: var(--color-surface--active);
}
