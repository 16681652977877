
.achUsageSection{
  margin-bottom: var(--space-base);
}

.progressBarRow {
  display: flex;
  flex-direction: column;
  padding: var(--space-base) 0;
}

.progressBarRow>div:first-child {
  margin-bottom: var(--space-base);
}

/* Extra large and up?? */
@media (--extra-large-screens-and-up){
  .progressBarRow {
    flex-direction: row;
  }
}
