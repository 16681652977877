.container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: flex-start;
}

.container > input {
    position: absolute;
    opacity: 0;
}

.loadingContent {
    width: 100%;
    height: 100%;
    padding: var(--space-base);
    border: var(--border-base) solid var(--color-border);
    border-radius: var(--radius-base);
    text-align: left;
    background: var(--color-surface);
}

.segmentContainer {
    display: flex;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-base);
    border: var(--border-base) solid var(--color-border);
    border-radius: var(--radius-base);
    text-align: left;
    background: var(--color-surface);
    justify-content: space-between;
    flex-direction: row;
    column-gap: var(--space-base);
    row-gap: var(--space-small);
}

.container input:checked ~ .segmentContainer {
    border: var(--border-thick) solid var(--color-interactive);
    border-radius: var(--radius-base);
    background: var(--color-surface);
}

.container input:focus-visible ~ .segmentContainer {
    box-shadow: var(--shadow-focus);
    border: var(--border-thick) solid var(--color-interactive);
    border-radius: var(--radius-base);
    background: var(--color-surface);
}

.container:hover input:not(:disabled):not(:checked) ~ .segmentContainer {
    border: var(--border-base) solid var(--color-border);
    border-radius: var(--radius-base);
    background: var(--color-surface--hover);
    cursor: pointer;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: var(--space-base);
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    row-gap: var(--space-small);
}

.innerContentContainer {
    width: 100%;
}

.iconAndStatusContainer {
    padding-bottom: var(--space-small);
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-small);
}

@media only screen and (--medium-screens-and-up) {
    .childContainer {
        width: 50%;
    }
}
