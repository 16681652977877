.cardsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  gap: var(--space-base);
  overflow-x: auto;
}

.card {
  flex: 1;
  min-width: 200px;
}
