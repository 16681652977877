.imageClass {
  width: 100%;
  margin-left: calc(var(--space-minuscule) * -1);
  margin-right: calc(var(--space-minuscule) * -1);
}

.imageWrapper {
  position: relative;
  margin: -1px;
  color: var(--color-text--reverse);
  text-align: center;
}

.buttonWrapper {
  margin-bottom: 0.5rem !important;
}

.buttonWrapper > button {
  min-width: 10rem;
}
