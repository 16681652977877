.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
}

.templatePageContent {
  flex: 1;
}

.templateCardsWrapper {
  padding: var(--space-large);
}

@media (--large-screens-and-up) {
  .templateCardsWrapper {
    padding: var(--space-large) 0;
  }
}

.templatePageSubtitle {
  padding-bottom: var(--space-base);
}

.bottomPadding {
  padding-bottom: var(--space-small);
}
