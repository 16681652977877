.itemContainer {
  display: block;

  &:hover,
  &:focus {
    & p {
      color: var(--color-interactive--hover) !important;
    }
  }
}
