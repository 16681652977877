.shareLinkFooter {
  position: sticky;
  bottom: 0;
  margin: 0 calc(-1 * var(--space-base)) calc(-1 * var(--space-larger));
  padding-top: var(--space-smaller);
  overflow: hidden;
}

.shareLinkContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--space-smaller);
  box-shadow: var(--shadow-high);
  padding: var(--space-base) calc(var(--space-large) + var(--space-base));
  background-color: var(--color-surface--background);

  @media (--large-screens-and-up) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

.shareLinkHeader {
  margin-bottom: var(--space-base);
}

.shareToolbar {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  gap: var(--space-small);

  @media (--large-screens-and-up) {
    flex-direction: row-reverse;
  }
}

.shareToolbar > *:nth-child(2) {
  @media (--large-screens-and-up) {
    margin-right: var(--space-large);
  }
}

.shareHelp {
  display: flex;
  width: 100%;

  @media (--large-screens-and-up) {
    justify-content: flex-end;
  }
}

.shareError {
  width: 100%;
  margin-bottom: var(--space-small);
}
