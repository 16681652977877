.errorContainer {
  display: flex;
  align-items: center;
  margin: var(--space-small) 0;
}

.errorContainer > svg {
  flex-shrink: 0;
  margin-right: var(--space-base);
}
