.addProductOrServicePrompt {
  display: flex;
  position: absolute;
  bottom: calc(100% + var(--space-smaller));
  z-index: 10;
  width: 100%;
  box-shadow: var(--shadow-base);
  margin-bottom: var(--space-smallest);
  padding: var(--space-small);
  border: var(--border-base) solid var(--color-border);
  background-color: var(--color-surface--hover);
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.hidden {
  visibility: hidden;
}
