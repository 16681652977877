.addToCart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--space-base);
}

@media(max-width: 768px) {
    .addToCart {
        flex-direction: column;
    }
}
