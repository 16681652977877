.listItem {
  display: flex;
  padding-bottom: var(--space-smaller);
  list-style-type: none;
  gap: var(--space-base);
}

.listItem:not(:last-child) {
  padding-bottom: var(--space-smaller);
}

.iconItem {
  display: flex;
  align-items: center;
}
