.downgradeDisclaimerCard {
  margin: 0 0 var(--space-base) 0;
  padding: var(--space-base);
  border-radius: var(--radius-base);
  background-color: var(--color-surface--background--subtle);
}

.downgradeDisclaimerCard .disclaimerList {
  margin-left: var(--space-large);
  list-style: disc;
}

.downgradeDisclaimerCard Button {
  margin-bottom: var(--space-smaller);
  padding-bottom: 0 !important;
  padding-left: 0;
  background-color: var(--color-surface--background--subtle) !important;
}

.downgradeDisclaimerCard span{
  padding-bottom: var(--space-smallest);
  color: var(--color-interactive);
  font-weight: 400;
}

.downgradeDisclaimerCard h4 {
  display: flex;
  align-items: center;
}

.downgradeDisclaimerCard h4 svg {
  padding-right: var(--space-small);
}
