:global([data-react-class="campaigns/index.CampaignsRoute"]),
.container > [data-testid="atlantis-form"] {
  display: contents;
}

:global([data-controller="campaigns"]) :global(.with-react-page) {
  /**
  Set position absolute to prevent the flex container from growing to allow for scrolling on the content and preview panels
  */
  position: absolute;
  top: 0;
  bottom: 0;
}

.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
}
