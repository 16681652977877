.messageBubble {
  max-width: 75%;
  padding: var(--space-small) var(--space-base);
  border-radius: var(--space-smaller);
  color: var(--color-heading);
  font-family: var(--typography--fontFamily-normal);
  font-size: var(--space-base);
  line-height: var(--space-large);
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.outbound {
  background-color: var(--color-success--surface);
}

.inbound {
  background-color: var(--color-inactive--surface);
}

.assistant {
  background-color: var(--color-informative--surface);
}

.marginBase {
  margin-bottom: var(--space-base);
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.errorState {
  align-self: flex-end;
  background-color: var(--color-critical--surface);
}

.fileParent {
  margin-top: var(--space-base);
}

.fileParent:last-child {
  margin-bottom: var(--space-small);
}

.fileParent:hover {
  cursor: pointer;
}

div.fileParent:focus,
div.fileParent:focus-visible {
  outline: none;
}

div.fileParent:active:hover {
  box-shadow: var(--shadow-focus);
}

/*
  Highlight around the surrounding div
*/
.fileParent:hover>div,
.fileParent:focus>div,
.fileParent:focus-visible>div,
/*
  additional highlight around the image, not the text
*/
.fileParent:hover>div>div:first-child,
.fileParent:focus>div>div:first-child,
.fileParent:focus-visible>div>div:first-child {
  box-sizing: border-box;
  border: var(--border-base) solid var(--color-focus);
  border-radius: var(--border-thick);
  background-color: var(--color-surface--hover);
}
