/* first set base styles */
.recurringTooltipTable {
  width: 100%;
  border-collapse: collapse;
  font-family: var(--typography--fontFamily-normal);
  font-size: var(--typography--fontSize-small);
  text-align: left;
  vertical-align: middle;
}

.recurringTooltipTable th {
  font-weight: 700;
}

.recurringTooltipTable td {
  font-weight: 200;
}

/* hide dialog button */
.popoverWrapper button {
  display: none;
}

/* set default spacing between rows */
.recurringTooltipTable tr th,
.recurringTooltipTable tr td {
  padding: 0;
}

/* second column aligns right */
.recurringTooltipTable th:last-child,
.recurringTooltipTable td:last-child {
  text-align: right;
}

/* add some min space between columns */
.recurringTooltipTable tr th:first-child,
.recurringTooltipTable tr td:first-child {
  padding-bottom: var(--space-smaller);
  padding-right: var(--space-base);
}

.recurringTooltipTable tfoot tr {
  border-top: 1px solid var(--color-border);
}

/* no spacing under the final row of the table */
.recurringTooltipTable :last-child tr th,
.recurringTooltipTable :last-child tr td {
  padding-bottom: 0;
}

/* larger space below last body row and the final row */
.recurringTooltipTable tbody tr:last-child td {
  padding-bottom: var(--space-small);
}

/* smaller space between the final row and the border */
.recurringTooltipTable tfoot tr:first-child th {
  padding-top: var(--space-smaller);
}

/* pseudo classes with no content don't show */
.recurringTooltipTable tfoot tr th:first-child::before {
  content: unset;
}

.recurringTooltipTable tr.revenue th:first-child::before {
  background: var(--color-green);
}

.recurringTooltipTable tr.cost th:first-child::before {
  background: var(--color-grey--light);
}
