.lineItemsBulkEditWrapper {
  display: flex;
  flex-direction: column;
}

.lineItemsInputContainer {
  border-top: var(--border-base) solid var(--color-border);
}

.lineItemsInputContainer:first-child {
  border: 0;
}

.lineItemsBulkEditEmpty {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-base);
}
