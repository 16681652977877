.carouselContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  touch-action: pan-y;
}

.carouselWrapper {
  display: flex;
  transition: transform 0.3s;
}

.carouselSlide {
  flex: 0 0 100%;
  width: 100%;
  padding: var(--space-small);
}

.hideCarouselSlideWidth {
  width: 0;
}

.carouselIndicators {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
}

.carouselIndicator {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border: none;
  border-radius: 50%;
  outline: none;
  background: var(--color-grey--lighter);
  cursor: pointer;
}

.carouselIndicatorPadding {
  padding-bottom: var(--space-base);
}

.carouselIndicator.active {
  background: var(--color-blue--dark);
}

.carouselArrowPrev,
.carouselArrowNext {
  z-index: 1;
  border: none;
  cursor: pointer;
  appearance: none;
}

.carouselArrowPrev {
  margin-right: var(--space-small);
}

.carouselArrowNext {
  margin-left: var(--space-small);
}

.carouselArrowPrev:hover,
.carouselArrowNext:hover {
  opacity: 0.5;
  transition: opacity 0.3s;
}
