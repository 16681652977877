.secondaryText {
  margin-top: var(--space-small);
}

.partialPaymentsTitle {
  margin-top: var(--space-large);
}

.partialPaymentToggle {
  margin-top: var(--space-base);
}

.underlineText {
  text-decoration: underline;
}
