.handheldHeader {
  display: flex;
}

.title {
  display: flex;
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  padding-left: var(--space-base);
  padding-right: 0.625rem;
  flex-direction: column;
  flex: 1 1;
  background-color: var(--color-surface);
}

.list {
  background-color: var(--color-surface);
}
