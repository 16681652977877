.shadow {
  position: absolute;
  right: -1rem;
  z-index: 4;
  width: 4rem;
  height: 100%;
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

@media screen and (min-width: 64rem) {
  .bottomPadding {
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 40rem) {
  .scrollContainer {
    background-color: rgb(244, 244, 244);
  }
}

.scrollContainer.greyBackground {
  background-color: rgb(244, 244, 244) !important;
}

.scrollContainer > div:last-child:not(:nth-child(2)):not(:nth-child(1)) {
  padding-right: 3rem !important;
}

.carouselEmptyState {
  display: flex;
  height: 9rem;
  margin: auto;
  align-items: center;
  justify-content: center;
}
