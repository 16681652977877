.securityAssuranceContainer {
  padding-top: var(--space-base);
}

.securityAssuranceTexts {
  padding-left: var(--space-base);
  padding-right: var(--space-base);
}

.noSideMargin {
  padding-left: 0;
  padding-right: 0;
}
