.exampleTextInput {
  width: 85px;
}

.exampleNumberInput {
  width: 40px;
}

.areaInputGroup {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}

.areaInputWrapper > div {
  gap: var(--space-small);
}

.block {
  display: inline-block;
}

.dropdownOptionContainer {
  display: flex;
  position: relative;
  gap: var(--space-small);
}

.dropdownNumber {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownOption {
  flex-grow: 1;
}
