.submittedEvidenceContainer {
  padding-top: var(--space-large);
  white-space: pre-line;
}

.submittedEvidenceSection {
  padding-bottom: var(--space-base);
  border-bottom: solid thin var(--color-border);
}

.supportingEvidenceSection {
  border-bottom: solid thin var(--color-border);
}

.serviceDate {
  padding-bottom: var(--space-base);
  font-weight: bold;
}

.supportingEvidenceFileLinkContainer {
  padding-bottom: var(--space-base);
}

.supportingEvidenceFileLink {
  color: var(--color-interactive);
}

.supportingEvidenceFileLink:hover {
  color: var(--color-interactive--hover);
  cursor: pointer;
}

.supportingEvidenceFileLink:focus {
  display: inline-flex;
  box-shadow: var(--shadow-focus);
  border-color: var(--color-focus);
  outline: none;
  color: var(--color-interactive--hover);
  background-color: var(--color-surface--hover);
}

.supportingEvidenceFileLink:active {
  box-shadow: none;
  border: none;
  outline: none;
  color: var(--color-interactive--hover);
  background-color: var(--color-surface--hover);
}
