.datePickerContainer {
  position: relative;
}

.dateSelectorButton {
  margin: 0;
}

.dateSelectorCardContainer {
  position: absolute;
  top: calc(100% + var(--space-small));
  z-index: 10;
}

.dateSelectorCardBody {
  display: flex;
  width: 370px;
  box-shadow: var(--shadow-base);
  padding: var(--space-base);
  border-radius: var(--radius-base);
  row-gap: var(--space-base);
  flex-direction: column;
}

.dateSelectorDrawer {
  display: flex;
  width: 100%;
  padding: var(--space-base);
  flex-direction: column;
  row-gap: var(--space-base);
}

.dateSelectorDrawer h6 {
  color: var(--color-text--secondary);
}

@media only screen and (width < 640px) {
  .applyButton {
    position: fixed;
    right: var(--space-base);
    bottom: var(--space-base);
    margin: 0;
    padding: 0;
    padding-top: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .datePickerContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;
  }

  .mobileDateFilterDrawerWrapper button {
    width: auto !important;
  }

  .dateSelectorButton > button {
    width: 100%;
  }

  .applyButton {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
