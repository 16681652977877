.dashboardContent {
  height: 100%;
}

.cardContent > * {
  height: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.content {
  flex: 1 1 auto;
  align-self: center;
  padding-right: var(--space-base);
}

.centerItems {
  display: flex;
  align-items: center;
}

.action {
  flex: 0 0 auto;
  align-self: center;
  position: relative;
  margin: var(--space-base) 0;
}

.headerText {
  font-weight: 700;
  text-transform: uppercase;
}

.disputeAmountIndicatorRed {
  border-radius: var(--radius-base);
  color: var(--color-base-white);
  background-color: var(--color-destructive);
}

.disputeAmountIndicatorNeutral {
  border-radius: var(--radius-base);
  color: var(--color-heading);
  background-color: var(--color-disabled--secondary);
}

.disputeIndicatorNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 40px;
  margin-right: 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
