.paymentDetailsHeaderContainer {
  display: flex;
  align-items: center;
  padding: var(--space-base);
  justify-content: space-between;
}

.paymentPreviewHeaderContainer {
  display: flex;
  align-items: flex-start;
  padding-top: var(--space-base);
  justify-content: space-between;
}

.paymentDetailsErrorLabel {
  margin-right: var(--space-small);
}

.paymentDetailsErrorLabelAndEditButton {
  display: flex;
  align-items: right;
  justify-content: space-between;
}
