.insight {
  display: grid;
  width: 100%;
  margin-bottom: var(--space-base);
  grid-template-rows: auto;
  row-gap: var(--space-base);
}

.container {
  display: grid;
  align-content: center;
  justify-content: flex-end;
}

.divider {
  margin: 0 calc(-1 * var(--space-base));
}
