.firstHeader {
  margin-top: var(--space-small);
}

.outerButtonContainer {
  display: flex;
  justify-content: space-between;
  margin: var(--space-base);
  padding: 0 calc(var(--space-base) * 0.5);
}

.rightButtonContainer {
  display: flex;
}

.rightButtonContainerChild {
  margin-right: var(--space-small);
}

.bulletPoints > * {
  padding: 0 calc(var(--space-base) * 0.5);
}

.bulletPoints > li + li {
  margin-top: var(--space-small);
}
