.reviewWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
  width: 80%;
  max-width: 512px;
  padding: var(--space-largest);
  padding-right: 0;
}

.maskingTapeWrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: 200px;
  padding: 0 var(--space-largest);
}

.backgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.beforeImg {
  position: absolute;
  top: 0;
  left: 0;
  max-width: fit-content;
  transform: translate3d(-45%, -50%, 0);
}

.authorInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--space-base) var(--space-largest);
  gap: var(--space-small);
  text-align: right;
}
