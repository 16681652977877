.list {
  height: 100%;
  margin: var(--space-base) 0 0;
  padding: 0;
}

.list > li {
  list-style-type: none;
}

.list > li + li {
  margin-top: var(--space-base);
  margin-bottom: var(--space-base);
}

.list > li:last-child {
  min-height: 100%;
  margin-bottom: 0;
}
