.inputGroupWithValidationsFix {
  flex: 1 1 100%;
  align-self: flex-start;
}

.removeTopBorder:first-child {
  margin-top: -1px;
}
.secondColumn:first-child {
  margin-top: -1px;

  @media (--medium-screens-and-up) {
    margin-left: -1px;
  }
}
