.expectedText {
  color: var(--color-text--secondary);
}

.buttonContainer button span{
  font-size: 12px;
}

.onItsWayContainer {
  margin-bottom: 0 !important;
}
.onItsWayContainer > div {
  margin-bottom: var(--space-small);
}

.nextPayoutContainer {
  margin-bottom: 0 !important;
}
.nextPayoutContainer > div {
  margin-bottom: var(--space-large);
}
