.baseContainer {
  margin: var(--space-base) 0;
  padding: var(--space-large);
  border: var(--border-base) solid var(--color-inactive--surface);
  border-radius: 8px;
  background-color: var(--color-surface--background);
  transition: all var(--timing-base);
}

.baseContainer.focus {
  box-shadow: var(--shadow-focus);
  outline: none;
}

.completedContainer {
  border: var(--border-base) solid var(--color-success);
  background-color: var(--color-success--surface);
}

.clickableContainer {
  cursor: pointer;
}

.clickableContainer:hover {
  background-color: var(--color-surface--background--hover);
}

.clickableContainer.completedContainer:hover {
  background-color: var(--color-surface--hover);
}

.headerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.expandedContentWrapper {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition: all var(--timing-base);
}

.expandedContentWrapper.expanded {
  visibility: visible;
  margin-top: var(--space-base);
  opacity: 1;
  grid-template-rows: 1fr;
}

.expandedContent {
  margin: calc(-1 * var(--space-smaller));
  padding: var(--space-smaller);
  overflow: hidden;
  grid-row: 1 / span 2;
}

.linkStyles {
  margin-left: var(--space-base);
  font-weight: 800;
}

.actionsContainer {
  margin-top: var(--space-large) !important;
}
