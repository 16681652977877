.pageContainer {
  display: flex;
  padding-top: 1rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sectionContainer {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  align-items: center;
  flex-direction: column;
}

.pageContainer > div > div > div:nth-child(1) > div > div {
  width: 100%;
  text-align: center;
}

.pageContainer > div > div > div:nth-child(1) > p {
  width: 100%;
  text-align: center;
}

@media (--small-screens-and-below) {
  .sectionContainer {
    margin-top: var(--space-largest);
  }

  .sectionContainer > button {
    width: 100%;
  }
}
