.otherFeatureSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--space-base);
  padding-bottom: var(--space-base);
  overflow-x: hidden;
  text-align: left;
}

.featureCards {
  display: flex;
  position: relative;
  width: 100%;
  margin: auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.featureCard {
  display: none;
  max-width: 22rem;
  min-width: 18rem;
  margin: 0.5rem;
}

.slide {
  display: block;
  -webkit-animation: fadeIn 0.7s;
  animation: fadeIn 0.7s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.leftSlide {
  display: block;
  position: absolute;
  transform: translate(-22.5rem);
}

.rightSlide {
  display: block;
  position: absolute;
  transform: translate(22.5rem);
}

.dots {
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: var(--space-base);
}

.dot {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0.15625rem;
  border-radius: 50%;
  background-color: rgb(225, 225, 225);
}

.activeDot {
  background-color: rgb(66, 78, 86);
}

@media only screen and (min-width: 40rem) {
  .otherFeatureSection {
    margin-bottom: 0;
    padding: var(--space-large) var(--space-large) 0 var(--space-large);
    overflow-x: auto;
    overflow-y: hidden;
  }

  .featureCards {
    width: auto;
    justify-content: start;
    transform: translateY(calc(var(--space-base) * 2.5));
  }

  .featureCard {
    display: block;
    width: 100%;
    margin: 0 3rem 0 0;
    transition-duration: 0.5s;
  }

  .featureCard:first-child {
    margin-left: 0;
  }

  .featureCard:last-child {
    margin-right: 0;
  }

  .fourFeatureCard {
    max-width: 18rem;
  }

  .slide {
    width: auto;
    -webkit-animation: none;
    animation: none;
  }

  .leftSlide {
    position: inherit;
    transform: unset;
  }

  .rightSlide {
    position: inherit;
    transform: unset;
  }

  .dots {
    display: none;
  }

  .featureCard:hover {
    opacity: 1 !important;
    transform: translateY(calc(var(--space-base) * -1));
    transition-duration: 0.5s;
  }

  .featureCards:hover .featureCard {
    opacity: 0.3;
  }
}
