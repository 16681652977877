.marketplaceTopNav {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  background: rgb(255, 255, 255);
  align-self: flex-start;
  --drop-shadow: drop-shadow(0px 0 var(--space-small) rgba(0, 0, 0, 0.25));
}

.marketplaceTopNavInterior {
  display: flex;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
  --line-padding: 0;
  @media (--extra-large-screens-and-up) {
    max-width: 90%;
  }
}

.marketplaceTopNav:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1rem;
  width: 100%;
  height: 1rem;
  background: linear-gradient(
    180deg,
    rgb(147, 147, 147),
    hsla(0, 0%, 57.6%, 0) 33.93%
  );
  pointer-events: none;
  opacity: 0.25;
}

.logo {
  width: 200px;
  height: 32px;
}

.smallLogo {
  width: 27px;
  height: 27px;
}

.topNavGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketPlaceImgContainer {
  padding-left: 16px;
}

.topNavLinksContainer a {
  margin-right: var(--space-largest);
  color: var(--color-heading);
  font-weight: 600;
}

.topNavButtonsContainer > a:first-child {
  margin-right: var(--space-base);
}

@media (--large-screens-and-up) {
  .marketPlaceImgContainer {
    padding-left: 19px;
  }
}
