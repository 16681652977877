.container {
  display: flex;
  height: 100%;
  padding: var(--space-large);
  padding-top: 0;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  padding-bottom: var(--space-large);
}

.copy {
  padding-bottom: var(--space-large);
}

.form {
  padding-top: var(--space-large);
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .container {
    padding: 0 var(--space-extravagant);
  }
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 450px;
    margin: 0 auto;
    padding: 0;
    align-items: flex-start;
    text-align: left;
  }
}
