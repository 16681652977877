/* stylelint-disable color-format/format */
@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

.tableWrap {
  width: 100%;
  background-color: var(--color-surface);
}

.emptyTableWrap {
  background-color: var(--color-surface);
}

.tableAccessory {
  padding: var(--space-base) var(--space-base) var(--space-small)
    var(--space-base);
  border: var(--border-base) solid var(--color-border);
  border-bottom: 0;
}

.tableAccessory > .searchContainer {
  max-width: 288px;
}

.loadingSkeletonAnimation {
  display: block !important;
  height: 25px;
  background: linear-gradient(
    to right,
    rgb(238, 238, 238) 20%,
    rgb(221, 221, 221) 50%,
    rgb(238, 238, 238) 80%
  );
  background-size: 500px 100px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: moving-gradient;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.statusCell {
  padding: 5px;
  border-radius: 3px;
}
