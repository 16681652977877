.header {
  display: flex;
  padding: var(--space-base);
  background-color: var(--color-surface--background);
  justify-content: space-between;
  gap: var(--space-base);
}

.header > :first-child {
  flex-grow: 2;
}

.header > :last-child {
  flex-grow: 5;
}

.header > p {
  display: inline;
  vertical-align: middle;
}

.centerVertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
