.heading {
  margin: 0;
  color: var(--color-interactive);
  line-height: var(--typography--lineHeight-base);
  word-break: break-word;
}

.variationDates {
  margin-top: var(--space-smaller);
}

.description {
  margin-top: var(--space-small);
  word-break: break-word;
}

.lineItemNameAndDescriptionContainer {
  margin-right: var(--space-small);
}

.lineItemNameImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.taxableContainer > p {
  margin-top: var(--space-small);
  font-weight: bold;
}

.productOrServiceCustomFields {
  margin-top: var(--space-small);
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.mobileTitle {
  display: none;
}

.numericBold {
  font-weight: bold;
}

/** Responsiveness for small screens */
@media screen and (max-width: 1024px) {
  .quantityCell {
    margin-top: var(--space-small);
  }

  .quantityCell > td {
    font-weight: bold;
    align-items: flex-end;
  }

  .mobileTitle {
    display: flex;
  }

  .alignCenter {
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .alignRight {
    display: flex;
    text-align: right;
    flex-direction: row;
    justify-content: space-between;
  }

  .alignRight > span:nth-child(2),
  .alignCenter > span:nth-child(2) {
    font-weight: bold;
  }
}
