.cardListItem {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: var(--space-large);
  border-bottom: 1px solid var(--color-border);
}

.cardListItem:last-child {
  border-bottom: none;
}

.compactCardListItem {
  padding: var(--space-base);
}

.cardListItem button {
  cursor: pointer;
}

.cardListItem > div {
  width: "100%";
  padding: 0 var(--space-base);
}

.compactCardListItem > div {
  padding: 0 var(--space-small);
}

.inlineAction:focus {
  outline: none;
}
