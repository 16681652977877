.bubbleWrapper {
  width: 100%;
}

.inbound {
  display: flex;
  align-self: flex-start;
  flex-direction: row;
}

.outbound {
  display: flex;
  align-self: flex-end;
  flex-direction: row-reverse;
}

.marginY {
  margin-left: var(--space-base);
  margin-right: var(--space-base);
}

.marginBBase {
  margin-bottom: var(--space-base);
}

.hoverTime {
  visibility: hidden;
  margin: 0 var(--space-small);
}

.bubbleWrapper:hover > .hoverTime {
  visibility: visible;
}
