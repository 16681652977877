.listItemContainer {
  display: flex;
  margin-left: calc(-1 * var(--space-small));
  margin-right: calc(-1 * var(--space-small));
  padding: var(--space-small) var(--space-base);
}
/* This makes child elements grow to fill the available space */
.listItemContainer > * {
  flex-grow: 1;
}

.listItemContainer > section {
  overflow: visible;
}

.listItemContainer:hover {
  background-color: var(--color-surface--hover);
  transition: background-color var(--timing-base) ease-in-out;
}

.listItemRow {
  display: flex;
  margin-bottom: var(--space-smaller);
  gap: var(--space-small);
}
.listItemRow p {
  line-height: var(--typography--lineHeight-base);
  align-self: center;
}
