.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.addressRow {
  padding-bottom: 0;
}

.emailRow {
  padding-bottom: var(--space-smaller);
}
