.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

/** Overrides the default button styling */
.container  > button {
    --button--size: 0 !important;

    padding: 0;
    color: var(--color-text--secondary) !important;
    text-decoration: none !important;
}
