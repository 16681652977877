.inputRangeControls {
  --inputRange-track-height: 4px;
  --inputRange-track-radius: var(--radius-small);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: var(--space-smaller) 0;
  align-items: stretch;
  gap: var(--space-base);
}

.inputRangeControls > div {
  flex: 1 1 100%;
}

.inputRangeControls input[type="number"] {
  appearance: textfield;
}

.inputRangeControls > input[type="range"] {
  flex: 1 1 100%;
  margin: 0;
  background: transparent;
  appearance: none;
}

@media (--medium-screens-and-up) {
  .inputRangeControls {
    flex-wrap: nowrap;
  }

  .inputRangeControls > div {
    flex: 0 0 auto;
  }
}

.inputRangeControls > input[type="range"]:focus-visible {
  box-shadow: var(--shadow-focus);
  outline: none;
}

.inputRangeControls > input[type="range"]::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  box-shadow: var(--shadow-low);
  border-radius: 100%;
  background-color: var(--color-interactive);
  cursor: grab;
  appearance: none;
}
/* Extra styles on top of default to vertically center the thumb, separating for ease of reading */
/* stylelint-disable-next-line no-duplicate-selectors */
.inputRangeControls > input[type="range"]::-webkit-slider-thumb {
  margin-top: 2px;
  transform: translateY(-50%);
}
.inputRangeControls > input[type="range"]::-webkit-slider-thumb:hover {
  background-color: var(--color-interactive--hover);
}
.inputRangeControls > input[type="range"]::-webkit-slider-thumb:active {
  cursor: grabbing;
}

.inputRangeControls > input[type="range"]::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  box-shadow: var(--shadow-low);
  border-radius: 100%;
  background-color: var(--color-interactive);
  cursor: grab;
  appearance: none;
}
.inputRangeControls > input[type="range"]::-moz-range-thumb:hover {
  background-color: var(--color-interactive--hover);
}
.inputRangeControls > input[type="range"]::-moz-range-thumb:active {
  cursor: grabbing;
}

.inputRangeControls > input[type="range"]::-ms-thumb {
  width: 1rem;
  height: 1rem;
  box-shadow: var(--shadow-low);
  border-radius: 100%;
  background-color: var(--color-interactive);
  cursor: grab;
  appearance: none;
}
.inputRangeControls > input[type="range"]::-ms-thumb:hover {
  background-color: var(--color-interactive--hover);
}
.inputRangeControls > input[type="range"]::-ms-thumb:active {
  cursor: grabbing;
}

.inputRangeControls > input[type="range"]::-webkit-slider-runnable-track {
  display: block;
  width: 100%;
  height: var(--inputRange-track-height);
  border-radius: var(--inputRange-track-radius);
  background-color: var(--color-interactive--background);
}

.inputRangeControls > input[type="range"]::-moz-range-track {
  display: block;
  width: 100%;
  height: var(--inputRange-track-height);
  border-radius: var(--inputRange-track-radius);
  background-color: var(--color-interactive--background);
}

.inputRangeControls > input[type="range"]::-ms-track {
  display: block;
  width: 100%;
  height: var(--inputRange-track-height);
  border-radius: var(--inputRange-track-radius);
  background-color: var(--color-interactive--background);
}

/* Webkit doesn't provide a way to style the progressed portion of the track, so it needs unique styling */
/* Once again, separated for readability */
/* stylelint-disable-next-line no-duplicate-selectors */
.inputRangeControls > input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    var(--color-interactive) 0%,
    var(--color-interactive) var(--percent-range-value),
    var(--color-interactive--background) var(--percent-range-value),
    var(--color-interactive--background) 100%
  );
}

.inputRangeControls > input[type="range"]::-moz-range-progress {
  background-color: var(--color-interactive);
}

.inputRangeControls > input[type="range"]::-ms-fill-lower {
  background-color: var(--color-interactive);
}
