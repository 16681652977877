.recipientSearchContainer {
  display: flex;
  align-items: center;
  padding: var(--space-base);
  border-bottom: var(--border-base) solid var(--color-border);
}

.toLabelContainer {
  /* Left padding to the wall will be space-base from the above container */
  padding-right: var(--space-base);
}
