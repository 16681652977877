.conversationListItem {
  display: flex;
  padding: 0 var(--space-base);
}

.conversationListItem a {
  color: var(--copilot-link);
  text-decoration: underline;
  text-underline-offset: 3px;
}

.requestContainer {
  display: flex;
  padding: var(--space-small) var(--space-base);
  border-radius: var(--space-small);
  color: var(--color-text);
  white-space: pre-line;
  background-color: var(--copilot-prompt-bg);
}

.requestContainer.error {
  margin-bottom: var(--space-base);
  background-color: var(--color-critical--surface);
  align-items: center;
}

.responseContainer {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  border: none;
  text-align: left;
  grid-template-columns: auto 1fr;
  gap: var(--space-smaller);
}

.icon {
  width: var(--space-large);
  height: var(--space-large);
  box-sizing: content-box;
  padding-right: var(--space-small);
}

.responseContentColumn {
  display: flex;
  min-width: 0;
  margin-bottom: 0;
  text-align: left;
  flex-direction: column;
  gap: var(--space-base);
  flex: 1 1 auto;
  align-self: center;
}

/* stylelint-disable selector-max-type */
.responseContentColumn p:has(+ ul),
.responseContentColumn p:has(+ ol) {
  /* Markdown adds a bottom margin and !important is needed to override it */
  margin-bottom: 0 !important;
}
/* stylelint-enable selector-max-type */

.responseContentColumn ul,
.responseContentColumn ol {
  padding-left: var(--space-base);
}

.responseContentColumn li {
  margin-top: var(--space-smaller);
  list-style-type: disc;
}

.feedbackRow {
  display: flex;
  margin-top: var(--space-base);
  margin-bottom: var(--space-small);
  justify-content: space-between;
  align-items: baseline;
}
