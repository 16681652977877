.toggleButton {
  z-index: 9000;
}

.topNavGroup {
  display: flex;
  align-items: center;
  gap: var(--space-small);
  justify-content: center;
}

.sidebarToggle {
  position: absolute;
  top: calc(-1 * var(--space-small));
  right: 100%;
  padding: var(--space-large);
  filter: var(--drop-shadow);

  @media (--medium-screens-and-up) {
    display: none;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--elevation-menu);
  width: 300px;
  background-color: var(--color-surface);
  transform: translateX(100%);
  transition: all 200ms ease-in-out;
  flex: 0 1 auto;

  @media (--medium-screens-and-up) {
    position: relative;
    filter: none;
    transform: translateX(0);
  }
}

.sidebar.visible {
  filter: var(--drop-shadow);
  transform: translateX(0);

  @media (--medium-screens-and-up) {
    filter: none;
  }
}

.sidebarContent {
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
}

.sidebarNavigationControls {
  height: 4rem;
}

.sidebarNavigationInterior {
  display: flex;
  position: relative;
  flex: 1;
  overflow: hidden auto;
  flex-direction: column;
}

.sidebarNavigationInteriorCellMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: var(--space-base);
}
.sidebarNavigationInteriorCellMain a {
  margin-bottom: var(--space-base);
  color: var(--color-heading);
  font-weight: 600;
}

.sidebarNavigationCellBottom {
  padding: 1rem;
  padding-bottom: var(--space-large);
}

.devCenterLink a {
  color: var(--color-text);
}

.sidebarNavigationCellFooter {
  display: flex;
  padding: 1rem;
  flex-direction: column;
}
.sidebarNavigationFooterDivider {
  display: inline-block;
  width: 1rem;
  height: auto;
}

.sidebarNavigationCellFooter > button:first-child {
  margin-bottom: var(--space-base);
}
