.drawer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.visible {
  visibility: visible;
  height: calc(100% - 140px);
}

.withConversationFilter {
  height: calc(100% - 205px);
}

.invisible {
  visibility: hidden;
}

.h0 {
  display: block;
  height: 0;
  line-height: 0;
}

.errorWrapper {
  padding: var(--space-base) var(--space-base) 0 var(--space-base);
}
