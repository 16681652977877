.contentOverlayContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(var(--elevation-menu) + var(--elevation-modal));
  border-radius: var(--radius-larger);
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(var(--elevation-menu) + var(--elevation-modal));
  background-color: var(--color-overlay);
}
