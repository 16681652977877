.popoverContainer {
  width: 349px;
}

.content {
  display: flex;
  flex-direction: column;
  margin: var(--space-base);
  gap: var(--space-base);
}

.button {
  margin: 0 0 var(--space-base) var(--space-base);
}
