.container {
  display: flex;
}

.content {
  flex: 1 1 auto;
  align-self: center;
  padding-right: var(--space-base);
  transition: opacity var(--timing-base);
}

.action {
  flex: 0 0 auto;
  align-self: flex-end;
  position: relative;
}
