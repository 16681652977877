.reportSideDrawerContent {
  --reportColumn-space: var(--space-base);
  flex: 1;
  overflow-y: hidden;
}

.reportSideDrawerContent > * {
  flex: 1;
  height: 100%;
}

.reportSideDrawerContent .sectionHeader {
  padding: var(--reportColumn-space);
}

.reportSideDrawerContent .listItem {
  padding: var(--space-small) var(--reportColumn-space);
}

.reportSideDrawerContent .reportSideDrawerFooter {
  display: flex;
  padding: var(--reportColumn-space);
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-surface);
}

.reportSideDrawerContent .showAll {
  color: var(--color-interactive);
  cursor: pointer;
}

.reportSideDrawerContent .cardBody {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reportSideDrawerContent .columnList {
  height: 100%;
  /* Screen height - footer height - header and section header height */
  max-height: calc(100vh - 68px - 106px);
  overflow-y: scroll;
  flex: 1;
}
