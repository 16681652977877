.pageWrapper {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto var(--space-extravagant);
  padding: 0 var(--space-base);
}

.pageWrapper > * {
  margin-bottom: var(--space-base);
}

.pageWrapper > *:last-child {
  margin-bottom: 0;
}
