.blockContainer {
  margin-bottom: var(--space-smaller);
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  padding-left: var(--space-large);
}

.gridContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 24px;
}

.loadingContainer {
  margin-top: var(--space-extravagant);
  margin-bottom: var(--space-extravagant);
}

.experimentContainer {
  display: flex;
  position: relative;
  padding: 25px;

  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
