.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
}

.fromEmailContainer {
  flex: 1;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;
}

.confirmImpliedConsentPermissionContainer {
  padding-top: 10px;
}

.reviewPageContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
}

.editContainer,
.previewContainer {
  flex: 1 0 100%;
  width: 100%;
}
.previewContainer {
  display: flex;
  justify-content: center;
  background-color: var(--color-surface--background);
}

@media (--large-screens-and-up) {
  .reviewPageContainer {
    flex-wrap: nowrap;
    height: 100%;
    /** Prevent the container from growing below the BottomBar */
    overflow-y: hidden;
  }

  .editContainer {
    flex: 1 1 57%;
    height: 100%;
    overflow-y: auto;
  }

  .previewContainer {
    flex: 1 0 43%;
    height: 100%;
    overflow-y: auto;
  }
}
