.applicationRevisionBanner {
  color: var(--color-text);
  font-size: var(--typography--fontSize-base);
  font-weight: 400;
  line-height: var(--typography--lineHeight-base);
}

.appLink {
  color: var(--color-informative);
}
