.buttonGroup {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
}

.inputTimeContainer {
  flex: inherit;
}

.inputTime {
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: center;
}

.inputTime > :first-child {
  margin-right: -1px;
  --public-field--top-right-radius: 0;
  --public-field--bottom-right-radius: 0;
}

.inputTime > :last-child {
  --public-field--top-left-radius: 0;
  --public-field--bottom-left-radius: 0;
}

.leftButtons {
  flex: 1 1 auto;
  order: 1;
}

.rightButtons {
  display: flex;
  gap: var(--space-small);
  flex: 0 0 auto;
  order: 2;
}
