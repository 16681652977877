.pageContainer {
  display: flex;
  padding-top: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.innerPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.innerPageContainer > * {
  margin-top: 35px;
  text-align: center;
}

@media screen and (min-width: 40rem) {
  .pageContainer {
    padding-top: 2rem;
  }
}

.buttonContainer {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  align-items: center;
  flex-direction: column;
}
