.datesContainer {
  margin-bottom: var(--space-small);
}

.datesContainer > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.datesContainer.schedulingAssistant > div {
  grid-template-columns: 1fr 1fr 1fr;
}

.datesContainer.schedulingAssistant > div > button {
  height: 100%;
}
