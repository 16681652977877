.cardContent {
  height: 100%;
  padding-top: 0;
  overflow: hidden;
}

.headerText {
  font-weight: 700;
  text-transform: uppercase;
}
.appItems {
  display: grid;
}

.app {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--space-small) 0;
  transition: background-color var(--timing-quick) ease;
}

.app:hover {
  background-color: var(--color-surface--background--hover);
  cursor: pointer;
}

.appImage {
  display: flex;
  align-items: center;
  width: 26px;
  height: 26px;
  margin-left: var(--space-small);
  margin-right: var(--space-base);
  background-size: cover;
}

.appDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
