.container {
  display: grid;
  padding: var(--space-large);
  border-radius: var(--space-smallest);
  background-color: var(--color-surface--background);
  gap: 0;
}

.tierNameContainer {
  display: flex;
  margin-top: var(--space-large);
  align-items: center;
}

.savingsMessage {
  padding-left: var(--space-base);
}

.termsOfService {
  margin-top: var(--space-small);
  text-align: center;
}
