.disabled > h5 {
  color: var(--color-disabled);
}

.disabled > p {
  color: var(--color-disabled);
}

.disabled dt,
.disabled dd {
  color: var(--color-disabled);
}

.summaryContainer {
  display: grid;
  grid-row-gap: var(--space-small);
}
