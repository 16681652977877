.recipientInput {
  display: inline-block;
  width: 100%;
  margin: var(--space-smallest);
  padding: 0;
  border: none;
  outline: none;
  color: var(--field--value-color);
  font-size: var(--typography--fontSize-base);
  line-height: 1.5rem;
  background-color: transparent;
}

.recipientInput::placeholder {
  color: var(--field--placeholder-color);
}
