.integrationPageFooterContainer {
  margin-top: var(--space-extravagant);
  background-color: var(--color-surface--reverse);
}

.integrationPageFooterTop {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.integrationPageFooterDescription {
  width: unset;
  padding: var(--space-largest) var(--space-base);
  background-image: url("~@images/integrations/marketplace-footer-white-paper-bg.png");
}

.integrationPageFooterDescription h1 {
  max-width: 671px;
}
.integrationPageFooterDescription p {
  max-width: 671px;
  margin: var(--space-large) 0;
}

.marketPlaceFooterPic {
  width: unset;
  min-height: 222px;
  background-image: url("~@images/integrations/marketplace-footer-pic.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}

.integrationPageFooterPaperTier {
  position: relative;
  top: -35px;
  height: var(--space-extravagant);
  background-image: url("~@images/integrations/marketplace-footer-paper-tear.svg");
}

.integrationPageFooterLinksContainer {
  padding: 0px var(--space-base) var(--space-large);
}

.integrationPageFooterLinksContainer a {
  color: var(--color-text--reverse);
  white-space: nowrap;
}

.integrationPageFooterMiddleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.integrationPageFooterLogoContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.integrationPageFooterLogoContainer img {
  width: 144px;
}

.integrationPageFooterSocials {
  display: flex;
  margin-bottom: var(--space-largest);
}

.integrationPageFooterSocials a {
  margin-right: var(--space-base);
}

.integrationPageFooterSocials svg {
  width: var(--space-larger) !important;
  height: var(--space-larger) !important;
  transition: fill 0.3s ease;
}

.integrationPageFooterSocials svg path {
  fill: var(--color-blue--lighter) !important;
  /* becomes dark blue in the retheme version */
}

.integrationPageFooterSocials svg:hover path {
  fill: var(--color-white) !important;
}

.integrationPageFooterContacts {
  display: flex;
  max-width: 690px;
  margin: var(--space-largest) 0;
  flex-direction: column;
  justify-content: space-between;
  gap: 0;
}

.integrationPageFooterContacts a {
  margin-bottom: var(--space-base);
}

.integrationPageFooterLinkBlock {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-right: 0;
}

.integrationPageFooterBottomContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: var(--space-larger);
  margin-right: var(--space-larger);
}

.integrationPageFooterLinksBottom {
  display: flex;
  margin-bottom: var(--space-base);
}

.integrationPageFooterLinksBottom p {
  color: var(--color-text--reverse);
  font-size: var(--typography--fontSize-small);
  white-space: nowrap;
}

.integrationPageFooterLinksBottom a {
  font-size: var(--typography--fontSize-small);
}

.integrationPageFooterLinksBottom div {
  display: none;
  margin: 0 var(--space-larger);
}

@media (--medium-screens-and-up) {
  .integrationPageFooterTop {
    flex-direction: row;
  }

  .integrationPageFooterDescription {
    width: 50%;
    padding: var(--space-largest) var(--space-largest) 72px var(--space-largest);
  }

  .marketPlaceFooterPic {
    width: 50%;
  }

  .integrationPageFooterLinksContainer {
    padding: 0px 132px var(--space-large);
  }

  .integrationPageFooterLogoContainer img {
    width: 195px;
  }

  .integrationPageFooterContacts {
    flex-direction: row;
    margin: var(--space-larger) 0;
    gap: var(--space-larger);
  }

  .integrationPageFooterBottomContainer {
    flex-direction: row;
    justify-content: flex-end;
  }

  .integrationPageFooterLinksBottom div {
    display: block;
  }
}

@media (--large-screens-and-up) {
  .integrationPageFooterDescription {
    width: 60%;
  }

  .marketPlaceFooterPic {
    width: 40%;
  }

  .integrationPageFooterMiddleContainer {
    flex-direction: row;
  }

  .integrationPageFooterLinkBlock {
    margin-right: var(--space-largest);
  }
}
