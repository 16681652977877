.imageSection {
  display: flex;
  height: 100%;
  min-width: 400px;
  background-color: var(--color-surface--background);
  align-items: center;
  flex-basis: 35%;
  justify-content: center;
}

.accordionImage {
  height: 55%;
  max-height: 625px;
}
