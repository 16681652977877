@keyframes number-scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100% + 30px));
  }
}

.numberRollerWrapper {
  display: flex;
  height: 30px;
  overflow: hidden;
  font-size: 30.4px;
  justify-content: flex-end;
}

.numberRoller {
  display: flex;
  height: max-content;
  flex-direction: column;
  transform: translateY(calc(-100% + 30px));
  animation: number-scroll 1.25s ease-out;
}
