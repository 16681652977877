.recommendationsButtonContainer {
  margin: 0;
  padding: 0;
  border: none;
}

.recommendationGridContainer {
  display: grid;
  grid-gap: var(--space-small) 0;
}

.recommendationButtonContainer {
  display: flex;
  margin-bottom: var(--space-small);
  padding: var(--space-base);
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
  list-style-type: none;
  background-color: var(--color-surface);
  cursor: pointer;
  transition: all var(--timing-quick) linear;
  align-items: flex-start;
  justify-content: space-between;
  outline-color: var(--color-focus);
}

.recommendationButtonContainer:hover,
.recommendationButtonSelected {
  border: 1px solid var(--color-success);
}

.recommendationInput {
  position: absolute;
  left: -9999px;
}

.cardContent {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  gap: var(--space-small);
}

.title {
  display: flex;
  gap: var(--space-smaller);
  align-items: center;
}

.title > h4 {
  line-height: var(--typography--lineHeight-base);
}

.recommendationDetails {
  display: flex;
  gap: var(--space-small);
  flex-direction: column;
}
.dateContainer {
  display: flex;
  gap: var(--space-small);
}

.timeContainer {
  display: flex;
  gap: var(--space-smaller);
}

.errorMessageContainer {
  display: flex;
  flex-direction: row;
}

.noAvailableOptionsMessageContainer {
  display: flex;
  gap: var(--space-small);
  flex-direction: row;
}

.spanGridContainer {
  grid-column: -1 / 1;
  display: grid;
  grid-gap: var(--space-small);
}

.recommendationsSchedulingControls {
  display: flex;
  flex-direction: column;
  min-height: 292px;
}

.recommendationsAndSchedulingContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2%;
}

.dateHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-base);
}

.recommendationsHeadingContainer {
  display: flex;
  flex-direction: column;
}

.headingTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);

  & > svg {
    margin-right: var(--space-smaller);
  }

  & > div {
    margin-left: var(--space-small);
  }
}

@media screen and (max-width: 1024px) {
  .recommendationsAndSchedulingContainer {
    display: flex;
    flex-direction: column-reverse;
  }

  .dateHeaderContainer {
    padding-top: var(--space-base);
  }

  .headingTitleContainer {
    padding-top: var(--space-small);
  }
}

.schedulingParametersContainer {
  display: flex;
  align-items: center;
  gap: var(--space-base);
}
