.bottomBarContainer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--color-surface);
  flex: 0;
}

.bottomBarMedAndUp {
  display: flex;
  justify-content: space-between;
  padding: var(--space-base);
  border-top: 1px solid var(--color-border);
}

.bottomBarMedAndDown {
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  padding: var(--space-base);
  border-top: 1px solid var(--color-border);
}

.bottomBarRow {
  display: flex;
  justify-content: space-between;
  gap: var(--space-small);
}

.bottomBarMedAndDownNoButtons {
  display: none;
}

.bottomRightBarMedAndUp {
  display: flex;
  justify-content: center;
  column-gap: var(--space-base);
  white-space: nowrap;
}

.column {
  flex: 1;
  justify-content: center;
}
