.container {
  width: 100%;
  height: 100%;
  padding: var(--space-small);
  overflow: hidden;
}

.content {
  margin-bottom: var(--space-small);
  color: var(--color-text--secondary);
  font-size: var(--typography--fontSize-small);
  font-style: normal;
}

.details {
  color: var(--color-text--secondary);
  font-size: var(--typography--fontSize-smaller);
  font-style: normal;
}
