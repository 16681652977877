.helpDrawerButton {
  padding: var(--space-small);
  border-radius: var(--radius-base);
  transition: background-color 0.3s ease, color 0.2s;
}

.helpDrawerButton:hover,
.helpDrawerButton:focus {
  background-color: var(--color-surface--hover);
}

.helpDrawerButton:focus-visible {
  box-shadow: var(--shadow-focus);
  outline: transparent;
}

@media screen and (max-width: 40rem) {
  .helpDrawerButton {
    display: inline-block;
  }
}
