.depositDetailsWrapper {
  width: 100%;
  align-self: stretch;
  flex-grow: 1;
}

.strikeThrough {
  text-decoration: line-through;
}

.amountContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
