.cell {
  line-height: var(--typography--lineHeight-base);
  text-align: left;
  vertical-align: top;
}

.small {
  padding: var(--horizontal-inset) calc(var(--horizontal-inset) / 2);
  padding-top: calc(var(--horizontal-inset) / 4);
  padding-bottom: calc(var(--horizontal-inset) / 4);
}

.base {
  padding: var(--horizontal-inset) calc(var(--horizontal-inset) / 2);
}

.large {
  padding: var(--horizontal-inset) calc(var(--horizontal-inset) / 2);
  padding-top: calc(var(--horizontal-inset));
  padding-bottom: calc(var(--horizontal-inset));
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.numeric {
  font-feature-settings: "tnum";
}

/** Responsiveness for small screens */
@media screen and (max-width: 1024px) {
  .cell {
    display: flex;
    width: 100%;
    padding: 0;
    flex-direction: column;
    justify-content: left;
  }

  .cell:first-child {
    padding-left: 0;
  }

  .cell:last-child {
    padding-right: var(--horizontal-inset);
  }
}
