.container {
  display: flex;
  overflow: hidden;
}

.container .leftColumn {
  display: flex;
  max-width: 300px;
  flex-basis: 269px;
  flex-grow: 1;
  flex-shrink: 0;
  border-color: var(--color-border);
  border-style: solid;
  border-width: 0;
  border-right-width: 1px;
  flex-direction: column;
}

.container .rightColumn {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.minical {
  padding-bottom: var(--space-small);
  border-bottom: 1px solid var(--color-border);
}
