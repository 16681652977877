.enterpriseContainer {
  margin: 3rem 20% 3rem 20%;
  transition: margin 0.5s;
}

.enterpriseHeading {
  text-align: center;
}

.enterpriseCoaching {
  margin: 3rem 35% auto 35%;
  transition: margin 0.5s;
}
