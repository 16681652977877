.messageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 var(--space-base);
}

.inboundTimeStamp,
.outboundTimeStamp,
.errorStateTimeStamp {
  display: inline-flex;
  padding: var(--space-smaller) 0;
  color: var(--color-text--secondary);
  align-self: flex-start;
}

.outboundTimeStamp {
  color: var(--color-text--secondary);
  align-self: flex-end;
}

.errorStateTimeStamp {
  color: var(--color-critical--onSurface);
  align-self: flex-end;
}
