.headerWrapper {
  display: flex;
  align-items: top;
  gap: var(--space-small);
  margin-top: calc(-1.5 * var(--space-large));
}

.reportOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reportOptions > .reportControls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: var(--space-small);
}

.successReportBanner {
  margin-left: var(--space-large);
  margin-right: var(--space-large);
}

.exportWrapper {
  width: 100%;
  height: 100vh;
  padding-top: var(--space-large);
  background-color: var(--color-surface);
}

.reportWrapper {
  height: 100%;
  background-color: var(--color-surface);
}

@media only screen and (max-width: 1270px) {
  .reportOptions {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: var(--space-small);
  }
}

@media only screen and (max-width: 900px) {
  .headerWrapper {
    margin-top: calc(-1 * var(--space-large));
  }
}

@media (max-width: 768px) {
  .reportControls {
    width: 100%;
  }

  .reportOptions {
    flex-direction: column;
  }
}
