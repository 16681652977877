.paymentTabsNotClickable button[role="tab"] {
  cursor: not-allowed;
}

.regularMargins {
  margin: var(--space-base);
}

.achErrorMessageWrapper {
  margin-bottom: var(--space-base);
}
