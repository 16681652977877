.container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Workaround: 2 was subtracted from var(--elevation-modal) because modal's z index is 1001 and lightbox in Atlantis
     has z-index of 1000. This is done so lightbox comes on top of Side Drawer.'*/
  z-index: calc(var(--elevation-modal) - 2);
  flex-direction: column;
  /** While the overlay without a background color would be
  transparent this makes it easy to switch out if desired in the future */
  --color-overlay--drawer: transparent;
  --color-drawer--surface: var(--color-surface);
}

.subtle {
  --color-drawer--surface: var(--color-surface--background--subtle);
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay--drawer);
}

.drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: calc(var(--base-unit) * 26.25);
  box-shadow: var(--shadow-base);
  border-radius: var(--radius-base) 0 0 var(--radius-base);
  background-color: var(--color-drawer--surface);
}

@media (--small-screens-and-below) {
  .fullWidthDrawer {
    max-width: 100%;
  }
}

.drawerContent {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.header {
  display: flex;
  padding: var(--space-large);
  justify-content: space-between;
  align-items: center;
}

.header.subtle {
  padding: var(--space-base) var(--space-base) var(--space-small)
    var(--space-base);
}
