.buttonBar {
  display: flex;
  padding: var(--modal--padding);
  padding-top: 0;
  justify-content: flex-end;
}

/* !important is to fix css loading order */
.buttonBar > *:not(:last-of-type) {
  margin-right: var(--space-small) !important;
}

.reducedPaddingWhenWrappedInTab {
  padding-bottom: calc(var(--space-minuscule) - 1px);
  padding-left: 0;
  padding-right: 0;
}
