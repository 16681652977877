.largePadding {
  padding: var(--space-larger);
}

.centeredBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
