:root {
  --integration-icon-size: 175px;
  --integration-page-width: 1280px;
}

.twoThirdsGrid {
  display: grid;
  max-width: var(--integration-page-width);
  margin: 0 auto;
  grid-template-columns: 1fr;
  gap: var(--space-large);
}

.iconGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-larger);
}

.actionSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subduedHeader {
  padding-bottom: var(--space-smaller);
  text-transform: uppercase;
}

.body {
  padding: var(--space-base) var(--space-large);
}

.integrationPage ul {
  padding: calc(var(--space-small) * 1.5) 0 0 0;
}

.integrationPage li {
  padding: var(--space-smallest) 0;
}

.iconList li {
  list-style: none;
}

.bulletList li {
  list-style: inside;
}

.iconList svg {
  margin-right: var(--space-base);
}

@media (min-width: 640px) {
  .iconGrid {
    grid-template-columns: var(--integration-icon-size) 1fr;
  }

  .twoThirdsGrid {
    grid-template-columns: 2fr 1fr;
    gap: var(--space-extravagant);
  }
}
