.highlight > div {
  box-shadow: var(--shadow-focus);
  background-color: var(--color-surface--hover);
}

/**
 Remove padding caused by the SG-1 styling from the dialog causing misalignment of the chips label
*/
.chipDismissible p {
  margin: 0;
}
