.container {
  height: 100%;
  padding: var(--space-large);
  flex: 1;
}

.campaignTemplateInputs {
  padding-top: var(--space-large);
  padding-bottom: var(--space-base);
}

.rewriteAndVariablesContainer {
  display: flex;
  justify-content: space-between;
}

.headingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: calc(var(--space-small));
}

.logoWrapper {
  display: flex;
}
