.unreadBadge {
  display: inline-block;
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  height: 0.85rem;
  border-radius: 15%;
  color: var(--color-white);
  font-family: var(--typography--fontFamily-display);
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  background-color: var(--color-critical);
  outline-style: solid;
  outline-width: thin;
}

.notificationBadge:before {
  content: "";
  display: block;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  width: var(--space-small);
  height: var(--space-small);
  box-sizing: content-box;
  border: var(--border-thick) solid var(--color-surface);
  border-radius: 50%;
  background-color: var(--color-critical);
}
