.container {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.container > * {
  padding: var(--space-base);
}

.container > *:nth-child(2) {
  padding: 0 var(--space-base) 0 var(--space-base);
}
