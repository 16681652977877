.title {
  padding: var(--space-base) var(--space-large);
}

.activePanel {
  display: flex;
  height: 100%;
  max-height: 418px;
  justify-content: center;
  background-color: var(--color-surface--background);
}
