.customFieldItemContentArea {
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: left;
  flex-wrap: wrap;
  align-items: center;
}

.customFieldItemName {
  flex-grow: 2;
  flex-basis: 0;
  align-items: center;
}

.customFieldItemDescription {
  /* TODO: This min width needs rethinking. It's forcing the wrap at a certain size.*/
  min-width: 250px;
}

.customFieldItemDescriptionAndMoreActions {
  display: flex;
  flex-direction: row;
  flex-grow: 4;
  flex-basis: 0;
  align-items: center;
  overflow: visible;
}

.customFieldMoreActionsButton {
  margin-top: calc(var(--space-small) * -1);
  margin-bottom: calc(var(--space-small) * -1);
  margin-left: auto;
  padding-right: var(--space-base);
  cursor: pointer;
}

.archivedCustomFieldActionsButton {
  margin-top: calc(var(--space-small) * -1);
  margin-bottom: calc(var(--space-small) * -1);
  margin-left: auto;
  cursor: pointer;
}

.customFieldItemEditButton {
  color: var(--color-interactive);
  cursor: pointer;
  align-items: center;
}
