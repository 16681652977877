.messageHeader {
  --message-weight: 400;
  display: flex;
  flex-direction: column;
  font-weight: var(--message-weight);
}

.bold {
  --message-weight: 800;
  transform: uppercase;
}
