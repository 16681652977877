.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: flex-start;
  isolation: isolate;
}

.container input {
  position: absolute;
  opacity: 0;
}

.loadingContent {
  width: 100%;
  height: 100%;
  padding: var(--space-base);
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
  text-align: left;
  background: var(--color-surface);
}

.content,
.contentVertical {
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow-base);
  border: var(--border-thick) solid var(--color-border);
  border-radius: var(--radius-base);
  text-align: left;
  background: var(--color-surface);
  justify-content: space-between;
  flex-direction: row;
  column-gap: var(--space-base);
}

.disabled {
  z-index: 10;
  border: 0;
  background-color: var(--color-disabled--secondary);
}

.content {
  padding: 0;
}

.contentVertical {
  flex-direction: column;
  row-gap: var(--space-small);
}

.container input:checked ~ .content,
.container input:checked ~ .contentVertical {
  border: var(--border-thick) solid var(--color-interactive);
  border-radius: var(--radius-base);
  background: var(--color-surface);
}

.container input:focus-visible ~ .content,
.container input:focus-visible ~ .contentVertical {
  box-shadow: var(--shadow-focus);
  border: var(--border-thick) solid var(--color-interactive);
  border-radius: var(--radius-base);
  background: var(--color-surface);
}

.container:hover input:not(:disabled) ~ .content,
.container:hover input:not(:disabled) ~ .contentVertical {
  border: var(--border-thick) solid var(--color-border);
  border-radius: var(--radius-base);
  background: var(--color-surface--hover);
  cursor: pointer;
}

.inlineContainer {
  display: flex;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.contentHeadingAndViewEditButton {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: var(--space-large);
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  row-gap: var(--space-small);
}

.iconContainer {
  padding: var(--space-small);
  border-radius: var(--radius-base);
  background-color: var(--color-surface--background);
}

.innerContentContainer {
  width: 100%;
}

.iconAndStatusContainer {
  padding-bottom: var(--space-small);
}

.descriptionContainer {
  padding-top: var(--space-small);
}
