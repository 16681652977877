.stripeInput {
  display: flex;
  height: 3rem;
  padding-top: initial;
  font-family: var(--typography--fontFamily-normal);
  background-color: var(--field--background-color);
  flex-direction: column;
  justify-content: center;
}

.stripeInput:global(.is-focus) {
  z-index: 3;
  box-shadow: var(--shadow-focus);
  border-color: none;
  outline: none;
}

.placeholderFieldLabel {
  z-index: 4;
  pointer-events: none;
}
