.detailsContainer,
.rightContainer {
  height: 100%;
  padding: 0 var(--space-base) var(--space-base);
}

.arrow {
  display: flex;
  padding-top: var(--space-base);
  padding-bottom: var(--space-larger);
  justify-content: flex-end;
}

.close {
  display: flex;
  justify-content: flex-end;
  padding: var(--space-small);
  padding-bottom: 0;
}

.title {
  display: flex;
  padding: var(--space-base);
}

.donePanelContainer {
  background-image: url("~@images/OnboardingChecklistBackground.png");
  background-size: cover;
}

@media (--medium-screens-and-up) {
  .close {
    padding: var(--space-base);
  }

  .title {
    width: 75%;
    padding: var(--space-base) var(--space-base) var(--space-large);
  }

  .detailsContainer,
  .rightContainer {
    padding: 0 var(--space-base) var(--space-large);
  }
}

@media (--large-screens-and-up) {
  .close {
    padding: var(--space-base) var(--space-base)
      calc(var(--space-largest) * 1.15) var(--space-base);
  }

  .title {
    width: 100%;
    padding: 0;
    padding-left: var(--space-large);
    justify-content: center;
  }

  .detailsContainer,
  .rightContainer {
    padding: var(--space-large) var(--space-larger) var(--space-large)
      var(--space-large);
    white-space: pre-line;
  }

  .donePanelContainer {
    padding-bottom: calc(var(--space-extravagant) * 1.8);
  }

  .description {
    max-width: 348px;
  }
}
