.campaignListTabContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  --public-tab--inset: 0;
}

.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
  --public-tab--inset: 0;
}
