.chatWindow {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: hidden;
  --button--color-surface--hover: var(--color-base-purple--300);
  --color-border--interactive: var(--color-base-purple--600);
  /* stylelint-disable-next-line color-no-hex */
  --color-interactive: #233d48;
  /* stylelint-disable-next-line color-no-hex */
  --color-interactive--hover: #233d48;
  /* stylelint-disable-next-line color-no-hex */
  --color-surface: #fff;
  /* stylelint-disable-next-line color-no-hex */
  --color-surface--background--subtle: #fff;
  /* stylelint-disable-next-line color-no-hex */
  --color-text: #233d48;
  --copilot-prompt-bg: var(--color-base-purple--300);
  --copilot-link: var(--color-base-purple--600);
  --public--button--color-variation--hover: var(--color-base-purple--600);
  --copilot-transition-gradient: linear-gradient(
    to right,
    var(--color-surface) 33%,
    var(--button--color-surface--hover) 66%
  );
  --copilot-hover-transition-duration: 750ms;
}

:root[data-theme="dark"] .chatWindow {
  --button--color-surface--hover: var(--color-base-purple--800);
  --color-border--interactive: var(--color-base-purple--400);
  /* stylelint-disable-next-line color-no-hex */
  --color-interactive: #eef1f2;
  /* stylelint-disable-next-line color-no-hex */
  --color-interactive--hover: #eef1f2;
  /* stylelint-disable-next-line color-no-hex */
  --color-surface: #273135;
  /* stylelint-disable-next-line color-no-hex */
  --color-surface--background--subtle: #273135;
  /* stylelint-disable-next-line color-no-hex */
  --color-text: #eef1f2;
  --copilot-prompt-bg: var(--color-base-purple--800);
  --copilot-link: var(--color-base-purple--400);
}

:global(
    :root[data-theme="dark"]
      div[role="dialog"]:has(div[data-testid="jobber-assistant-conversation"])
  ) {
  /* stylelint-disable-next-line color-no-hex */
  --sideDrawer--background: #273135;
}

.listContainer {
  display: flex;
  position: relative;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  flex-direction: column;
}

.listContent {
  display: flex;
  flex-direction: column;
  gap: var(--space-base);
  height: 100%;
  overflow: auto;
  scroll-padding-top: var(--space-base);
}

.listContent::-webkit-scrollbar {
  width: 0 !important;
}

.listContainer::after,
.listContainer::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: var(--space-base);
  pointer-events: none;
}

.listContainer::before {
  top: 0;
  background: linear-gradient(
    var(--color-surface--background--subtle),
    transparent
  );
}

.listContainer::after {
  bottom: 0;
  background: linear-gradient(
    transparent,
    var(--color-surface--background--subtle)
  );
}

.promptContainer {
  display: flex;
  column-gap: var(--space-base);
}

.promptContainer button {
  height: var(--space-largest);
  min-width: var(--space-largest);
  margin-top: auto;
  border: none;
  background-color: var(--color-base-purple--600) !important;
  background-position: 0% 0%;
  transition: background-position 750ms;
}

.promptContainer button:hover {
  background-image: linear-gradient(0deg, var(--color-base-purple--700), var(--color-base-purple--600)) !important;
  background-position: 0% 100%;
  background-size: 100% 200%;
}

.promptContainer textarea {
  min-height: unset;
}

:root[data-theme="dark"] .promptContainer button {
  background-color: var(--color-base-purple--400) !important;
}

:root[data-theme="dark"] .promptContainer button:hover {
  background-image: linear-gradient(0deg, var(--color-base-purple--300), var(--color-base-purple--400)) !important;
}

.promptContainer button svg {
  transform: rotate(90deg);
}

.promptContainer div {
  box-shadow: none !important;
}

:root[data-theme="dark"] .promptContainer > div > div {
  /* stylelint-disable-next-line color-no-hex */
  background-color: #273135;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-smaller);
}
