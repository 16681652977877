.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container > *:first-child {
  flex-grow: 1;
  overflow: hidden;
}

.actionBar {
  display: flex;
  padding: var(--space-base);
  gap: var(--space-base);
  border-color: var(--color-border);
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
}

.actionBar > *:nth-child(2) {
  flex-grow: 1;
}
