.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer > img {
  display: flex;
  padding: 10px;
}

.logoContainer > img.channelLogo {
  max-width: 225px;
  max-height: 115px;
}
