.fillWithLastContainer {
  position: absolute;
  bottom: calc(100% + var(--space-smaller));
  z-index: calc(var(--elevation-menu) + var(--elevation-modal));
  width: 100%;
  box-shadow: var(--shadow-base);
  box-sizing: border-box;
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
  background-color: var(--color-surface);
}

.fillWithLastHeader {
  display: flex;
  padding-left: var(--space-base);
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--border-thick) solid var(--color-border--section);
}

.fillItem {
  display: flex;
  padding: var(--space-base);
  flex-direction: row;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: calc(var(--elevation-menu) + var(--elevation-modal));
}
