.action {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: var(--space-small);
  text-align: left;
  text-decoration: none;
  background-color: transparent;
  transition: background-color var(--timing-base);
  appearance: none;
  align-items: flex-start;
}

.action > * {
  padding: var(--space-small);
}

.hoverable {
  cursor: pointer;
}

.hoverable:hover,
.hoverable:focus {
  outline: none;
  background-color: var(--color-surface--active);
}

.icon {
  display: flex;
  flex: 0 0 auto;
  width: var(--space-large);
  height: var(--space-large);
  box-sizing: content-box;
  padding-left: var(--space-base);
  padding-right: 0;
}

.regular {
  flex: 1 1 auto;
  min-width: 0;
  align-self: center;
}

.activeCompletedFont > p {
  color: var(--color-heading);
  font-weight: bold;
}

.activeRegularFont > p {
  padding-left: var(--space-base);
  color: var(--color-heading);
  font-weight: bold;
}

.completedFont > p {
  padding-left: var(--space-small);
  color: var(--color-text--secondary);
}

.regularFont > p {
  padding-left: var(--space-base);
  font-weight: bold;
}

.itemContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.itemContentTime {
  padding-right: var(--space-base);
}
