.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 0;
  padding-top: 0;
  flex: 1;
}

.twoColumnGrid .leftColumn {
  padding-bottom: var(--space-large);
}

.twoColumnGrid .leftColumn.hiddenDivider {
  margin-bottom: 0;
  padding-bottom: 0;
  grid-row-start: 1;
  grid-row-end: 2;
}

.twoColumnGrid .rightColumn {
  display: none;
}

.twoColumnGrid .rightColumn.hiddenDivider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--space-large);
  padding-top: 0;
}

.forward {
  animation: slideIn var(--timing-base) cubic-bezier(0.5, 0, 0.4, 1);
}

.backward {
  animation: slideOut var(--timing-base) cubic-bezier(0.5, 0, 0.4, 1);
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .twoColumnGrid .rightColumn.hiddenDivider {
    padding: var(--space-extravagant);
  }
}

@media screen and (min-width: 1024px) {
  .twoColumnGrid {
    grid-template-columns: 1fr 1fr;
  }

  .twoColumnGrid .leftColumn.hiddenDivider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: -10vh;
  }

  .twoColumnGrid .rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: var(--color-surface--reverse);
  }

  .twoColumnGrid .rightColumn.themeStepDark {
    background-color: var(--color-base-lightBlue--1000);
  }

  .twoColumnGrid .rightColumn.themeStepLight {
    /* stylelint-disable-next-line color-no-hex */
    background-color: #F9F8F6; /* hard-coded color so dark theme doesn't flip it, color is --color-surface--background--subtle */
  }

  .twoColumnGrid .rightColumn.hiddenDivider {
    background-color: var(--color-surface);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(500px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(0);
  }
}
