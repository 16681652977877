.paymentDisclaimer {
  padding-bottom: var(--space-small);
}

.addressRow {
  display: flex;
}
.addressRowItemLarge {
  width: 70%;
}

.addressRowItemSmall {
  width: 30%;
}

.billingAddressHeaderContainer {
  display: flex;
  padding: var(--space-base);
  padding-bottom: var(--space-small);
  align-items: center;
  justify-content: space-between;
}

.loadingSpinner {
  display: flex;
  padding: var(--space-base);
  justify-content: center;
}
