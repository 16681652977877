.container > div {
  box-sizing: border-box;
  flex: 1 0 50%;
}

.container > div:nth-child(1) {
  margin-bottom: var(--space-base);
}

.container :global(.chatBubble) {
  display: block;
  word-break: break-word;
  white-space: pre-wrap;
}

.helpText {
  font-style: italic;
}

:global(.dialog-content) .helpText {
  margin-bottom: 0;
}

@media only screen and (min-width: 450px) {
  .container {
    display: flex;
  }

  .footer > div {
    text-align: right;
  }

  .container > div:nth-child(1) {
    margin-bottom: 0;
  }

  .container > div:nth-child(2) {
    padding-left: var(--space-large);
  }

  .footer {
    display: flex;
    margin-top: var(--space-base);
  }

  .footer > a {
    flex: 1 1 auto;
  }
}
