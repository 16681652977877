.summaryContainer {
  display: inline-block;
  width: 250px;
}
.summaryContainer h5 {
  color: var(--color-text--secondary);
}
.row {
  display: flex;
  flex-direction: row;
  gap: var(--space-small);
  margin-bottom: var(--space-smaller);
}
.row p {
  margin-top: var(--space-smallest);
}
.overviewCardLoading {
  margin-right: var(--space-largest);
}
