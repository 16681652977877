.offeringsListContainer {
  width: 100%;
}

.itemContainer {
  display: flex;
  padding: var(--space-base) 0;
  border-bottom: var(--border-base) solid var(--color-border);
  align-items: center;
}

.itemContainer:first-child {
  padding-top: 0;
}

.itemContainer:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.draggableItemIconContainer {
  display: inline-flex;
  margin-right: var(--space-base);
  flex-shrink: 0;
}

.listContainer {
  max-height: 60vh;
  overflow-y: auto;
}

.spinner {
  display: flex;
  padding: var(--space-base);
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
