.inputWrapper {
  display: flex;
  width: 100%;
}

.inputWrapper > div:first-child {
  max-width: 4rem;
  border-right: 0;
  border-radius: var(--radius-base) 0 0 var(--radius-base);
}

div[aria-label="Schedule End Type Selection"] {
  flex-direction: row;
  gap: var(--space-base);
}

.datePicker sg-icon[icon="calendar"] {
  color: var(--color-text--secondary);
}
