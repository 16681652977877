.customFieldsSectionCard {
  padding-bottom: var(--space-larger);
}

.emptyStateIconBackground {
  width: calc(var(--space-largest) + var(--space-small));
  height: calc(var(--space-largest) + var(--space-small));
  border-radius: var(--radius-circle);
  background-color: var(--color-surface--background);
}

div.emptyStateIconBackground > svg {
  margin-top: calc(var(--space-base) - var(--space-minuscule));
  margin-left: var(--space-base);
}

.emptyStateContainer {
  display: flex;
  padding: var(--space-larger);
}

.emptyStateIconContainer {
  display: flex;
  align-items: center;
  flex-basis: auto;
  padding-right: var(--space-base);
}

.emptyStateDetailsContainer {
  flex-grow: 1;
}

.emptyStateDetailsText {
  margin-bottom: var(--space-smaller);
}

.itemContainerBottomSpace {
  visibility: hidden;
  height: var(--space-smallest);
}

.divider {
  padding-left: var(--space-base);
  padding-right: var(--space-base);
}

.itemContainer:last-of-type > .divider {
  display: none;
}

.archivedCustomField {
  margin-left: calc(2.5 * var(--space-base));
  padding-top: var(--space-small);
  padding-bottom: var(--space-small);
}

.archivedCustomFieldSectionButton button {
  display: flex;
  width: 100%;
  justify-content: left;
  padding-left: var(--space-base);
}

.archivedCustomFieldSectionButton button:hover {
  background-color: var(--color-surface--hover) !important;
}

.archivedCustomFieldSectionButton button:focus {
  --shadow-focus: none;
  background-color: var(--color-surface--hover) !important;
}

.archivedCustomFields {
  padding-left: var(--space-base);
  padding-right: var(--space-base);
}
