.cell p {
  font-weight: 700;
  transition: background-color 200ms linear, color 200ms linear,
    width 200ms linear;
}

.cell.active p,
.cell:hover p {
  color: var(--color-brand);
  font-weight: 700;
  cursor: pointer;
  transition: background-color 200ms linear, color 200ms linear,
    width 200ms linear;
}

.cell svg {
  transition: background-color 200ms linear, fill 200ms linear,
    width 200ms linear;
}

.cell.active svg,
.cell:hover svg {
  fill: var(--color-brand) !important;
  transition: background-color 200ms linear, fill 200ms linear,
    width 200ms linear;
}

.cell {
  display: inline-flex;
  align-items: center;
}
