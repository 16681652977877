.reason {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.inputTextbox {
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

.alert {
  display: flex;
  color: var(--color-critical);
  align-items: center;
}

.alertText {
  margin-left: 0.5rem;
  font-size: var(--typography--fontSize-base);
}
