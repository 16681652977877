.listItemRow {
  display: flex;
  margin-bottom: var(--space-smaller);
  gap: var(--space-small);
}

.listItemRow p {
  line-height: var(--typography--lineHeight-base);
  align-self: flex-end;
}

.radioGroup {
  margin-top: var(--space-base);
}

.saveButton {
  display: flex;
  justify-content: flex-end;
}
