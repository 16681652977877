.topNavButton {
  position: relative;
  padding: var(--space-small);
  padding-right: var(--space-small);
  border: 0;
  border-radius: var(--radius-base);
}

.topNavButton:hover {
  background: var(--color-surface--hover);
  cursor: pointer;
}

.topNavButton:focus {
  box-shadow: var(--shadow-focus);
}

.lockBadge {
  position: absolute;
  right: -0.05rem;
  bottom: -0.125rem;
  z-index: 0;
}

.lockBadge > path:hover {
  fill: var(--color-surface--hover);
}

.lockBadge svg path:first-child {
  fill: var(--color-surface);
}

.img {
  width: 100%;
  max-width: 20rem;
  margin: auto;
}
