.scrollContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.listHidden {
  display: none;
}

.scrollReverse {
  flex-direction: column-reverse;
}

.scrollRegular {
  flex-direction: column;
}
