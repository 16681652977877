.rightContainer {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: var(--space-small);
}

.moreIconContainer {
  position: absolute;
  top: 0;
  right: 0;
  flex-shrink: 0;
  align-self: flex-end;
}

.titleText {
  flex-wrap: wrap;
  flex: 1;
  margin-right: var(--space-large);
}

.descriptionContainer {
  margin-right: var(--space-base);
}

.endContainer {
  display: flex;
  flex: 0.6666;
  flex-wrap: no-wrap;
  gap: var(--space-base);
  flex-shrink: 0;
  align-items: center;
}

.editButton {
  color: var(--color-interactive);
  cursor: pointer;
}

.editButton:hover {
  color: var(--color-interactive--hover);
}
