.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--space-base);
}

.heading {
  /* We want to override native spacing on H3 */
  margin-bottom: var(--space-small) !important;
}

.glimmerRow {
  margin-bottom: var(--space-smaller);
}

.pageContainer {
  display: flex;
  justify-content: center;
}
