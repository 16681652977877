.appCustomFieldGroupContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-small);
  flex-grow: 1;
}

.appCustomFieldGroupInfoContainer {
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: left;
  flex-wrap: wrap;
  align-items: center;
}

.appCustomFieldGroupInfoName {
  display: flex;
  gap: var(--space-small);
  margin-bottom: calc(var(--space-smaller) + var(--space-smallest));
  flex-grow: 2;
  flex-basis: 0;
  align-items: center;
}

.appCustomFieldGroupInfoLogo {
  width: var(--space-large);
  height: var(--space-large);
}

.appCustomFieldGroupInfoMoreActions {
  display: flex;
  flex-direction: row;
  flex-grow: 4;
  flex-basis: 0;
  align-items: center;
  overflow: visible;
}

.appCustomFieldGroupInfoMoreActionsButton {
  margin-top: calc(var(--space-small) * -1);
  margin-bottom: calc(var(--space-small) * -1);
  margin-left: auto;
  padding-right: var(--space-base);
  cursor: pointer;
}
