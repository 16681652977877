.panelContainer {
  display: grid;
  width: 100%;
  padding: var(--space-base) var(--space-large);
  background-image: url("~@images/SetupGuideBackground.png");
  align-content: center;
  justify-content: center;
}

.rightContainer,
.rightContainerSmallScreen {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.rightContainer {
  padding-left: var(--space-base);
}
.rightContainerSmallScreen {
  padding-left: 0;
}

.leftContainer {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 100%;
}

.description {
  white-space: pre-line;
}

.imageContainer {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  max-height: 180px;
}

.additionalImageContainer {
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 120px;
}

.additionalImageContainer > a:focus {
  outline: none;
  color: transparent;
  background-color: transparent;
}

.additionalImageContainer,
.appBadge,
.displayBadgeVertical {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.displayBadgeVertical {
  width: 300px;
  flex-direction: column;
}

.googlePlayBadgeVertical {
  width: 180px;
}
.appStoreBadgeVertical {
  width: 140px;
}

.appStoreBadge {
  display: inline-block;
  width: 120px;
  height: 60px;
  padding-left: var(--space-small);
  padding-right: var(--space-small);
}
.googlePlayBadge {
  display: inline-block;
  width: 150px;
  height: 60px;
}
