.jobberIcon {
  width: 2rem;
  height: 2rem;
  margin-bottom: var(--space-base);
}

@media (--small-screens-and-up) {
  .jobberIcon {
    margin-bottom: var(--space-large);
  }
}

.topFeaturesWrapper {
  display: block;
}

.header > span {
  font-family: var(--typography--fontFamily-normal);
  font-size: var(--typography--fontSize-larger);
  font-weight: 500;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.header b {
  font-family: var(--typography--fontFamily-display);
  font-weight: 800;
}

.industryName > strong {
  padding-bottom: 5px;
}

.featureContainer {
  min-width: calc(90% - 2px);
  margin: 5px 0;
  padding: 7px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (--medium-screens-and-up) {
  .featureContainer {
    min-width: auto;
    margin: 0;
    overflow: auto;
    text-overflow: unset;
    white-space: normal;
  }

  .featureContainer:not(:last-child) {
    margin-right: 5px;
  }

  .topFeaturesWrapper {
    display: flex;
  }
}
