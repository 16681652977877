.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 32px;
  justify-content: center;
  align-items: center;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='280' height='2' viewBox='0 0 280 2' fill='none'%3E%3Cpath d='M1 1L279 0.999976' stroke='%2332821C' stroke-linecap='round' stroke-linejoin='round' stroke-dasharray='5 5'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: repeat-x;
}

.addButton {
  padding: 0 8px 0px 8px;
  background-color: rgba(255, 255, 255, 1);
}
