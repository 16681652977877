:root {
  --max-width: calc(var(--space-base) * 70);
  --max-image-height: calc(var(--space-base) * 23);
  --max-button-width: calc(var(--space-base) * 10);
  --button-height: calc(var(--space-base) * 3);
}

.landingPageContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.landingPage {
  padding: 0 var(--space-large) 0 var(--space-large);
}

.landingPage,
.headingContainer {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  align-items: center;
  flex-direction: column;
  margin-bottom: var(--space-larger);
}

.planSectionContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: var(--space-largest) var(--space-large) 0 var(--space-large);
  background-color: var(--color-surface--background);
}

.planSectionContainerGradientBackground {
  background: linear-gradient(
    to top,
    var(--color-surface) 50%,
    var(--color-surface--background) 50%
  );
}

.planSectionContainerTrialAvailable {
  padding-bottom: var(--space-large);
}

.featuresHighlightsContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: var(--space-larger) var(--space-large) var(--space-largest)
    var(--space-large);
  background-color: var(--color-surface--reverse);
}

.gridContainer,
.firstImageGridContainer,
.planSectionGridContainer {
  display: grid;
  width: 100%;
  margin-bottom: var(--space-large);
}

.gridContainer,
.planSectionGridContainer {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: var(--space-large);
  grid-row-gap: var(--space-large);
  align-items: center;
}

.planSectionGridContainer,
.featuresHighlightsGridContainer {
  max-width: 56rem;
}

.firstFeature {
  grid-area: 1/1/2/5;
}

.secondImageContainer {
  grid-area: 2/1/3/5;
  margin-top: var(--space-small);
}

.secondFeature {
  grid-area: 3/1/4/5;
}

.thirdFeature {
  grid-area: 4/1/5/5;
}

.discoverHeading {
  grid-area: 1/1/3/5;
}

.buttonsContainer {
  grid-area: 3/1/4/5;
  width: 100%;
}

.planCard {
  margin-top: var(--space-larger);
  grid-area: 4/1/5/5;
}

.heading > h1 {
  margin-bottom: var(--space-base);
  text-align: center;
}

.heading > h3 {
  margin-bottom: var(--space-large);
  text-align: center;
}

.firstFeature > h2,
.secondFeature > h2,
.thirdFeature > h2 {
  margin-bottom: var(--space-small);
}

.firstImageContainer,
.secondImageContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: rgb(241, 240, 234);
}

.firstImageContainer {
  padding-top: var(--space-largest);
  padding-left: var(--space-base);
  padding-right: var(--space-base);
}

.firstImageContainer > img,
.secondImageContainer > img {
  max-width: 100%;
}

.firstImageContainer > img {
  margin-bottom: 0;
}

.discoverHeading,
.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.upgradeNowButton,
.startTrialButton,
.upgradeNowButton button,
.startTrialButton button,
.chatWithUsButton button {
  width: 100%;
}

.chatWithUsButton {
  width: 100%;
  margin-top: var(--space-small);
}

.featuresHighlightsSection {
  display: flex;
  width: 100%;
  background-color: var(--color-surface--reverse);
  justify-content: center;
}

@media screen and (min-width: 64rem) {
  .landingPage {
    width: 70%;
    padding: 0;
  }

  .headingContainer {
    width: 50%;
  }

  .firstImageGridContainer,
  .gridContainer,
  .planSectionGridContainer,
  .featuresHighlightsGridContainer {
    width: 60%;
  }

  .gridContainer {
    grid-row-gap: var(--space-base);
  }

  .gridContainerTwoFeatures {
    grid-row-gap: var(--space-larger) !important;
  }

  .firstImageGridContainer {
    margin-bottom: var(--space-extravagant);
  }

  .firstImageContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .secondImageContainer {
    margin-top: 0;
    grid-area: 1/1/4/3;
  }

  .secondImageContainerTwoFeatures {
    grid-area: 1/1/3/3;
  }

  .firstFeature {
    grid-area: 1/3/2/5;
  }

  .firstFeatureTwoFeatures {
    align-self: flex-end;
  }

  .secondFeature {
    grid-area: 2/3/3/5;
  }

  .secondFeatureTwoFeatures {
    align-self: flex-start;
  }

  .thirdFeature {
    grid-area: 3/3/4/5;
  }

  .discoverHeading {
    text-align: left;
    grid-area: 1/1/3/3;
  }

  .buttonsContainer {
    grid-area: 1/3/3/5;
    justify-content: flex-end;
  }

  .planCard {
    grid-area: 3/1/5/5;
  }
}

@media screen and (min-width: 40rem) {
  .landingPageContainer {
    padding: 0;
  }

  .landingPage {
    width: 90%;
  }

  .headingContainer {
    width: 60%;
    padding: 0;
  }

  .firstImageGridContainer,
  .gridContainer,
  .planSectionGridContainer,
  .featuresHighlightsGridContainer {
    width: 80%;
  }

  .planSectionGridContainer {
    width: 75%;
  }

  .firstImageContainer > img,
  .secondImageContainer > img {
    max-height: var(--max-image-height);
  }

  .planSectionContainer {
    margin-top: var(--space-base);
    padding: var(--space-largest) var(--space-largest) 0 var(--space-largest);
  }

  .planSectionContainerTrialAvailable {
    padding-bottom: var(--space-largest);
  }

  .discoverHeading {
    display: block;
  }

  .planCard {
    margin-bottom: var(--space-extravagant);
  }
}

@media (--medium-screens-and-up) {
  .discoverHeading,
  .buttonsContainer {
    flex-direction: row;
  }

  .chatWithUsButton {
    max-width: var(--max-button-width);
    margin-top: 0;
    margin-left: var(--space-small);
  }

  .upgradeNowButton,
  .upgradeNowButton button,
  .chatWithUsButton button {
    max-width: var(--max-button-width);
  }

  .startTrialButton,
  .startTrialButton button {
    max-width: fit-content;
  }
}
