.ElongatedCircleWrapper {
  display: inline-block;
  position: relative;
}

.circle {
  position: absolute;
  top: -20%;
  left: -12%;
  width: 120%;
  height: 140%;
}
