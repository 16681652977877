.container {
  display: flex;
  height: 100%;
  padding: 0 var(--space-large);
  flex-direction: column;
  gap: var(--space-base);

  form {
    height: 100%;
  }
}
.settingsContainer {
  display: grid;
  max-height: calc(100vh - 185px);
  padding: var(--space-small) var(--space-large);
  overflow-y: auto;
  gap: var(--space-base);
}

.editContainer {
  margin-top: var(--space-large);
  --public-tab--inset: 0;
}

.preview {
  height: 9rem;
}

.section h4 {
  margin-bottom: var(--space-smaller);
}

.section li {
  line-height: 1.25;
}

.section li:first-child {
  margin-bottom: var(--space-small);
}

.inlineSection {
  display: flex;
  align-items: center;
  column-gap: var(--space-large);
  justify-content: space-between;
}

.footer {
  position: fixed;
  bottom: var(--space-large);
  width: calc(100vw - 2 * var(--space-large));

  @media (min-width: 420px) {
    width: calc(420px - 2 * var(--space-large));
  }
}

.actionItem {
  button {
    width: 100%;
    padding: 0;
    text-decoration: none;
    justify-content: space-between;
  }

  button > svg {
    fill: var(--color-interactive) !important;
    border-radius: var(--radius-base);
  }

  button:hover svg {
    background-color: var(--color-surface--hover) !important;
  }

  button:active svg,
  button > svg:active {
    box-shadow: var(--shadow-focus) !important;
  }

  button:hover,
  :focus-visible,
  :active {
    box-shadow: unset !important;
    background-color: transparent !important;
  }

  button > span {
    font-weight: normal;
    text-align: left;
  }
}

.backButton {
  position: absolute;
  top: var(--space-large);
  left: var(--space-small);
}

/* This is temporary until the SideDrawer component can support a back button natively*/
/* stylelint-disable-next-line selector-disallowed-list */
div[class*="SideDrawer-module__drawer"]:has(
    div[class*="ReviewsSettings-module__backButton"]
  ) {
  h2 {
    margin-left: var(--space-large);
  }
}

div[role="menu"] {
  max-height: 275px;
  overflow-y: auto;
}
