:root {
  --popover--padding: var(--space-base);
  --popover--width: calc(var(--base-unit) * 21.875);
  --dismiss--button-padding: calc(var(--base-unit) / 4);
}

.popover {
  z-index: var(--elevation-tooltip);
  width: max-content;
  max-width: var(--popover--width);
  min-width: 350px;
  box-shadow: var(--shadow-base);
  border: var(--border-base) solid var(--color-border);
  border-radius: var(--radius-base);
  font-size: var(--base-unit);
  line-height: normal;
  background: var(--color-surface);
}

.popover > * {
  --public-content--padding: var(--popover-padding);
}

.arrow {
  visibility: hidden;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 9px;
  height: 9px;
  background: inherit;
  background-color: rgb(244, 244, 244);
}

.arrow::before {
  content: "";
  visibility: visible;
  border: var(--border-base) solid var(--color-border);
  transform: rotate(45deg);
  transform-origin: center;
  clip-path: polygon(-4px -4px, 9.7px 0, 0 9.7px);
}

.popover[data-popper-placement^="bottom"] > .arrow {
  top: -5px;
}

.contentTitle {
  padding: 17.25px 16px 17.25px 16px;
  color: rgb(66, 78, 86);
  font-size: 18px;
  font-weight: 700;
  background-color: rgb(244, 244, 244);
}
