.actionButtons {
  display: flex;
  margin-top: var(--space-large);
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-base);
}

.nonAdminActionButtons {
  display: flex;
  gap: var(--space-base);
  flex-direction: column;
}

.nonAdminActionButton {
  align-self: flex-start;
}
