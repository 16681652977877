.computeJobProfitability {
  position: absolute;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}

.computeJobProfitability button {
  box-shadow: var(--shadow-high);
}

@media screen and (max-width: 1024px) {
  .computeJobProfitability {
    margin-top: var(--space-extravagant);
  }
}
