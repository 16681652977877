.inputContainer {
  display: flex;
  height: 72px;
  padding: 0 var(--space-base);
  flex-direction: row;
  gap: var(--space-base);
}

.conditionContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.conditionInternalContainer {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.conditionRuleContainer {
  flex: 1;
  display: inline-flex;
}

.bar {
  width: 40px;
  height: 72px;
  min-width: 40px;
  background-color: var(--color-surface--background);
}

.arrowContainer {
  height: 56px;
  align-content: end;
  padding: var(--space-small);
  border-bottom-right-radius: var(--radius-base);
  border-bottom-left-radius: var(--radius-base);
  background-color: var(--color-surface--background);
}

.toolTipContainer {
  display: flex;
  margin-left: var(--space-smaller);
  align-items: center;
}
