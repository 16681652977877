.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  padding: var(--space-base);
  border-top: var(--border-base) solid var(--color-border);
}

.container:first-child {
  border: 0;
}

.lineItemsFooter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.lineItemsTotalButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.lineItemsTotals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0.2;
  gap: var(--space-small);
  flex-basis: 20%;
}

.lineItemsTotalCost {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.lineItemsTotalPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

/** Responsiveness for small screens */
@media screen and (max-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: var(--space-small);
  }

  .lineItemsTotals {
    flex-basis: 50%;
  }
}
