.container {
  --typography--fontFamily-normal: Helvetica, Arial, "Lucida Grande", sans-serif;
  --typography--fontFamily-display: Helvetica, Arial, "Lucida Grande",
    sans-serif;
  --typography--fontSize-base: var(--space-base);
  --typography--lineHeight-base: var(--typography--lineHeight-large);
  display: flex;
  width: calc(100% - (var(--space-base) * 2));
  max-width: calc(600px + (var(--space-large) * 2));
  margin: var(--space-base);
  font-family: var(--typography--fontFamily-normal);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  align-items: center;
  flex-direction: column;
}

@media (--large-screens-and-up) {
  .container {
    width: calc(100% - (var(--space-largest) * 2));
    margin: var(--space-largest);
  }
}

.previewContent {
  display: flex;
  width: 100%;
  padding: var(--space-largest) var(--space-large);
  overflow-y: visible;
  white-space: break-spaces;
  background-color: var(--color-surface);
  align-items: center;
  gap: var(--space-large);
  flex-direction: column;
  --previewContent-color: rgb(60, 162, 224);

  img {
    display: inline;
  }
}

.previewContent .emailSubject {
  width: 100%;
  text-align: left;
  word-break: break-word;
  align-self: flex-start;
}

.previewContent a {
  color: rgba(0, 102, 204, 1);
  text-decoration: underline;
}

.previewBody {
  text-align: left;
}

.previewContent button,
.previewContent button:hover,
.previewContent button:focus,
.previewContent button:active {
  --public--button--color-variation: var(--previewContent-color);
  --public--button--color-variation--hover: var(--previewContent-color);
}

.previewHeader h3 {
  --typography--fontSize-larger: var(--typography--fontSize-largest);
  --typography--lineHeight-tight: var(--typography--lineHeight-large);
  font-weight: 700;
}

.footer {
  --typography--fontFamily-normal: Helvetica, Arial, "Lucida Grande", sans-serif;
  --typography--lineHeight-base: var(--typography--lineHeight-large);
  align-self: flex-end;
  --typography--fontSize-base: var(--typography--fontSize-smaller);
  width: 100%;
  padding: var(--space-larger);
  background-color: var(--color-surface--background--subtle);
}

.unsubscribeLink {
  text-decoration: underline;
}

.brandingLogo {
  max-width: 200px;
}

.emailBodyLoading {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-small);
}

.previewHeaderImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailHeaderImage {
  max-width: min(600px, 100%);
}

.previewHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.referralLink {
  padding: 12px var(--space-base);
  border-radius: var(--radius-base);
  background-color: var(--color-surface--background--subtle);
  line-break: anywhere;
}

.referralLinkTitle {
  margin-bottom: var(--space-small);
}

:global(.substitutedCampaignEmailPreviewVariableText) {
  text-decoration: dashed;
  text-decoration-line: underline;
  text-decoration-color: var(--color-text--secondary);
  text-decoration-thickness: var(--space-smallest);
  text-underline-offset: 3px;
}
