@media only screen and (min-width: 640px) {
  .rightAlignSortColumn {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.sortableColumn {
  display: flex;
  align-items: center;
  gap: var(--space-smallest);
}

.cellLink {
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.reportCellTextWrapper {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reportCellTagsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.cellBodyPopoverTextWrap {
  overflow-wrap: break-word;
  cursor: text;
}

.statusWrapper {
  white-space: nowrap;
}

.multiLineHeader {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
