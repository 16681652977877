.lineItemContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: var(--space-base);
}

.lineItemInputContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--space-small);
  margin-bottom: var(--space-small);
}

.dragIconContainer {
  margin-top: var(--space-base);
  margin-right: var(--space-small);
}

.inputRow {
  display: grid;
  width: 100%;
  gap: var(--space-small);
  grid-template-columns: 1fr;
}

/** Responsiveness for small screens */
@media screen and (--large-screens-and-up) {
  .inputRow {
    grid-template-columns: minmax(200px, 2fr) repeat(
        auto-fit,
        minmax(100px, 1fr)
      );
  }
}

.actionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: var(--space-base);
}
