.xstateIcon {
  width: 18px;
  height: 18px;
  fill: var(--color-interactive--subtle);
}

.xstateIcon:hover {
  fill: var(--color-interactive--subtle--hover);
}

:global(#xstateOverlay) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
}

:global(#xstateInspectorContainer) {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:global(#xstateInspectorContainer:focus) {
  outline: none !important;
}

:global(#xstateInspectorContainer::after) {
  content: "○○○";
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 10px;
  font-size: 7px;
  font-weight: "bold";
  text-align: center;
  background-color: var(--color-white);
  cursor: ns-resize;
}
