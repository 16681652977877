.container {
  padding: var(--space-small) var(--space-smaller);
}

.loadingContainer {
  display: flex;
  height: 52px;
  padding: var(--space-base) 0;
  justify-content: center;
}

.parentCell {
  padding: 0;
}
