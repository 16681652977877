.coreToConnectTrialSettingsPopovers {
  display: none;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row > p {
  align-self: flex-end;
}

.coreToConnectTrialSettingsPopovers [role="dialog"] {
  max-width: 360px;
}

@media (min-width: 40rem) {
  .coreToConnectTrialSettingsPopovers {
    display: unset;
  }
}
