.linkDescription {
  display: inline-flex;
  float: right;
  height: var(--space-large);
  padding-top: 0.13rem;
  color: var(--color-interactive);
  font-size: 1rem;
  line-height: 1.25rem;
  text-decoration-line: underline;
  align-self: flex-end;
  gap: var(--space-smaller);
}
