.cardHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--space-base);
}

.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: var(--space-base);
}

.headerAndDiscountContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 60%;
  align-items: baseline;
  column-gap: var(--space-base);
}
