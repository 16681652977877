.jobberIcon {
  width: 2rem;
  height: 2rem;
  margin-bottom: var(--space-base);
}

@media (--small-screens-and-up) {
  .jobberIcon {
    margin-bottom: var(--space-large);
  }
}

.settingUp > span {
  display: inline-block;
  font-family: var(--typography--fontFamily-normal);
  font-size: var(--typography--fontSize-larger);
  font-weight: 500;
}

.settingUp {
  display: block;
  max-width: 100%;
  white-space: nowrap;
}

.settingUp > strong {
  padding-bottom: 5px;
  white-space: break-spaces;
}
